import actionTypes from '../actionTypes'
import { apiStatuses } from '../../resources/constants';

const initialPreferenceState = {
  status: apiStatuses.IDLE,
  foodVisibilityStatus: apiStatuses.IDLE,
  userTopFoods: null,
  error: null,
  foodVisibilityError: null,
  isFoodScoreUpdated: null
};

const topFoodsReducer = (state = initialPreferenceState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_TOP_FOODS_STARTED:
      return {
        ...state,
        status: apiStatuses.PENDING,
      };
    case actionTypes.SELECT_TOP_FOODS_SUCCESS:
      return {
        ...state,
        userTopFoods: action.topFoods.data,
        status: apiStatuses.RESOLVED,
        // normalizeFoodScore = 0 means, food score for the user didn't update, since food score value never be a zero
        // so we just check the first item to check if the food score is updated or not
        isFoodScoreUpdated: action.topFoods.data[0].foods[0].normalizeFoodScore !== 0
      };
    case actionTypes.SELECT_TOP_FOODS_ERROR:
      return {
        ...state,
        status: apiStatuses.REJECTED,
        error: action.payload.error,
      };
    case actionTypes.SET_TOP_FOODS:
      return {
        ...state,
        userTopFoods: action.topFoods,
        status: apiStatuses.RESOLVED
      };
    case actionTypes.SET_FOOD_VISIBILITY_STARTED:
      return {
        ...state,
        foodVisibilityStatus: apiStatuses.PENDING,
      };
    case actionTypes.SET_FOOD_VISIBILITY_SUCCESS:
      return {
        ...state,
        userTopFoods: action.payload.data,
        foodVisibilityStatus: apiStatuses.RESOLVED,
      };
    case actionTypes.SET_FOOD_VISIBILITY_ERROR:
      return {
        ...state,
        foodVisibilityStatus: apiStatuses.REJECTED,
        foodVisibilityError: action.error,
      };
    default:
      return state;
  }
};

export default topFoodsReducer;
