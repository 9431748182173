import React, { } from 'react';
import PropTypes from 'prop-types';
import {
    Grid, Button,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik'; // Removed useFormik
import * as Yup from 'yup';
import RegistrationFormikInput from '../../components/RegistrationFormikInput';
import constants from '../../resources/constants';
import { passwordRequirements } from '../../utils';
import { Link } from 'react-router-dom';


const RegisterFormForSequencingUser = ({ classes, accountCreationError, isCreatingAccount, orderDetails, onSubmit,
    jobId, productCode, orderNo
}) => {


    const validationSchema = Yup.object().shape({
        jobId: Yup.number()
        .typeError('Sequencing job id must be a number')
            .required('Sequencing job id is required'),
        orderNumber: Yup.string()
            .required('Order number is required'),
        productCode: Yup.string()
            .required('Product code is required'),
        firstName: Yup.string()
            .required('First name is required'),
        lastName: Yup.string()
            .required('Last name is required'),
        email: Yup.string()
            .email('Must be a valid email')
            .required('Email is required'),
        confirmEmail: Yup.string()
            .oneOf([Yup.ref('email')], 'Emails must match')
            .email('Must be a valid email')
            .required('You are required to confirm your email'),
        password: passwordRequirements,
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required('You are required to confirm your password'),
        tos: Yup.bool()
            .oneOf([true], 'You must accept the Privacy Policy and Terms of Service before continuing'),
    });

    return (
        <Formik
            initialValues={{
                orderNumber: orderDetails ? orderDetails.orderNumber : orderNo,
                productCode: orderDetails ? orderDetails.productCode : productCode,
                jobId: jobId,
                firstName: orderDetails ? orderDetails.name : '',
                lastName: orderDetails ? orderDetails.surname : '',
                email: orderDetails ? orderDetails.email : '',
                confirmEmail: '',
                password: '',
                confirmPassword: '',
                tos: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
            enableReinitialize
        >
            {(props) => {
                const {
                    handleSubmit,
                    errors,
                    submitCount,
                } = props;
                return (
                    <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Grid item xs={12}>
                            <RegistrationFormikInput
                                name="jobId"
                                label="Sequencing Job ID"
                                id="createAccountProductJobId"
                                submitCount={submitCount}
                                tooltip="To find your job ID, look at the top of your GenoPalate PDF report purchased through Sequencing.com. Enter this number exactly as shown."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RegistrationFormikInput
                            name="orderNumber"
                            id="createAccountOrderNumberInput"
                            label="Order number"
                            submitCount={submitCount}
                            tooltip="This code can be found in your order confirmation email from GenoPalate. If you ordered from a third-party retailer, request your registration codes from the link above."
                            type={orderDetails && orderDetails.orderNumber ? 'hidden' : 'text'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RegistrationFormikInput
                            name="productCode"
                            label="Product code"
                            id="createAccountProductCodeInput"
                            submitCount={submitCount}
                            tooltip="This code can be found in your order confirmation email from GenoPalate. If you ordered from a third-party retailer, request your registration codes from the link above."
                            type={orderDetails && orderDetails.productCode ? 'hidden' : 'text'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RegistrationFormikInput
                                name="firstName"
                                label="First name"
                                id="createAccountFirstNameInput"
                                submitCount={submitCount}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RegistrationFormikInput
                                name="lastName"
                                label="Last name"
                                id="createAccountLastNameInput"
                                submitCount={submitCount}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RegistrationFormikInput
                                name="email"
                                label="Email"
                                id="createAccountEmailInput"
                                submitCount={submitCount}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RegistrationFormikInput
                                name="confirmEmail"
                                label="Re-enter email address"
                                id="createAccountConfirmEmailInput"
                                submitCount={submitCount}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RegistrationFormikInput
                                name="password"
                                label="Password"
                                id="createAccountPasswordInput"
                                submitCount={submitCount}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RegistrationFormikInput
                                name="confirmPassword"
                                label="Re-enter password"
                                id="createAccountConfirmPasswordInput"
                                submitCount={submitCount}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            Password requirements:
                            <p className={classes.passwordReq}>At least 8 characters</p>
                            <p className={classes.passwordReq}>At least one number</p>
                            <p className={classes.passwordReq}>At least one special character (examples: #@!*)</p>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                            <Grid container>
                                <Grid item xs={1} sm={1}>
                                    {/* The MUI checkbox control would not work with the Formik validation */}
                                    <Field type="checkbox" id="createAccountTermsInput" name="tos" style={{ width: 20, height: 30 }} />
                                </Grid>
                                <Grid item xs={10} sm={11} className={classes.tosAndPPWrapper}>
                                    By clicking here you agree to our&nbsp;
                                    <a href="https://www.genopalate.com/pages/privacy-policy" target="_blank" rel="noopener noreferrer" >Privacy Policy</a>
                                    &nbsp;and&nbsp;
                                    <a href="https://www.genopalate.com/pages/terms-of-service" target="_blank" rel="noopener noreferrer" >Terms of Service</a>
                                </Grid>
                            </Grid>
                        </Grid>
                        {errors.tos && submitCount > 0 && (
                            <Grid item xs={12} className={classes.errorText}>
                                <span>{errors.tos}</span>
                            </Grid>
                        )}
                        {accountCreationError && (
                            <Grid item xs={12} className={classes.errorText} id="accountCreationError">
                                <span>{accountCreationError}</span>
                            </Grid>
                        )}
                        <Grid item xs={12} className={classes.centerGrid}>
                            <Button disabled={isCreatingAccount} variant="contained" id="createAccountSubmitButton"  className={classes.createAccountButton} type="submit">CREATE ACCOUNT</Button>
                        </Grid>
                        <Grid item xs={12} className={classes.alreadyHaveAccountText}>
                            Already have an account?&nbsp;
                            <Link to="/login"><b>Sign in</b></Link>
                        </Grid>
                        <Grid item xs={12} className={classes.centerGrid}>
                            Having trouble?&nbsp;
                            <a href={`${constants.CONTACT_SUPPORT_URL}`} id="createAccountSupportLink" style={{ color: 'black' }} target="_blank" rel="noopener noreferrer" ><b>Contact support</b></a>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
}

RegisterFormForSequencingUser.propTypes = {
    classes: PropTypes.object.isRequired,
    accountCreationError: PropTypes.string,
    isCreatingAccount: PropTypes.bool,
    orderDetails: PropTypes.object,
    orderNo: PropTypes.string,
    productCode: PropTypes.string,
};


export default RegisterFormForSequencingUser;