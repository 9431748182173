import { colors } from 'common/assets/sharedUI';

const styles = theme => ({
  pageWrapper: {
    paddingTop: 40,
  },
  backLink: {
    color: colors.lightBlue,
    paddingLeft: 20,
    cursor: 'pointer',
  },
  backLinkText: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  h3: {
    marginBottom: '50px',
  },
  pageDescription: {
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
  },
  foodCategoriesWrapper: {
    // borderTop: `1px solid ${colors.gray_01}`,
  },
  foodContainer: {
    minHeight: 812,
    width: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  foodListContainer: {
    // border: `solid 1px ${colors.gray_01}`,
    borderRadius: '6px',
    background: 'white',
  },
  foodContentLegend: {
    width: '100%',
    textAlign: 'right',
  }
});

export default styles;
