/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { snakeCase, startCase } from 'lodash';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import format from 'date-fns/format';
import { getUuidCode } from './storage';

/**
 * This React component provides page tracking integration with Google Analytics.
 */
class GoogleAnalytics extends Component {
  /**
   * Invoked immediately after the component is rendered and inserted into the DOM tree.
   * This will emit a page view to GA.
   */
  componentDidMount() {
    const { location: { pathname, search } } = this.props;
    this.logPageChange(pathname, search);
  }

  /**
   * Invoked when there was an update to state or props of the component. If the component
   * changed pathname or search a page view will be emitted to GA.
   *
   * @param prevLocation  Location interface
   */
  componentDidUpdate({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  /**
   * This is the logic that actually emits the page tracking data to GA.
   *
   * @param pathname {string}
   * @param search {string}
   */
  logPageChange(pathname, search = '') {
    const { userTraitScores, userFoodScores } = this.props;
    const page = pathname + search;
    const { location } = window;
    // The session id will change for each page view, but since it's configured in GA as 'session scoped', only the last value will be retained
    // for all page views in the session.
    const sessionId = `${new Date().getTime()}.${Math.random().toString(36).substring(5)}`;

    setTimestampCustomDimension();

    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      dimension1: getUuidCode(), // GenoPalate_ID
      dimension3: sessionId, // Session_ID
    });
    const pageName = userTraitScores && userTraitScores.length ? mapPathToPageTitle(page, userTraitScores, userFoodScores) : null;
    ReactGA.send({ hitType: "pageview", page: page, title: pageName });
  }

  /**
   * Do not render anything.
   *
   * @returns {null}
   */
  render() {
    return null;
  }
}

/**
 * Define the shape of the props that are required.
 *
 * @type {{location: *}}
 */
GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  userTraitScores: PropTypes.arrayOf(PropTypes.object),
  userFoodScores: PropTypes.arrayOf(PropTypes.object),
};

GoogleAnalytics.defaultProps = {
  userTraitScores: [],
  userFoodScores: [],
};

const mapStateToProps = state => ({
  userTraitScores: (!!state.productV2 && !!state.productV2.userProductV2 && !!state.productV2.userProductV2.product && state.productV2.userProductV2.product.userTraitScore) || [],
  userFoodScores: (!!state.productV2 && !!state.productV2.userProductV2 && !!state.productV2.userProductV2.product && state.productV2.userProductV2.product.userFoodScore) || [],
});

const GoogleAnalyticsConnected = connect(mapStateToProps)(GoogleAnalytics);

/**
 * Define a Route element with a GoogleAnalytics component. Use of this in React router will effectively
 * track all page changes.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const RouteTracker = () => <Route component={GoogleAnalyticsConnected} />;

const PROD_GOOGLE_ANALYTICS_ACCOUNT = 'G-X30WR8GZN7';
const STAGING_GOOGLE_ANALYTICS_ACCOUNT = 'G-JKH01WKZXX';
const DEFAULT_GOOGLE_ANALYTICS_ACCOUNT = STAGING_GOOGLE_ANALYTICS_ACCOUNT;

/**
 * Initialize ReactGA with the appropriate GA account based on environment.
 *
 * @returns {string}
 */
const init = () => {
  const { IS_PROD, IS_STAGING } = window.env;
  const googleAnalyticsGA4 = IS_PROD ? PROD_GOOGLE_ANALYTICS_ACCOUNT
    : (IS_STAGING ? STAGING_GOOGLE_ANALYTICS_ACCOUNT : DEFAULT_GOOGLE_ANALYTICS_ACCOUNT);

  if (googleAnalyticsGA4) {
    ReactGA.initialize(googleAnalyticsGA4);
  }

  return googleAnalyticsGA4;
};

/**
 * Set the GA custom dimension for the timestamp.  Isolated to this file so that we can encapsulate the dimension numbers, should they ever change.
 * There is no reason that the rest of the client needs to know what those ordinal numbers are.
 */
const setTimestampCustomDimension = () => {
  ReactGA.set({
    dimension2: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxxxx"), // Timestamp with local timezone
  });
};

const mapPathToPageTitle = (path, userTraitScores, userFoodScores) => {
  const cleanPath = stripEndingSlash(path);
  const nutrientRegExMatch = nutrientsRegExp.test(cleanPath);
  const foodRegExMatch = foodsRegExp.test(cleanPath);
  switch (cleanPath) {
    case '/':
      return 'Login';
    case '/register':
      return 'Register';
    case '/register/existing_data':
      return 'Register EDNA';
    case '/register/dna_kit':
      return 'Register DNA kit';
    case '/home':
      return 'Home';
    case '/insights':
      return 'Insights';
    case '/account':
      return 'Account';
    case '/account/update':
      return 'Account Update Password';
    case '/consultation':
      return 'RD Consultation Page View';
    default:
      break;
  }
  if (nutrientRegExMatch) {
    if (!userTraitScores || !userTraitScores.length) { return null; }
    return getNutrientPageName(cleanPath, userTraitScores);
  }
  if (foodRegExMatch) {
    if (!userFoodScores || !userFoodScores.data || !userFoodScores.data || !userFoodScores.data.length) { return null; }
    return getFoodPageName(cleanPath, userFoodScores.data);
  }

  return null;
};

// get the formatted pageName for nutrient overview and nutrient details pages
const getNutrientPageName = (path, userTraitScores) => {
  const routes = path.split('/');
  const [_, _nutrients, domainName, traitId] = routes;
  return traitId ? startCase(getTraitById(userTraitScores, traitId)) : `Nutrients_${startCase(domainName)}`;
};

const getFoodPageName = (path, foodGroups) => {
  const routes = path.split('/');
  const [_, _food, foodId] = routes;
  return foodId ? `Food_${snakeCase(getFoodById(foodGroups, foodId))}` : 'Food_Summary';
};

const getTraitById = (domains, traitId) => {
  if (!domains || !Array.isArray(domains)) { return null; }
  const allTraits = domains.reduce((acc, cur) => [...acc, ...cur.traits], []);
  const selectedTrait = allTraits.find(t => t.traitId === parseInt(traitId, 10));
  return selectedTrait ? selectedTrait.traitName : null;
};

const getFoodById = (foodGroups, foodId) => {
  const allFoods = foodGroups.reduce((acc, cur) => [...acc, ...cur.foods], []);
  const selectedFood = allFoods.find(food => food.id === parseInt(foodId, 10));
  return selectedFood ? selectedFood.name : null;
};

/**
 * Send an event to Google Analytics with custom data.
 *
 * @param category {string}
 * @param action {string}
 * @param label {string}
 */
const registerGAClickEventEnhanced = (category, action, label) => {
  // Update the timestamp so that events can be correlated over time.
  setTimestampCustomDimension();

  ReactGA.event({
    category,
    action,
    label,
  });
};

// HELPER UTILS
const nutrientsRegExp = /(\/nutrients\/)/;
const foodsRegExp = /(\/food)/;
const stripEndingSlash = string => (string.length > 1 && string.charAt(string.length - 1) === '/' ? string.substring(0, string.length - 1) : string);

const EventCategories = {
  AccountConsent: 'account_consent',
  AccountPassword: 'account_password',
  AccountProfile: 'account_profile',
  AccountShipping: 'account_shipping',
  AddOns: 'add_ons',
  Cart: 'cart',
  IndividualFoodPage: 'individual_food_page',
  FoodSentence: 'food_sentence',
  FoodSettings: 'food_settings',
  FoodTools: 'food_tools',
  Footer: 'footer',
  FoodIndex: 'food_index',
  Home: 'home',
  Login: 'login',
  Menu: 'menu',
  MobileAppBanner: 'mobile_app_banner',
  DietitianConsultBanner: 'dietitian_consult_banner',
  MyFoods: 'my_foods',
  Navbar: 'navbar',
  Nutrients: 'nutrients',
  Onboarding: 'onboarding',
  OptimalFoods: 'optimal_foods', // Optimal Foods and My Foods seem duplicative
  PreQuestionnaire: 'prequestionnaire',
  Registration: 'registration',
  Subscription: 'subscription',
  UploadDna: 'upload_dna',
  UploadDnaSource: (source) => `upload_dna_${source}`,
  SupplementBanner: 'supplement_banner',
  LandingScreenSupplement: 'landing_screen_supplement',
  ShopPageSupplement: 'shop_page_supplement',
  ShopPageProduct: 'shop_page_product',
  GenoBlend: 'geno_blend_supplement',
  NutrientConversion: 'nutrient_conversion_amount',
  NutrientConversionPercent: 'nutrient_conversion_percent',
  NutrientUpSell: 'nutrient_upsell_card',
  DeleteAccount: 'delete_account',
  RDConsultation: 'rd_consultation',
};

const EventActions = {
  AddToCart: 'add_to_cart',
  Cancel: 'cancel',
  Click: 'click',
  Close: 'close',
  Collapse: 'collapse', // Open/Close are used for the same UI component as Expand/Collapse
  Confirm: 'confirm',
  Copy: 'copy',
  Expand: 'expand',
  Hide: 'hide',
  Hover: 'hover',
  LeftArrow: 'left_arrow',
  Off: 'off',
  Open: 'open',
  On: 'on',
  Print: 'print',
  RemoveFromCart: 'remove_from_cart',
  RightArrow: 'right_arrow',
  Update: 'update',
  X: 'x',
  NutrientsGenoVitCallout: 'nutrients_genovit_callout',
  NutrientsConsult: 'nutrients_consultation_callout',
  NutrientsResultsPrint: 'nutrients_results_print',
  NutrientsDetailsClicked: 'nutrients_details_clicked',
  NutrientsUnitsToggled: 'nutrients_units_toggled',
  NutrientsRecommendationCalculation: 'nutrients_recommendation_calcultaion',
  NutrientsGenoBlendCallout: 'nutrients_genoblend_callout',
  RegisterKitClicked: 'register_kit_clicked',
  PageView: 'page_view',
  FoodIndexGenoVitCallout: 'food_index_genovit_callout',
  FoodIndexConsult: 'food_index_consultation_callout',
  FoodScoreCalculation: 'food_score_calcultaion',
  FoodIndexGenoBlendCallout: 'food_index_genoblend_callout',
  FoodIndexResultsPrint: 'food_index_results_print',
  FoodDetailsClicked: 'food_details_clicked',
};

const EventLabels = {
  AllRecommendations: 'all_recommendations',
  AllergiesAndPreferences: 'allergies_&_preferences',
  Booster: (boosterName) => snakeCase(boosterName),
  BrowserFoodExportInstructions: 'browser_food_export_instructions',
  Cancel: 'cancel',
  CancelSubscription: 'cancel_subscription',
  UpdateSubscription: (duration) => duration ? snakeCase(`${duration}_update_subscription`) : update_subscription,
  CartOpen: 'cart_open',
  ChangeConsent: 'change_consent',
  ChangePassword: 'change_password',
  ComeBackLater: 'come_back_later',
  ContactSupport: 'contact_support',
  Continue: 'continue',
  DietTypes: 'diet_types',
  Facebook: 'facebook',
  FacebookMemberForum: 'facebook_member_forum',
  Food: (foodName) => snakeCase(foodName),
  FoodGroup: (foodGroupName) => snakeCase(foodGroupName),
  ForgotPassword: 'forgot_password',
  GenoPalateUniversity: 'genopalate_university',
  GroupConsult: 'group_consult',
  DietitianConsult: 'dietitian_consult',
  Guide: 'guide',
  Home: 'home',
  IndividualFoods: 'individual_foods',
  IntroVideo: 'intro_video',
  Instagram: 'instagram',
  LandingScreenSupplement: 'landing_screen_supplement',
  LinkedIn: 'linkedin',
  Login: 'login',
  Logout: 'logout',
  MobileAppBanner: 'mobile_app_banner',
  MyAccount: 'my_account',
  MyFoods: 'my_foods',
  MyInsights: 'my_insights',
  MyNutrients: 'my_nutrients',
  Navbar: 'navbar',
  Nutrient: (nutrientName) => snakeCase(nutrientName),
  NutrientConversion: (conversion) => snakeCase(conversion),
  NutrientUpSell: (name) => 'nutrients_' + snakeCase(name),
  FoodUpSell: (name) => 'food_index_' + snakeCase(name),
  OpenFoodSettings: 'open_food_settings',
  PageClosed: 'page_closed',
  PageOpened: 'page_opened',
  PauseSubscription: 'pause_subscription',
  Pinterest: 'pinterest',
  PrivacyAndSecurityLink: 'privacy_and_security_link',
  PrivacyPolicy: 'privacy_policy',
  PurchasedProducts: 'purchased_products',
  Questionnaire: 'questionnaire',
  RegistrationInput: (input) => snakeCase(input),
  Remove: 'remove',
  ScheduleConsultation: 'schedule_consultation',
  Setting: (settingName) => snakeCase(settingName),
  SettingsType: (settingsTypeName) => snakeCase(settingsTypeName),
  Source: (source) => snakeCase(source),
  Shop: 'shop',
  ShopPageSupplement: 'shop_page_supplement',
  ShopViewProduct: 'shop_view_product',
  Submit: 'submit',
  SupplementBanner: 'supplement_banner',
  TermsOfService: 'terms_of_service',
  TopFood: (name) => `top_food_${snakeCase(name)}`,
  Trait: (trait) => snakeCase(trait),
  Twitter: 'twitter',
  UpdatePassword: 'update_password',
  UpdateProfile: 'update_profile',
  UpdateShipping: 'update_shipping',
  ViewOptimalFoods: 'view_optimal_foods',
  X: 'x',
  DeleteAccountRequested: 'delete_account_requested',
  UpdateBlendSubscription: 'update_blend_subscription',
  RegisterThirdPartyDNAkit: 'register_third_party_dna_kit',
  ConsultationLandingViaEmail: 'consultation_email_button',
  ConsultationLandingScheduleLinkClicked: 'consultation_landing_page_signup'

};

export {
  GoogleAnalyticsConnected as GoogleAnalytics,
  RouteTracker,
  init,
  setTimestampCustomDimension,
  registerGAClickEventEnhanced,
  mapPathToPageTitle,
  EventCategories,
  EventActions,
  EventLabels,
};
