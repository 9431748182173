import React, { useState, useMemo, useCallback, useEffect } from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Grid,
  IconButton,
  Typography,
  Divider,
  Box,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import ArrowForward from '@material-ui/icons/ArrowRightAltSharp'
import { Link, useHistory } from 'react-router-dom'
import { useStyles } from './BlendSubscriptionHeader.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { appConstants } from 'utils/constants/appConstants'
import CustomRadio from 'components/CustomRadio'
import { colors } from 'common/assets/sharedUI'
import CounterComponent from 'components/CounterComponent'
import DropDownPicker from 'components/DropDownPicker'
import { CustomDialog, Seprator } from 'components/shared'
import { CancelSubscriptionDialog, PauseSubscriptionDialog } from 'components/SupplementSubscription'
import { AccountAlert } from 'components/AccountForm'

import {
  EventActions,
  EventCategories,
  EventLabels,
  registerGAClickEventEnhanced,
} from 'utils/googleanalytics'
import { addItemToCart } from 'store/actions/additionalProducts'
import {
  cancelSubscription,
  pauseSubscription,
  updateSubscription,
} from 'store/actions/subscription'
import { useQueryParams, useUserCurrentSubscription } from '../../../hooks'
import SubscribedUser from '../SubscribedUser'
import {
  AlertTypes,
  blendSKUsV1,
  defaultErrorMessage,
  genericErrorMessage,
  introductoryHeading,
  introductorySubtext,
} from 'resources/constants'
import helpers from 'utils/helpers'
import { fonts } from 'common/assets/sharedUI'
import { SUBSCRIPTION_TYPE } from 'utils/subscriptionHelpers'
import { genopalateClickIdKey } from 'services/shopify/utils'
import { images } from 'resources'

const PLAN_TYPE = {
  ONE_TIME: 'one-time',
  SUBSCRIPTION: 'subscription',
}

const FLAVOR_ERROR = {
  MESSAGE: 'No flavor selected',
}

export const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
  const formattedPrice = helpers.formatNumber(
    originalPrice * (1 - discountPercentage / 100)
  )
  return formattedPrice
}

// TODO: add the skeleton or loader until data loads
function BlendSubscriptionHeader({
  baseProduct,
  sellingPlans = [],
  variants,
  isItemInCart,
}) {
  const { queryParams, getQueryParam } = useQueryParams()
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { currentBlendSubscription: currentSubscription, shippingData, paymentMethod, refetch } =
    useUserCurrentSubscription('GenoBlend')

  // set the delivery day default to 30
  const defaultDeliveryDay =
    sellingPlans?.filter(
      (sellingPlan) => sellingPlan.deliveryPolicy.intervalCount === 30
    )[0]?.value ||
    sellingPlans[0]?.value ||
    undefined

  const [selectedValue, setSelectedValue] = useState(PLAN_TYPE.SUBSCRIPTION)
  const [quantity, setQuantity] = useState(1)
  const [flavor, setFlavor] = useState('') //TODO: change to variant id
  const [flavorError, setFlavorError] = useState(null)
  const [deliveryDays, setDeliveryDays] = useState(defaultDeliveryDay)

  const [showPauseDialog, setShowPauseDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showUpdateDialog, setShowUpdateDialog] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertTitle, setAlertTitle] = useState('')
  const [alertType, setAlertType] = useState(AlertTypes.ERROR)
  const nextOrderDate = useMemo(() => (currentSubscription ? new Date(currentSubscription.nextOrderDate) : null), [currentSubscription]);
  const { cart, spinnerOpen } = useSelector((state) => state.additionalProducts.cart);

  const {
    cancelSubscriptionFetching,
    cancelSubscriptionError,
    cancelSubscriptionSuccess,
  } = useSelector((state) => ({
    cancelSubscriptionFetching: state.subscription.cancelSubscription.fetching,
    cancelSubscriptionError: state.subscription.cancelSubscription.error,
    cancelSubscriptionSuccess: state.subscription.cancelSubscription.data,
  }))

  const { pauseSubscriptionFetching, pauseSubscriptionError, pauseSubscriptionSuccess } = useSelector(state => ({
    pauseSubscriptionFetching: state.subscription.pauseSubscription.fetching,
    pauseSubscriptionError: state.subscription.pauseSubscription.error,
    pauseSubscriptionSuccess: state.subscription.pauseSubscription.data,
  }));

  const { updateSubscriptionFetching, updateSubscriptionError } = useSelector(
    (state) => ({
      updateSubscriptionFetching:
        state.subscription.updateSubscription.fetching,
      updateSubscriptionError: state.subscription.updateSubscription.error,
    })
  )
  useEffect(() => {
    if(cart?.lineItems){
      const cartItem = cart?.lineItems.find((item) => item.sku === blendSKUsV1[item.sku]);
      const planType = !cartItem?.isOneTime ? PLAN_TYPE.SUBSCRIPTION : PLAN_TYPE.ONE_TIME;
      setSelectedValue(!!cartItem ? planType : PLAN_TYPE.SUBSCRIPTION);
    }
  },[cart]);

  const handleFlavorChange = (e) => {
    const selectedFlavor = e.target.value;
    setFlavor(selectedFlavor);

    if (!selectedFlavor) {
      setFlavorError(FLAVOR_ERROR.MESSAGE);
    }
  }

  const handleDeliveryDaysChange = (e) => {
    setDeliveryDays(e.target.value)
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const addItem = () => {
    if (!flavor) {
      setFlavorError(FLAVOR_ERROR.MESSAGE);
      return;
    }

    setFlavorError(null);
    const product = baseProduct
    const productId = flavor ? flavor : product.id

    let additionalAttributes = {};
    if (getQueryParam(genopalateClickIdKey)){
      additionalAttributes = {
        key: genopalateClickIdKey,
        value: getQueryParam(genopalateClickIdKey)
      };
    }

    if (selectedValue === PLAN_TYPE.SUBSCRIPTION) {
      dispatch(
        addItemToCart(
          productId,
          deliveryDays ? deliveryDays : undefined,
          quantity,
          additionalAttributes
        )
      )
    }

    if (selectedValue === PLAN_TYPE.ONE_TIME) {
      dispatch(addItemToCart(productId, undefined, quantity, additionalAttributes))
    }
  }

  const addToCartHandled = () => {
    const product = baseProduct
    registerGAClickEventEnhanced(
      EventCategories.GenoBlend,
      EventActions.AddToCart,
      product.name
    )
    addItem()
    // setShowQuestionnaireDialog(false); //TODO: please check
  }

  const removeFromCartClicked = useCallback(
    (lineItem) => {
      registerGAClickEventEnhanced(
        EventCategories.GenoBlend,
        EventActions.RemoveFromCart,
        lineItem.title
      )
      dispatch(removeItemFromCart(lineItem.cartLineId))
    },
    [dispatch]
  )

  const handleCancelSubscription = useCallback(
    (reason, comments) => {
      registerGAClickEventEnhanced(
        EventCategories.GenoBlend,
        EventActions.Click,
        EventLabels.CancelSubscription
      )
      dispatch(
        cancelSubscription(currentSubscription.subscriptionId, reason, comments)
      )
    },
    [currentSubscription, dispatch]
  )

  const handlePauseSubscription = useCallback((pauseDate) => {
    const additionalProperties = [{
      name: 'SUBSCRIPTION_TYPE',
      value: SUBSCRIPTION_TYPE.PAUSE,
    },
    {
      name: '_oneTime',
      value: 'No',
    }
  ];
    registerGAClickEventEnhanced(EventCategories.GenoBlend, EventActions.Click, EventLabels.PauseSubscription);
    dispatch(pauseSubscription(currentSubscription.subscriptionId, pauseDate, additionalProperties));
  }, [currentSubscription, dispatch]);

  const handleCloseCancelDialog = useCallback(() => {
    setShowCancelDialog(false)
    setIsCanceling(false)
  }, [])

  const handleCloseDialogAfterCancellation = useCallback(() => {
    setShowCancelDialog(false)
    setIsCanceling(false)
    history.push('/shop')
    window.location.reload();
  }, [history])

  const oneTimePrice = useMemo(() => {
    let price = baseProduct?.price || '';

    if (flavor && baseProduct?.variants?.edges) {
        price = baseProduct?.variants?.edges.find((variant) => variant.node['id'] === flavor)['node']['price']
    }

    return price;
  }, [flavor])

  const handleNewAlert = useCallback(({ message, title, type }) => {
    setAlertMessage(message)
    setAlertTitle(title)
    setAlertType(type)
    setShowAlert(true)
  }, [])

  const handleCloseAlert = useCallback(() => {
    setAlertMessage('')
    setAlertTitle('')
    setAlertType(AlertTypes.DEFAULT)
    setShowAlert(false)
  }, [])

  useEffect(() => {
    if (!cancelSubscriptionFetching && cancelSubscriptionError) {
      handleNewAlert({
        message:
          cancelSubscriptionError === 'error' ||
          cancelSubscriptionError.includes(genericErrorMessage)
            ? defaultErrorMessage
            : cancelSubscriptionError,
        title: 'Error',
        type: AlertTypes.ERROR,
      })
    }
  }, [cancelSubscriptionFetching, cancelSubscriptionError, handleNewAlert])

  useEffect(() => {
    if (updateSubscriptionFetching === false && updateSubscriptionError) {
      handleNewAlert({
        message:
          updateSubscriptionError === 'error' ||
          updateSubscriptionError.includes(genericErrorMessage)
            ? defaultErrorMessage
            : updateSubscriptionError,
        title: 'Error',
        type: AlertTypes.ERROR,
      })
      //TODO: refactor
      setIsUpdating(false)
    }
  }, [updateSubscriptionFetching, updateSubscriptionError, handleNewAlert])

  const handleSubscriptionUpdated = () => {
    setIsUpdating(false)
    handleOpenUpdateDialog()
  }

  const onUpdateSubscriptionClicked = useCallback(
    (data) => {
      setIsUpdating(true)

      const payload = {
        ...data,
        properties: [
          {
            name: 'SUBSCRIPTION_TYPE',
            value: SUBSCRIPTION_TYPE.UPDATED,
          },
          {
            name: '_oneTime',
            value: 'No',
          }
        ],
      }

      registerGAClickEventEnhanced(
        EventCategories.GenoBlend,
        EventActions.Update,
        EventLabels.UpdateBlendSubscription
      )

      dispatch(
        updateSubscription(
          currentSubscription.subscriptionId,
          payload,
          handleSubscriptionUpdated
        )
      )
    },
    [dispatch]
  )

  const handleOpenUpdateDialog = useCallback(() => {
    setShowUpdateDialog(true)
  }, [])

  const handleCloseUpdateDialog = useCallback(() => {
    refetch()
    setShowUpdateDialog(false)
  }, [])

  const onCancelSubscriptionClicked = useCallback(() => {
    setShowPauseDialog(true);
    setIsCanceling(true);
  }, [])

  const handleClosePauseDialog = useCallback(() => {
    // refetch the data for showing updated info
    // refetch();
    setShowPauseDialog(false);
  }, []);

  const handleOpenCancelSubscriptionFromPause = useCallback(() => {
    handleClosePauseDialog();
    handleOpenCancelDialog();
  }, [])

  const handleOpenCancelDialog = useCallback(() => {
    setShowCancelDialog(true)
  }, [])

  const handleOpenPauseDialog = useCallback(() => {
    setIsCanceling(false);
    setShowPauseDialog(true);
  }, []);

  // clean up any old alerts when page initially loads
  useEffect(() => {
    handleCloseAlert()
  }, [handleCloseAlert])

  return (
    <>
      <Grid container>
        <Link to={currentSubscription ? '/account' : '/shop'}>
          <div className={classes.backLink}>
            <FontAwesomeIcon icon={faArrowLeft} className={classes.icon} />
            <p className={classes.backLinkText}>
              {currentSubscription ? 'My Products' : 'Shop'}
            </p>
          </div>
        </Link>

        <React.Fragment>
          <Grid item xs={12} className={classes.productOuterWrapper}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <div className={classes.responsiveWrapper}>
                  <img
                    alt='Product'
                    className={classes.subscriptionImage}
                    src='https://cdn.shopify.com/s/files/1/0639/5766/3902/files/genoblend-app_1.jpg?v=1713940281'
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                {currentSubscription ? (
                  <SubscribedUser
                    baseProduct={baseProduct}
                    currentSubscription={currentSubscription}
                    shippingData={shippingData}
                    paymentMethod={paymentMethod}
                    variants={variants}
                    sellingPlans={sellingPlans}
                    loading={isUpdating}
                    onCancelSubscription={onCancelSubscriptionClicked}
                    onUpdateSubscription={onUpdateSubscriptionClicked}
                    onPauseSubscription={handleOpenPauseDialog}
                  />
                ) : (
                  <div className={classes.detailsContainer}>
                    <Typography className={classes.subscriptionTitle}>
                      {baseProduct?.name || appConstants.genoBlends.header}
                    </Typography>
                    <Typography className={classes.subscriptionDescription}>
                      {appConstants.genoBlends.description}
                    </Typography>

                    <Seprator color={colors.grey} />

                    <Typography className={classes.subHeading}>
                      {appConstants.pickYourPlan}
                    </Typography>

                    <FormControl
                      disabled={isItemInCart}
                      className={classes.formControl}
                    >
                      <Grid
                        container
                        direction='column'
                        alignContent='flex-start'
                        md={10}
                        sm={12}
                      >
                        <RadioGroup
                          aria-label='plan_type'
                          name='plan_type'
                          value={selectedValue}
                          onChange={handleChange}
                          className={classes.formControl}
                        >
                          <FormControlLabel
                            disabled={isItemInCart}
                            value={PLAN_TYPE.ONE_TIME}
                            className={[
                              classes.formControlOneTime,
                              selectedValue === PLAN_TYPE.ONE_TIME
                                ? classes.selectedRadio
                                : '',
                            ]}
                            control={
                              <CustomRadio
                                checked={selectedValue === PLAN_TYPE.ONE_TIME}
                                // onChange={handleChange}
                                // value={PLAN_TYPE.ONE_TIME}
                              />
                            }
                            label={`One Time Purchase ${helpers.formatNumber(
                              oneTimePrice
                            )}`}
                          />

                          <Grid
                            container
                            direction='column'
                            className={[
                              classes.formControlSubscriptionContainer,
                              selectedValue === PLAN_TYPE.SUBSCRIPTION
                                ? classes.selectedRadio
                                : '',
                            ]}
                          >
                            <Box className={classes.introductoryPriceBox}>
                              <Typography className={classes.introductoryHeading}>{introductoryHeading}</Typography>
                              <Typography className={classes.introductorySubtext}>{introductorySubtext}</Typography>
                            </Box>
                            <FormControlLabel
                              disabled={isItemInCart}
                              value={PLAN_TYPE.SUBSCRIPTION}
                              control={
                                <CustomRadio
                                  checked={
                                    selectedValue === PLAN_TYPE.SUBSCRIPTION
                                  }
                                  // onChange={handleChange}
                                  // value={PLAN_TYPE.ONE_TIME}
                                />
                              }
                              label={`Subscribe & Save 9% ${calculateDiscountedPrice(
                                oneTimePrice,
                                9.21
                              )}`}
                            />
                            <DropDownPicker
                              extraStylesDropDownPicker={classes.dropDownPicker}
                              extraStylesDropDownPickerItem={
                                classes.dropDownItem
                              }
                              value={deliveryDays}
                              handleDropDownChange={handleDeliveryDaysChange}
                              data={sellingPlans}
                            />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </FormControl>

                    <Divider variant='fullWidth' />
                    <Typography className={classes.subHeading}>
                      {appConstants.selectAFlavor.header}
                    </Typography>
                    <Typography className={classes.subheadingDescription}>
                      {appConstants.selectAFlavor.description}
                    </Typography>

                    <Grid container md={8} xs={12} spacing={2} style={{ marginLeft: 0, marginTop: 1 }}>
                      <DropDownPicker
                        data={variants}
                        value={flavor}
                        extraStylesDropDownPicker={classes.flavorDropDownPicker}
                        extraStylesDropDownPickerItem={classes.dropDownItem}
                        handleDropDownChange={handleFlavorChange}
                        placeholder='Select a Flavor'
                        error={flavorError}
                      />
                    </Grid>

                    <Grid
                      container
                      direction='row'
                      md={8}
                      justify='space-between'
                      spacing={2}
                      className={classes.checkoutContainer}
                    >
                      <Grid item xs={4} md={4}>
                        <CounterComponent
                          quantity={quantity}
                          setQuantity={setQuantity}
                          disabled={isItemInCart}
                        />
                      </Grid>

                      <Grid item xs={8} md={8}>
                        <Button
                          disabled={isItemInCart}
                          className={
                            isItemInCart
                              ? classes.addedToCartButton
                              : classes.addToCartButton
                          }
                          // onClick={
                          //   questionnaire && questionnaire.questionnaireComplete
                          //     ? () => addToCartHandled(baseProduct)
                          //     : openQuestionnaireDialog
                          // }
                          onClick={addToCartHandled}
                        >
                          {isItemInCart
                            ? appConstants.addedToCart
                            : appConstants.addToCart}
                          <IconButton size='small' htmlColor={colors.white}>
                            <ArrowForward
                              fontSize='small'
                              htmlColor={
                                isItemInCart
                                  ? colors.buttonDisabledText
                                  : colors.white
                              }
                            />
                          </IconButton>
                        </Button>
                      </Grid>
                    </Grid>
                    {!isItemInCart ? (
                      <Grid container alignItems="center" spacing={1} xs={12} md={8} className={classes.truemedMargin}>
                        <Grid item>
                          <Typography>
                            HSA/FSA eligible with
                          </Typography>
                        </Grid>
                        <Grid item>
                          <img
                            className={classes.truemedLogo}
                            src={images.truemedLogo}
                            alt="trueMed"
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            className={classes.primaryButton}
                            component="a"
                            href="https://www.genopalate.com/pages/supplements-fsa-hsa-eligible?coback=1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn more
                            <ArrowForward fontSize="small" className={classes.arrowIcon} />
                          </Button>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </Grid>

      <CancelSubscriptionDialog
        open={showCancelDialog}
        questionText='What is your reason for canceling your GenoBlend subscription?'
        handleClose={handleCloseCancelDialog}
        handleCancel={handleCancelSubscription}
        success={
          cancelSubscriptionFetching === false && !!cancelSubscriptionSuccess
        }
        handleCloseSuccess={handleCloseDialogAfterCancellation}
      />

      <PauseSubscriptionDialog
        open={showPauseDialog}
        handleClose={handleClosePauseDialog}
        handleCancel={handleOpenCancelSubscriptionFromPause}
        handlePause={handlePauseSubscription}
        success={pauseSubscriptionFetching === false && !!pauseSubscriptionSuccess}
        renewalDate={nextOrderDate}
        isCanceling={isCanceling}
      />

      <CustomDialog
        open={showUpdateDialog}
        title='Subscription Updated'
        content='Your subscription details have been updated.'
        handleClose={handleCloseUpdateDialog}
      />

      {showAlert && (
        <AccountAlert
          title={alertTitle}
          message={alertMessage}
          type={alertType}
          onClose={() => handleCloseAlert()}
        />
      )}
    </>
  )
}

export default BlendSubscriptionHeader

BlendSubscriptionHeader.propTypes = {
  isItemInCart: PropTypes.bool,
}
