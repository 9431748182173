import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import icons from "resources/icons";
import { Paper, Typography, Grid, Container } from "@material-ui/core";
import useStyles from "./TipsPaper.styles";

const TipsPaper = ({ traitData, sensitivitiesOrSubstances }) => {
  const classes = useStyles();
  const domainActionItems = useSelector(
    (state) => state.actionItems.domainActionItems
  );

  const [actionItems] =
    domainActionItems && traitData
      ? domainActionItems.filter(
          (rec) => rec.geneticInsightId === traitData.geneticInsightId
        )
      : [{ description: "" }];

  if (!actionItems && sensitivitiesOrSubstances) {
    return null;
  }

  const description = actionItems?.description || "";
  let [firstSentence, ...remainingParts] = description.split(".");
  firstSentence = firstSentence ? `${firstSentence}.` : "";
  const remainingDescription =
    remainingParts.length > 0 ? remainingParts.join(".").trim() : "";

  return (
    <div className={classes.tipContainer}>
      <Paper className={classes.paper}>
        <Container style={{ flexGrow: 1 }} maxWidth="lg">
          {!!actionItems && (
            <div className={classes.titleContainer}>
              <img
                src={icons.tipsIcon}
                className={classes.icon}
                name="tipsIcon"
                alt=""
              />

              <Grid item xs={12}>
              <Typography
                    variant="h6"
                    className={classes.title}
                    name="tipsTitle"
                  >
                    What You Can Do
                  </Typography>
                <hr className={classes.headerBar}/>
                <Typography
                  variant="body1"
                  name="tipsDescription"
                  className={classes.tipsDescription}
                >
                  {firstSentence}
                </Typography>
                <Typography variant="body1" name="tipsSubDescription">
                  {remainingDescription}
                </Typography>
              </Grid>
            </div>
          )}
        </Container>
      </Paper>
    </div>
  );
};

export default TipsPaper;

TipsPaper.propTypes = {
  sensitivitiesOrSubstances: PropTypes.bool.isRequired,
  traitData: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};
