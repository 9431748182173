import React, { useCallback, useMemo, useState } from 'react'
import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  createStyles,
  makeStyles,
  Divider,
  Button,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import ArrowForward from '@material-ui/icons/ArrowRightAltSharp'

import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'
import {
  faCalendarClock,
  faCreditCard,
  faLocationDot,
  faTruck,
} from '@fortawesome/pro-solid-svg-icons'

import { colors, fonts } from '../../common/assets/sharedUI'
import Seprator from 'components/shared/Seprator'
import DropDownPicker from 'components/DropDownPicker'
import CounterComponent from 'components/CounterComponent'
import { formatDateToTwoDigit } from 'services/shopify/utils'
import {
  EventActions,
  EventCategories,
  EventLabels,
  registerGAClickEventEnhanced,
} from 'utils/googleanalytics'
import { updateSubscription } from 'store/actions/subscription'

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },

    box: {
      textAlign: 'center',
      background: 'red',
    },

    icon: {
      minWidth: '37px',
      color: colors.gray_04,
    },

    text: {
      color: colors.gray_05,
      fontFamily: fonts.Baskerville,
    },

    productInfo: {
      marginTop: 10,
      marginBottom: 10,
    },

    productTitle: {
      fontFamily: fonts.BaskervilleBold,
      color: colors.gray_06,
      fontSize: 32,
      lineHeight: '32px',
      marginTop: 10,
      marginBottom: 20,
    },

    productSubTitle: {
      fontFamily: fonts.Swiss721MD,
      color: colors.gray_07,
    },

    planWrapper: {
      margin: '10px 0px',
    },

    planText: {
      fontFamily: fonts.Swiss721MD,
      color: colors.gray_06,
    },

    note: {
      fontSize: 14,
      fontFamily: fonts.Swiss721LT,
      margin: '10px 0',
      paddingTop: 0,
      paddingBottom: 0,
    },

    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: 3,
      alignItems: 'center',
      margin: '30px 0px',
    },

    container: {
      marginTop: 10,
      marginBottom: 10,
    },

    dropDownItem: {
      color: colors.green_07,
      fontSize: 14,
    },

    flavorDropDownPicker: {
      borderWidth: 1,
      border: `1px solid ${colors.gray_03}`,
      color: colors.green_07,
      paddingLeft: 10,
      fontSize: 14,
      flexGrow: 1,
    },

    dropDownPicker: {
      marginLeft: 37,
      marginRight: 20,
      borderWidth: 1,
      border: `1px solid ${colors.gray_03}`,
      color: colors.green_07,
      paddingLeft: 10,
      fontSize: 14,
    },

    subHeading: {
      fontSize: 15,
      color: colors.gray_06,
      marginTop: 20,
      textTransform: 'uppercase',
      fontWeight: '600',
    },
    subheadingDescription: {
      fontSize: 11,
      color: colors.gray_07,
      marginTop: 5,
      width: '100%',
    },

    supplyPouchText: {
      fontSize: '0.9rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.8rem',
      },
    },

    counterContainerClass: {
      height: 30,
      padding: '6px 12px',
    },

    button: {
      backgroundColor: colors.blue_04,
      color: colors.white,
      '&.Mui-disabled': {
        backgroundColor: colors.gray_07,
        color: colors.white,
      },
      '&:hover': {
        backgroundColor: colors.blue_05,
      },
    },
  })
)

const SubscribedUser = ({
  baseProduct,
  loading,
  currentSubscription,
  shippingData,
  paymentMethod,
  variants,
  sellingPlans,
  onCancelSubscription,
  onUpdateSubscription,
  onPauseSubscription,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const currentsSubscriptionFlavor = variants?.find(variant => variant.sku === currentSubscription?.productSku)?.value || variants[0]?.value || undefined
    
  const [flavor, setFlavor] = useState(currentsSubscriptionFlavor) //TODO: change to variant id
  const [deliveryDays, setDeliveryDays] = useState(
    currentSubscription?.duration || undefined
  )
  const [quantity, setQuantity] = useState( currentSubscription?.quantity || 1)
  const [isDirty, setIsDirty] = useState(false)

  const handleDeliveryDaysChange = (e) => {
    setDeliveryDays(e.target.value)
    setIsDirty(true)
  }

  const handleFlavorChange = (e) => {
    setFlavor(e.target.value)
    setIsDirty(true)
  }

  const handleQuantityChange = (quantity) => {
    setQuantity(quantity)
    setIsDirty(true)
  }

  const shipCity = useMemo(
    () => get(shippingData, 'address.shipCity', ''),
    [shippingData]
  )
  const shipState = useMemo(
    () => get(shippingData, 'address.shipState', ''),
    [shippingData]
  )
  const last4 = useMemo(
    () => get(paymentMethod, 'paymentDetails.last4', ''),
    [paymentMethod]
  )

  const handleSaveChanges = useCallback(async () => {
    const variant = flavor.split('/').pop() // Get the variant id only

    const payload = {
      quantity,
      frequency: deliveryDays,
      variant,
    }

    onUpdateSubscription(payload)
    setIsDirty(false)
  }, [
    currentSubscription?.subscriptionId,
    flavor,
    quantity,
    deliveryDays,
    dispatch,
  ])

  // mutate the object because recharge update subscription takes the frequency
  const updatedSellingPlans = sellingPlans?.map((sellingPlan) => {
    return {
      ...sellingPlan,
      value: sellingPlan?.deliveryPolicy?.intervalCount || '',
    }
  })

  return (
    <div>
      <Grid container alignItems='center'>
        <Grid item xs={12} md={6}>
          <ListItem disableGutters dense alignItems='flex-start'>
            <ListItemIcon className={classes.icon}>
              <FontAwesomeIcon icon={faTruck} className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={`Next Delivery ${formatDateToTwoDigit(
                new Date(currentSubscription?.nextDeliveryDate)
              )}`}
              className={classes.text}
            />
          </ListItem>

          <ListItem disableGutters dense alignItems='flex-start'>
            <ListItemIcon className={classes.icon}>
              <FontAwesomeIcon icon={faLocationDot} className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={`To ${shipCity} ${shipState}`}
              className={classes.text}
            />
          </ListItem>

          <ListItem
            alignItems='flex-start'
            style={{ textDecoration: 'underline' }}
            component={Link}
            to='/account'
          >
            <ListItemText
              primary='Update your Shipping Address'
              className={classes.text}
            />
            <Divider />
          </ListItem>
        </Grid>

        <Divider orientation='vertical' flexItem />

        <Grid item xs={12} md={5}>
          <ListItem disableGutters dense alignItems='flex-start'>
            <ListItemIcon className={classes.icon}>
              <FontAwesomeIcon
                icon={faCalendarClock}
                className={classes.icon}
              />
            </ListItemIcon>
            <ListItemText
              primary={`Renews ${formatDateToTwoDigit(
                new Date(currentSubscription?.nextOrderDate)
              )}`}
              className={classes.text}
            />
          </ListItem>

          <ListItem disableGutters dense alignItems='flex-start'>
            <ListItemIcon className={classes.icon}>
              <FontAwesomeIcon icon={faCreditCard} className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={`Card ending ${last4}`}
              className={classes.text}
            />
          </ListItem>

          <ListItem
            component={Link}
            to='/account'
            alignItems='flex-start'
            style={{
              paddingRight: 0,
              textDecoration: 'underline',
            }}
          >
            <ListItemText
              primary='Update your payment method'
              className={classes.text}
            />
            <Divider />
          </ListItem>
        </Grid>
      </Grid>

      <Box className={classes.productInfo}>
        <Typography className={classes.productTitle}>
          {baseProduct?.name}
        </Typography>

        <Typography className={classes.productSubTitle}>
          Get a custom protein and fiber formula that’s personalized for you
          based on your DNA and delivered straight to your door.
        </Typography>
      </Box>

      <Seprator color={colors.grey} />

      <Box className={classes.planWrapper}>
        <Typography variant='h6' className={classes.planText}>
          Your Plan
        </Typography>

        <Alert className={classes.note} severity='info'>
          Changes you make here will affect all future orders, not just this
          one.
        </Alert>
      </Box>

      <Grid
        container
        alignItems='center'
        spacing={2}
        className={classes.container}
      >
        <Grid item xs={6} md={4}>
          <CounterComponent
            quantity={quantity}
            setQuantity={handleQuantityChange}
            disabled={false}
            label='You Get'
            counterContainerClass={classes.counterContainerClass}
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <Typography variant='body1' className={classes.supplyPouchText}>
            14-day supply pouch(es)
          </Typography>
        </Grid>

        <Grid container xs={12} md={4}>
          <DropDownPicker
            value={deliveryDays}
            handleDropDownChange={handleDeliveryDaysChange}
            data={updatedSellingPlans}
            extraStylesDropDownPicker={classes.flavorDropDownPicker}
            extraStylesDropDownPickerItem={classes.dropDownItem}
          />
        </Grid>
      </Grid>

      <Seprator />

      <Typography className={classes.subHeading}>Your Flavor</Typography>

      <Grid container md={10} xs={12}>
        <DropDownPicker
          data={variants}
          value={flavor}
          extraStylesDropDownPicker={classes.flavorDropDownPicker}
          extraStylesDropDownPickerItem={classes.dropDownItem}
          handleDropDownChange={handleFlavorChange}
        />
      </Grid>

      <Box style={{ marginTop: '20px' }}>
        <Box>
          <Button
            disableElevation
            variant='contained'
            endIcon={
              loading ? (
                <CircularProgress size={18} color='inherit' />
              ) : (
                <ArrowForward fontSize='small' />
              )
            }
            className={classes.button}
            disabled={!isDirty || loading}
            onClick={handleSaveChanges}
          >
            Save Changes
          </Button>
        </Box>

        <Box>
          <Button
            variant='text'
            disableElevation
            disableRipple
            disableFocusRipple
            style={{ textDecoration: 'underline', margin: '5px 0px' }}
            onClick={onPauseSubscription}
          >
            Pause subscription
          </Button>
          <Button
            variant='text'
            disableElevation
            disableRipple
            disableFocusRipple
            style={{ textDecoration: 'underline', margin: '5px 0px' }}
            onClick={onCancelSubscription}
          >
            Cancel subscription
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default SubscribedUser
