/* eslint-disable react/prefer-stateless-function */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputBase, InputAdornment, IconButton, Tooltip,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { colors, fontFamily } from 'common/assets/sharedUI';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const handleGAHoverCall = (eventLabel) => registerGAClickEventEnhanced(EventCategories.Registration, EventActions.Hover, EventLabels.RegistrationInput(eventLabel));

const styles = {
  input: {
    border: `1px solid ${colors.gray_03}`,
    fontFamily,
    borderRadius: '5px',
    width: '100%',
    '& input': {
      padding: 10,
    },
  },
  label: {
    color: colors.bodyText,
    fontFamily,
    fontSize: '16px',
  },
  wrapper: {
    margin: '5px 0',
  },
  error: {
    color: colors.red,
    fontSize: 13,
    marginTop: 4,
  },
};

const RegistrationInput = ({
  label, classes, type, tooltip, error, ...extraProps
}) => {
  const [hidden, setHidden] = useState(true);
  const [open, setOpen] = React.useState(false);

  const toggleVisibility = () => {
    setHidden(!hidden);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const inputProps = {
    className: classes.input,
    style: error ? { borderColor: colors.red } : null,
    type: type === 'password' && !hidden ? 'text' : type,
    endAdornment: type === 'password'
      ? (
        <InputAdornment position="end">
          <IconButton onClick={toggleVisibility}>
            {hidden ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )
      : null,
    ...extraProps,
  };

  return (
    <div className={classes.wrapper} style={type === 'hidden' ? { display: 'none' } : null}>
      {label && (
        <div style={{ marginBottom: 3 }}>
          <span className={classes.label} style={error ? { color: colors.red } : null}>{label}</span>
          {' '}
        
          {tooltip && (
              <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip title={tooltip}
            onClose={handleTooltipClose}
            open={open }
            slotProps={  {
              popper: {
                disablePortal: true,
              },
            }}
            onOpen={() => handleGAHoverCall(label)}>
              {/** Must wrap FontAwesomeIcon in a div because it does not support
             * forward refs: https://material-ui.com/guides/composition/#caveat-with-refs */}
              <div onClick={handleTooltipOpen} onMouseEnter={handleTooltipOpen} style={{ display: 'inline' }}>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </div>
            </Tooltip>
           </ClickAwayListener>
          )}
        </div>
      )}
      <InputBase {...inputProps} />
      {error && <div className={classes.error}>{error}</div>}
    </div>
  );
};

RegistrationInput.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
    wrapper: PropTypes.string,
    error: PropTypes.string,
  }),
  type: PropTypes.oneOf(['password', 'email', 'text', 'hidden']),
  tooltip: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

RegistrationInput.defaultProps = {
  label: '',
  classes: null,
  type: 'text',
  tooltip: null,
  error: null,
};

export default withStyles(styles)(RegistrationInput);
