import React, { useState } from 'react';
import {
    Button, Typography, Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    Dialog, DialogTitle as MuiDialogTitle, DialogContent, DialogContentText, IconButton, withStyles
} from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './GeneticImpactCards.styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { sharedStyles } from "resources";
import clsx from "clsx";
import { colors } from "common/assets/sharedUI";
import { ReferenceCell } from './VariantDetected';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const GeneticImpactCards = ({ geneDetails, key, references }) => {
    const classes = useStyles();
    const [geneDetail, setGeneDetail] = useState(null);
    const [selectedGeneticTab, setSelectedGeneticTab] = useState(geneDetails.genes.filter((gene) =>
        gene.markers?.some((marker) => marker.variant)
    ).length < 1 ? 1 : 0);

    const handleOpen = (detail) => {
        setGeneDetail(detail);
    };

    const handleClose = () => {
        setGeneDetail(null);
    };

    const scrollToReferences = (targetElementId) => {
        handleClose();
        const targetElement = document.getElementById(`Ref${targetElementId - 2}`);
        if (targetElement) {
            const offset = 0;

            const elementPosition = targetElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    if (!geneDetails || !geneDetails.genes || geneDetails.genes.length === 0) {
        return <Typography>Loading...</Typography>;
    }


    const Accordion = ({ title, content, expanded, onClick, disabled }) => {
        const classes = useStyles();

        return (
            <div className={classes.root}>
                <ExpansionPanel expanded={expanded} className={classes.expansionPanel} disabled={disabled}>
                    <ExpansionPanelSummary
                        onClick={onClick}
                        className={classes.summary}
                        expandIcon={
                            <ExpandMoreIcon style={{ color: expanded ? colors.white : colors.gray_06 }} />
                        }
                        aria-controls="panel-content"
                        id="panel-header"
                        classes={{
                            expandIcon: classes.iconButtonEdgeEnd,
                        }}
                    >
                        <Typography className={classes.heading}>{title}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                        {content}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    };

    const TableHeader = () => {
        return <TableHead>
            <TableRow>
                <TableCell className={`${classes.tableHeader} ${classes.pl_4}`}>MARKER</TableCell>
                <TableCell className={classes.tableHeader}>GENOTYPE</TableCell>
                <TableCell className={`${classes.tableHeader}`}>
                    WHAT YOUR GENOTYPE MEANS
                </TableCell>
            </TableRow>
        </TableHead>
    }

    const RenderRows = ({ gene, marker, classes, markerIndex, textColor, removePadding }) => {

        const generateGpNumber = (marker) => {
            const zeroPaddedId = String(marker.markerId).padStart(6, '0');
            return `GP${zeroPaddedId}`;
        }
        return <>
            <TableCell className={`${classes.tableCellBase} ${removePadding ? classes.rowBorder : classes.pl_4}`}>
                {markerIndex === 0 && (
                    <Box mb={4}>
                        <Typography className={classes.tableBody}>Gene</Typography>
                        <Typography className={`${classes.geneName}`}>{gene.geneName}</Typography>
                    </Box>
                )}

                <Box key={markerIndex}>
                    <Typography className={classes.tableBody}>Marker</Typography>
                    <Typography className={`${classes.marker}`}>{generateGpNumber(marker)}</Typography>
                </Box>
            </TableCell>

            <TableCell className={`${classes.tableCellBase} ${classes.rowBorder}`}>
                <Typography
                    key={markerIndex}
                    className={`${textColor} ${classes.genotypeText}`}
                >
                    {marker.genotype && marker.genotype !== "--" ? marker.genotype : ""}
                </Typography>
            </TableCell>

            {removePadding ? <TableCell className={`${classes.wideColumn} ${classes.tableCellBase}`}>
                <Box key={markerIndex}>
                    {marker.genotypeDescription && (
                        <Typography className={`${classes.genotypeDescription} ${textColor} ${classes.font}`}>
                            {marker.genotypeDescription}
                        </Typography>
                    )}
                    <Typography
                        className={`${classes.infoText} ${marker.genotypeDescription ? classes.mt_4 : ""
                            } ${classes.tableBody}`}
                    >
                        <Tooltip arrow classes={{ tooltip: classes.tooltip }} title="This percentage shows how many people in the general population have the same genotype as you. Please note that your results are based entirely on your own DNA and are not influenced by anyone else's results or information.">
                            <Info className={classes.infoIcon} />
                        </Tooltip>
                        {Math.round(marker.prevalence)}% of the population shares your
                        genotype
                    </Typography>
                </Box>
            </TableCell> :

                <TableCell className={`${classes.wideColumn} ${classes.tableCellBase} ${removePadding ? classes.rowBorder : classes.pr_4}`}>
                    <Box key={markerIndex}>
                        {marker.genotypeDescription && (
                            <Typography className={`${classes.genotypeDescription} ${textColor}`}>
                                {marker.genotypeDescription}
                            </Typography>
                        )}
                    </Box>
                </TableCell>
            }
        </>
    }

    const TableContent = ({ isTestedMarker, key }) => {
        const sharedClasses = sharedStyles();
        return (
            <>
                <TableContainer key={key} component={Paper} className={classes.tableContainer}>
                    <TableHeader />
                    <Table aria-label="Genotype Table">
                        <TableBody className={classes.tableBody}>
                            {geneDetails.genes
                                .sort((a, b) => {
                                    const aHasVariant = a.markers?.some(marker => marker.variant === true);
                                    const bHasVariant = b.markers?.some(marker => marker.variant === true);

                                    // Primary sort based on variant condition
                                    if (aHasVariant && !bHasVariant) return -1;
                                    if (!aHasVariant && bHasVariant) return 1;

                                    // Secondary sort by geneName
                                    if (a.geneName == null && b.geneName != null) return 1;
                                    if (b.geneName == null && a.geneName != null) return -1;
                                    if (a.geneName === '--' && b.geneName !== '--') return 1;
                                    if (b.geneName === '--' && a.geneName !== '--') return -1;
                                    return a.geneName?.localeCompare(b.geneName);
                                })
                                .map((gene, geneIndex) => {
                                    // Flag to track if any marker was rendered
                                    let hasRenderedMarker = false;

                                    const renderedMarkers = gene.markers?.map((marker, markerIndex) => {
                                        const isVariant = marker.variant;

                                        // Check if the marker should be skipped
                                        if ((!isTestedMarker && !isVariant) || marker.genotype === "--") {
                                            return null;
                                        }

                                        hasRenderedMarker = true; // At least one marker is rendered

                                        const textColor = isVariant ? classes.blue_06 : classes.gray_07;
                                        const geneVariantLength = gene.markers
                                            ? gene.markers.filter(
                                                markerElement =>
                                                    markerElement.genotype !== "--" &&
                                                    (isTestedMarker || markerElement.genotype)
                                            ).length - 1
                                            : 0;
                                        const rowStyling = markerIndex === geneVariantLength ? classes.rowBorder : "";

                                        return (
                                            <TableRow
                                                key={markerIndex}
                                                className={`${classes.tableRowBase} ${markerIndex !== 0 ? classes.pt_0 : ""}`}
                                            >
                                                <RenderRows
                                                    marker={marker}
                                                    gene={gene}
                                                    textColor={textColor}
                                                    classes={classes}
                                                    markerIndex={markerIndex}
                                                    removePadding={false}
                                                />
                                            </TableRow>
                                        );
                                    });

                                    // Conditionally render the button if any marker was rendered
                                    return (
                                        <>
                                            {renderedMarkers}
                                            {hasRenderedMarker && (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={3}
                                                        className={`${classes.infoRow} ${classes.pl_4} ${classes.rowBorder}`}
                                                    >
                                                        <Button
                                                            className={clsx(
                                                                sharedClasses.secondaryButton,
                                                                classes.button
                                                            )}
                                                            variant="text"
                                                            onClick={() => handleOpen(gene)}
                                                        >
                                                            See all information →
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    };


    const CustomDialog = ({
        open,
        title,
        onClose,
        isTestedMarker
    }) => {
        const classes = useStyles();
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

        return (
            <Dialog
                maxWidth={false}
                fullWidth
                open={open}
                onClose={onClose}
                classes={{ paper: classes.dialogRoot }}
                fullScreen={isMobile}
                PaperProps={{
                    style: {
                        marginTop: isMobile ? theme.spacing(11) : 0,
                    }
                }}
            >
                <>
                    <MuiDialogTitle className={`${classes.dialogHeader}`}>
                        <Typography className={`${classes.dialogTitle}`}>{title}</Typography>
                        <IconButton
                            aria-label='close'
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>

                    <DialogContent classes={{ root: classes.dialogContent }}>
                        <DialogContentText className={classes.contentText}>
                            <TableContainer
                                component={Paper}
                                className={classes.tableContainer}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={`${classes.tableHeader}`}>MARKER</TableCell>
                                        <TableCell className={classes.tableHeader}>GENOTYPE</TableCell>
                                        <TableCell className={`${classes.tableHeader} `}>
                                            WHAT YOUR GENOTYPE MEANS
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <Table aria-label="Genotype Table">
                                    <TableBody className={classes.tableBody}>
                                        {geneDetail?.markers && geneDetail?.markers.map((marker, markerIndex) => {
                                            const isVariant = marker.variant;
                                            if (((!isTestedMarker && !isVariant) || marker.genotype === "--")) {
                                                return null;
                                            }
                                            const textColor = isVariant ? classes.blue_06 : classes.gray_07;
                                            const geneVariantLenght = geneDetail.markers
                                                ? geneDetail.markers.filter((markerElement) =>
                                                    markerElement.genotype !== "--" && (isTestedMarker || markerElement.genotype)
                                                ).length - 1
                                                : 0;
                                            const rowStyling = markerIndex === geneVariantLenght ? classes.rowBorder : ""

                                            return (
                                                <>
                                                    <TableRow
                                                        key={`gene${markerIndex}`}
                                                        className={`${classes.tableRowBase} ${markerIndex !== 0 ? classes.pt_0 : ""}`}
                                                    >
                                                        <RenderRows
                                                            marker={marker}
                                                            gene={geneDetail}
                                                            textColor={textColor}
                                                            classes={classes}
                                                            markerIndex={markerIndex}
                                                            removePadding={true}
                                                        />
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={3} className={`${classes.geneCell} ${classes.removeBorder}`}>GENE INFORMATION</TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={`row${markerIndex}`}
                                                        className={`${classes.tableRowBase}`}
                                                    >
                                                        <TableCell colSpan={3}>
                                                            {markerIndex === 0 && <Typography className={classes.tableBody}>{geneDetail.geneDescription}</Typography>}
                                                        </TableCell>

                                                    </TableRow>
                                                    <TableRow
                                                        key={`reference${markerIndex}`}
                                                        className={`${classes.tableRowBase}`}
                                                    >

                                                        <TableCell colSpan={3} className={`${classes.removeBorder}`}>
                                                            <ReferenceCell markers={geneDetail.markers}
                                                                scrollToReferences={scrollToReferences}
                                                                references={references}
                                                                isTestedMarker={isTestedMarker}
                                                            />
                                                        </TableCell>

                                                    </TableRow>
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContentText>
                    </DialogContent>
                </>
            </Dialog>
        )
    }

    return <>
        <Accordion
            title='Variants Detected'
            expanded={selectedGeneticTab === 0}
            onClick={() => setSelectedGeneticTab(selectedGeneticTab === 0 ? null : 0)}
            content={<TableContent
                key={"variant_detected"}
                isTestedMarker={false}
            />}
            disabled={geneDetails.genes.filter((gene) =>
                gene.markers?.some((marker) => marker.variant)
            ).length < 1}
        />
        <Accordion
            title='All Tested Markers'
            expanded={selectedGeneticTab === 1}
            onClick={() => setSelectedGeneticTab(selectedGeneticTab === 1 ? null : 1)}
            content={<TableContent
                key={"tested_markers"}
                isTestedMarker={true}
            />}
            disabled={false}
        />

        <CustomDialog
            open={!!geneDetail}
            onClose={handleClose}
            title='See all information'
            isTestedMarker={selectedGeneticTab === 1}
        />
    </>
};

export default GeneticImpactCards;
