import React, { useState, useRef } from "react";
import { Tabs, Tab, Box, Container, Button, Typography } from "@material-ui/core";
import VariantDetected from "./VariantDetected";
import OverviewTab from "./OverviewTab";
import useStyles from "./NutrientDetailTabs.styles";
import References from "./References";
import { helpers } from 'utils';
import { sortBy, uniqBy } from 'lodash'
import GeneticImpactCards from './GeneticImpactCards'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const NutrientDetailTabs = ({
  geneDetails,
  selectedDomain,
  essentialNutritionDetails,
  id,
  genderId
}) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const totalGenesWithVariants = geneDetails.genes.reduce((total, gene) => {
    const markersWithVariants = gene.markers?.filter((marker) => marker.variant).length || 0;
    return total + markersWithVariants;
  }, 0);

  const [selectedGeneticTab, setSelectedGeneticTab] = useState(totalGenesWithVariants < 1 ? 1 : 0);

  const message = totalGenesWithVariants > 0
    ? `You have ${helpers.numberToWords(totalGenesWithVariants)} of the genetic variants we tested for.`
    : "You do not have the genetic variants we tested for.";

  const references = sortBy(
    uniqBy(
      geneDetails.genes.flatMap((gene) =>
        gene.markers
          .filter((marker) => marker.reference !== null)
          .map((marker) => ({
            reference: marker.reference,
            studyLink: marker.studyLink,
          }))
      ),
      'reference'
    ),
    'reference'
  );

  return (
    <React.Fragment>
      <Container style={{ flexGrow: 1 }} maxWidth="lg">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          classes={{ indicator: classes.indicator }}
          className={classes.tabBorder}
        >
          <Tab label="Overview" classes={{ root: classes.tabRoot }} />
          <Tab label="Genetic Impact" classes={{ root: classes.tabRoot }} />
        </Tabs>
      </Container>

      {selectedTab === 0 ? (
        <OverviewTab
          selectedDomain={selectedDomain}
          essentialNutritionDetails={essentialNutritionDetails}
          geneDetails={geneDetails}
          id={id}
          handleTabChange={(event) => handleTabChange(event, 1)}
          genderId={genderId}
        />
      ) : (
        <React.Fragment>
          <Container style={{ flexGrow: 1 }} maxWidth="lg">
            <Typography className={classes.geneMessage}>
              {message}
            </Typography>
            {!smDown && <>
              <Box className={classes.geneticSection}>
                <Box className={classes.buttonGroup}>
                  <Button
                    className={`${classes.geneticButton} ${selectedGeneticTab === 0 ? classes.selectedButton : ""
                      }`}
                    onClick={() => setSelectedGeneticTab(0)}
                    disabled={totalGenesWithVariants < 1}
                  >
                    Variants Detected
                  </Button>
                  <Button
                    className={`${classes.geneticButton} ${selectedGeneticTab === 1 ? classes.selectedButton : ""
                      }`}
                    onClick={() => setSelectedGeneticTab(1)}
                  >
                    All Tested Markers
                  </Button>
                </Box>
              </Box>
              <VariantDetected
                key={selectedGeneticTab}
                geneDetails={geneDetails}
                isTestedMarker={selectedGeneticTab === 0 ? false : true}
                references={references}
              />
            </>
            }
          </Container>
          {smDown && <GeneticImpactCards
            key={"genetic_impact"}
            geneDetails={geneDetails}
            references={references}
          />}

          <References geneDetails={geneDetails} references={references} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default NutrientDetailTabs;
