/* eslint-disable import/prefer-default-export */
import get from 'lodash/get';
import { getUuidCode } from 'utils/storage';
import { getBoostersFromSubscription, getSubscriptionNamesFromSubscriptionProducts, localStorageCartKey, getSKUFromSubscriptionProducts, getBoostersFromSubscriptionSKU } from 'services/shopify/utils';
import types from '../actionTypes';
import additionalProductsService from '../../services/additionalProducts';
import shopifyService from '../../services/shopify';

export const fetchAdditionalProducts = () => async (dispatch) => {
  dispatch({ type: types.GET_ADDITIONAL_PRODUCTS_STARTED });
  try {
    const data = await additionalProductsService.getProducts();
    dispatch({ type: types.GET_ADDITIONAL_PRODUCTS_SUCCESS, data });
  } catch (error) {
    dispatch({ type: types.GET_ADDITIONAL_PRODUCTS_ERROR, error });
  }
};

export const fetchPurchasedProducts = () => async (dispatch) => {
  dispatch({ type: types.GET_PURCHASED_PRODUCTS_STARTED });
  try {
    const data = await additionalProductsService.getPurchasedProducts();
    dispatch({ type: types.GET_PURCHASED_PRODUCTS_SUCCESS, data });
  } catch (error) {
    dispatch({ type: types.GET_PURCHASED_PRODUCTS_ERROR, error });
  }
};

export const fetchSubscriptionProducts = () => async (dispatch) => {
  dispatch({ type: types.GET_SUBSCRIPTION_PRODUCTS_STARTED });
  try {
    const { products } = await additionalProductsService.getSubscriptionProducts();
    dispatch({ type: types.GET_SUBSCRIPTION_PRODUCTS_SUCCESS, data: products });
  } catch (error) {
    dispatch({ type: types.GET_SUBSCRIPTION_PRODUCTS_ERROR, error });
  }
};

export const fetchSubscriptionProductsV2 = () => async (dispatch) => {
  dispatch({ type: types.GET_SUBSCRIPTION_PRODUCTS_STARTED_V2 });
  try {
    const { products } = await additionalProductsService.getSubscriptionProductsV2();
    dispatch({ type: types.GET_SUBSCRIPTION_PRODUCTS_SUCCESS_V2, data: products });
  } catch (error) {
    dispatch({ type: types.GET_SUBSCRIPTION_PRODUCTS_ERROR_V2, error });
  }
};

export const fetchSubscriptionGenoBlendProducts = () => async (dispatch) => {
  dispatch({ type: types.GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_STARTED });
  try {
    const { products } = await additionalProductsService.genoblendService.genoBlendSubscriptionProduct();
    dispatch({ type: types.GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_SUCCESS, data: products });
  } catch (error) {
    dispatch({ type: types.GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_ERROR, error });
  }
};

export const fetchUserBlendTypes = () => async (dispatch) => {
  dispatch({ type: types.GET_USER_BLEND_STARTED });
  try {
    const data = await additionalProductsService.genoblendService.userBlendType();
    dispatch({ type: types.GET_USER_BLEND_SUCCESS, data: data.userBlend });
  } catch (error) {
    dispatch({ type: types.GET_USER_BLEND_ERROR, error });
  }
};

export const fetchBoosterFormulations = () => async (dispatch) => {
  dispatch({ type: types.GET_BOOSTER_FORMULATIONS_STARTED });
  try {
    const data = await additionalProductsService.getBoosterFormulations();
    dispatch({ type: types.GET_BOOSTER_FORMULATIONS_SUCCESS, data });
  } catch (error) {
    dispatch({ type: types.GET_BOOSTER_FORMULATIONS_ERROR, error });
  }
};

const createCart = async (email, uuidCode, dispatch) => {
  try {
    const cart = await shopifyService.createCart(email, uuidCode);
    if (cart) {
      dispatch(updateCart(cart));
    }
    dispatch({ type: types.INIT_CART_SUCCESS, cart });
  } catch (error) {
    dispatch({ type: types.INIT_CART_ERROR, error });
  }
};

export const initCart = (cartId) => async (dispatch, getState) => {
  const { profile } = getState();
  const email = get(profile, 'userProfile.data.email');
  const uuidCode = getUuidCode();
  dispatch({ type: types.INIT_CART_STARTED });
  try {
    if (email) { // if email is not set, do not attempt to init cart
      if (!cartId) {
        await createCart(email, uuidCode, dispatch);
      } else {
        const existingCart = await shopifyService.loadExistingCart(cartId);
        if (existingCart) {
          dispatch(updateCart(existingCart));
        }
        dispatch({ type: types.INIT_CART_SUCCESS, existingCart });
      }
    }
  } catch (initialError) {
    await createCart(email, uuidCode, dispatch);
  }
};

export const addItemToCart = (productId, sellingPlanId, quantity, additionalAttributes) => async (dispatch, getState) => {
  console.log('addItemToCart', { productId, sellingPlanId, quantity, additionalAttributes });
  const { additionalProducts } = getState();
  dispatch({ type: types.OPEN_CART_SPINNER });
  try {
    // If the subscription products aren't yet loaded into state, load them here so things don't break downstream
    if (!additionalProducts.cart || !additionalProducts.cart.cart || !additionalProducts.cart.cart.id) {
      const cartId = localStorage.getItem(localStorageCartKey);
      await dispatch(initCart(cartId));
    }
    const { additionalProducts: { cart: { cart: { id: cartId } } } } = getState();
    const formattedProductId = productId.includes('gid://') ? productId : `gid://shopify/ProductVariant/${productId.trim()}`;
    const cart = await shopifyService.addToCart(cartId, formattedProductId, sellingPlanId, additionalAttributes, quantity);
    dispatch({ type: types.UPDATE_CART, cart });
    dispatch({ type: types.CLOSE_CART_SPINNER });
  } catch (error) {
    console.log('error', error)
    dispatch({ type: types.OPEN_CART_ERROR, message: 'An error occurred while trying to add a product to your cart. Please try again.' });
  }
};

export const removeItemFromCart = (lineItemId) => async (dispatch, getState) => {
  dispatch({ type: types.OPEN_CART_SPINNER });
  try {
    // todo: flatten cart structure
    const { additionalProducts: { cart: { cart: { id: cartId } } } } = getState();
    const cart = await shopifyService.removeFromCart(cartId, lineItemId);
    dispatch({ type: types.UPDATE_CART, cart });
    dispatch({ type: types.CLOSE_CART_SPINNER });
  } catch (error) {
    dispatch({ type: types.OPEN_CART_ERROR, message: 'An error occurred while trying to remove a product from your cart. Please try again.' });
  }
};

// Pass in a line item id for the item to be replaced, and a newProductId and newSellingPlanId for the replacement.
export const replaceItemInCart = (removeLineItemId, newProductId, newSellingPlanId, additionalAttributes) => async (dispatch, getState) => {
  dispatch({ type: types.OPEN_CART_SPINNER });
  try {
    // todo: flatten cart structure
    const { additionalProducts: { cart: { cart: { id: cartId } } } } = getState();
    await shopifyService.removeFromCart(cartId, removeLineItemId);
    const cart = await shopifyService.addToCart(cartId, newProductId, newSellingPlanId, additionalAttributes);
    dispatch({ type: types.UPDATE_CART, cart });
    dispatch({ type: types.CLOSE_CART_SPINNER });
  } catch (error) {
    dispatch({ type: types.OPEN_CART_ERROR, message: 'An error occurred while trying to update an item in your cart. Please try again.' });
  }
};

export const showCart = () => async (dispatch) => {
  dispatch({ type: types.OPEN_CART_SPINNER });
  try {
    dispatch({ type: types.OPEN_CART });
    dispatch({ type: types.CLOSE_CART_SPINNER });
  } catch (error) {
    dispatch({ type: types.OPEN_CART_ERROR, message: 'An error occurred while trying to add a product to your cart. Please try again.' });
  }
};

export const updateCart = (cart) => ({ type: types.UPDATE_CART, cart });

export const closeCart = () => ({ type: types.CLOSE_CART });

export const openCart = () => ({ type: types.OPEN_CART });

export const closeCartError = () => ({ type: types.CLOSE_CART_ERROR });

const setBoostersInCartStarted = () => ({ type: types.SET_BOOSTERS_IN_CART_STARTED });
const setBoostersInCartSuccess = data => ({ type: types.SET_BOOSTERS_IN_CART_SUCCESS, data });
const setBoostersInCartError = error => ({ type: types.SET_BOOSTERS_IN_CART_ERROR, error });

export const updateBoostersInCartV1 = (subscriptionProducts) => async (dispatch, getState) => {
  const { additionalProducts } = getState();
  dispatch(setBoostersInCartStarted());
  try {
    if (!additionalProducts.cart || !additionalProducts.cart.cart) {
      await dispatch(setBoostersInCartSuccess(null));
    } else {
      const lineItems = get(additionalProducts, 'cart.cart.lineItems', []);
      if (!subscriptionProducts || subscriptionProducts.length === 0) throw new Error('Failed to load booster data.');
      const subscriptionNames = getSubscriptionNamesFromSubscriptionProducts(subscriptionProducts);
      const subscriptionLineItems = lineItems.filter(item => subscriptionNames.includes(item.title));
      const boostersArray = subscriptionLineItems.map(item => getBoostersFromSubscription(item.title, subscriptionProducts));
      const boosters = boostersArray.find(boosterList => boosterList.length > 0);
      dispatch(setBoostersInCartSuccess(boosters || null));
    }
  } catch (error) {
    dispatch(setBoostersInCartError(error));
  }
};

export const updateBoostersInCart = (subscriptionProducts) => async (dispatch, getState) => {
  const { additionalProducts } = getState();
  dispatch(setBoostersInCartStarted());
  try {
    if (!additionalProducts.cart || !additionalProducts.cart.cart) {
      await dispatch(setBoostersInCartSuccess(null));
    } else {
      const lineItems = get(additionalProducts, 'cart.cart.lineItems', []);
      if (!subscriptionProducts || subscriptionProducts.length === 0) throw new Error('Failed to load booster data.');
      const subscriptionSKUs = getSKUFromSubscriptionProducts(subscriptionProducts);
      const subscriptionLineItems = lineItems.filter(item => subscriptionSKUs.includes(item.sku));
      const boostersArray = subscriptionLineItems.map(item => getBoostersFromSubscriptionSKU(item.sku, subscriptionProducts));
      const boosters = boostersArray.find(boosterList => boosterList.length > 0);
      dispatch(setBoostersInCartSuccess(boosters || null));
    }
  } catch (error) {
    dispatch(setBoostersInCartError(error));
  }
};
