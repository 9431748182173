import React, { useEffect, useMemo, useState } from 'react';
import {
  SupplementFeaturedSpot,
} from 'components/componentsV2';
import { Button, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalProduct } from 'components/SupplementSubscription';
import { fetchAdditionalProducts } from '../store/actions/additionalProducts';
import useStyles from './ShopPage.styles';
import helpers from '../utils/helpers';
import { ArrowForward } from '@material-ui/icons';
import { images, sharedStyles } from 'resources';
import { colors } from 'common/assets/sharedUI';

const ShopPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [hasBeenCheckedThisRender, setHasBeenCheckedThisRender] = useState(false);

  const { data, fetching, error } = useSelector(state => state.additionalProducts.products);

  // Creates an array excluding supplement and consult products for displaying in the products section.
  const availableProducts = useMemo(() => (Array.isArray(data) && data
    .sort((a, b) => a.sortOrder - b.sortOrder)), [data]);

  useEffect(() => {
    if (!fetching && !data && !error) {
      dispatch(fetchAdditionalProducts());
    } else if (!hasBeenCheckedThisRender) {
      dispatch(fetchAdditionalProducts());
      setHasBeenCheckedThisRender(true);
    }
  }, [data, dispatch, error, fetching, hasBeenCheckedThisRender]);

  if (!data) return null;

  return (
    <Grid container xs={12}>
      <Grid container xs={12} className={classes.featuredImage}>
        <SupplementFeaturedSpot eventCaller="Shop" />
      </Grid>

      <Grid 
        container 
        className={classes.bannerbackground} 
        spacing={isSmall ? 2 : 5}
      >
        <Grid 
          item 
          xs={12} 
          sm={3}
          container
          justifyContent="center" 
        >
          <img
            className={classes.truemedLogo}
            src={images.truemedLogo}
            alt="trueMed"
          />
        </Grid>

        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <Typography>
            <strong>New!</strong> Our partnership with Truemed now makes your GenoPalate personalized supplement 
            purchases eligible for HSA or FSA with no additional cost to you.
          </Typography>
        </Grid>

        <Grid 
          item 
          xs={12} 
          sm={3}
          container
          justifyContent={isSmall ? "flex-start" : "center"} 
        >
          <Button
            className={classes.primaryButton}
            variant="contained"
            size="large"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.genopalate.com/pages/supplements-fsa-hsa-eligible?coback=1"
          >
            Learn More
            <ArrowForward fontSize="small" style={{ color: colors.white }} />
          </Button>
        </Grid>
      </Grid>


      <Grid container style={{ display: availableProducts.length === 0 ? 'none' : 'initial' }}>
        <Grid item xs={12}>
          <div className={classes.headerDiv}>
            <span className={classes.shopProductsHeader}>All Products</span>
          </div>
        </Grid>
        <Grid container>
          {Array.isArray(availableProducts) && availableProducts
            .map(({
              name, id, price, image, metafieldDescription,
            }) => (
              <Grid className={classes.additionalProductWrapper} item xs={12} md={3} key={id}>
                <AdditionalProduct
                  name={name}
                  id={id}
                  price={price}
                  image={image}
                  description={metafieldDescription}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default (ShopPage);
