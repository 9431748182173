// TODO: Clean up colors across the site.
const colors = {
  black: '#000',
  white: '#FFF',
  // Blue
  blue: '#095D7C',
  lightBlue: '#277E9F',
  lighterBlue: '#7CB9D0',
  highlightsBlue: '#277E9F',
  headerBlue: '#095D7C',
  buttonBlue: '#095D7C',
  buttonHoverBlue: '#277E9F',
  buttonHoverLightBlue: '#AADBED',
  buttonBorderBlue: '#277E9F',
  buttonTextBlue: '#277E9F',
  linkBlue: '#095D7C',
  lightestBlue: '#AADBED',
  darkBlue: '#09485F',
  bannerColor: '#0B5D7C',
  // Green
  green: '#8AC441',
  menuGreen: '#8AC441',
  chartGreen: '#8AC441',
  lighterGreen: '#D4EDB5',
  filterLightGreen: '#D4EDB5',
  carouselGreen: '#D4EDB5',
  hoverGreen: '#65A418',
  darkestGreen: '#508313',
  darkestDarkGreen: '#385c0d',
  // Grey
  darkGrey: '#73767C',
  darkerGrey: '#45484E',
  darkestGrey: '#282C36',
  chartGrey: '#73767C',
  grey: '#C9CACD',
  grey_01: '#F4F4F6',
  txtBtnGrey: '#C9CACD',
  headerGrey: '#73767C',
  lightGrey: '#F4F4F5',
  foodDetailsGrey: '#45484E',
  subtextGrey: '#45484E',
  buttonDisabled: '#E9E9EA',
  buttonDisabledBorder: '#45484E',
  buttonDisabledText: '#45484E',
  separatorColor: '#707070',
  // Yellow
  chartYellow: '#F0A92D',
  // Orange
  chartOrange: '#C64B2F',
  starOrange: '#C64B2F',
  // Red
  chartRed: '#B70000',
  // Gradient
  linearGradient: 'url(#colorUv)',
  // Other
  transparent: 'rgba(0,0,0,0)',

  gray_01: '#F4F4F5',
  gray_02: '#E9E9EA',
  gray_03: '#C9CACD',
  gray_04: '#73767C',
  gray_05: '#45484E',
  gray_06: '#282c36',
  gray_07: '#74767C',
  gray_08: '#272C37',
  gray_09: '#46484E',

  green_00: '#dae4d3',
  green_01: '#d4edb5',
  green_02: '#b2de7b',
  green_03: '#8ac441',
  green_04: '#65a418',
  green_05: '#508313',
  green_06: "#D8E2CD",
  green_07: "#385C0D",
  green_08: "#889D6E",
  green_09: "#205818",
  green_10: "#98ad80",
  green_11: '#d7ded0',

  blue_01: '#AADBED',
  blue_02: '#7CB9D0',
  blue_03: '#277e9f',
  blue_04: '#095d7c',
  blue_05: '#09485f',
  blue_06: "#0080A3",
  blue__06: '#0080A3',
  blue__07: '#287E9F',

  orange_01: '#E5F4FA',
  orange_02: '#EFA92C',

  lightRed: '#F2D9D5',
  red: '#B70000',

  get primaryBlue() {
    return this.blue_04;
  },

  get primaryGreen() {
    return this.green_03;
  },

  get bodyText() {
    return this.gray_06;
  },
};

const fonts = {
  kitPdfText: 'Helvetica',
  kitPdfTextBold: 'Helvetica-Bold',
  Baskerville: 'Baskerville',
  BaskervilleBold: 'Baskerville-Bold',
  Swiss721MD: 'Swiss721-MD-BT',
  Swiss721LT: 'Swiss721-LT-BT',
  Swiss721HY: 'Swiss721-BT-Heavy',
  Swiss721Roman: 'Swiss721-Roman',

  heading1: 'Baskerville',
  heading2: 'Swiss721-MD-BT',
  heading3: 'Swiss721-MD-BT',
  heading4: 'Swiss721-Roman',
  subHeading: 'Swiss721-Roman',

  paragraph1: 'Swiss721-LT-BT',
  paragraph2: 'Swiss721-Roman',
};

module.exports = {
  colors,
  fonts,
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "ITC-New-Baskerville", "Swiss721MediumBT',
};
