import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { colors } from 'common/assets/sharedUI';

const Legend = ({ color, text, range, direction = 'row' }) => {
  const circleStyle = (color) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: color,
    display: 'inline-block',
    marginRight: '8px',
  });

  return (
    <Box display='flex' flexDirection={direction}>
      {range && (
        <Typography
          variant='caption'
          component='span'
          style={{ fontWeight: 'bold' }}
        >
          {range}
        </Typography>
      )}
      <Box display='flex' alignItems='center'>
        {color && <Box component='span' sx={circleStyle(color)} />}
        <Typography variant='body2' component='span' mr={2}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

const FoodScoreLegend = () => {
  return (
    <Box display='flex' alignItems='center' gridGap={20}>
      <Legend text='Include' color={colors.darkestGreen} />
      <Legend text='Enjoy In Moderation' color={colors.orange_02} />
      <Legend text='Limit' color={colors.chartOrange} />
    </Box>
  );
};

const FoodExportViewLegend = () => (
  <Box display='flex' alignItems='center' gridGap={20}>
    <Legend range='0-34' text='Limit' direction='column' />
    <Legend range='35-49' text='Enjoy In Moderation' direction='column' />
    <Legend range='50+' text='Enjoy' direction='column' />
  </Box>
);

export { FoodScoreLegend, FoodExportViewLegend };