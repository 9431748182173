import React, { useCallback, useEffect } from 'react';
import {
  Button,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  isMobile,
  isTablet,
  CustomView,
  isDesktop,
} from 'react-device-detect';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';

import { FoodExportViewLegend } from './FoodScoreLegend';
import { images, sharedStyles } from '../resources';

import { setDisplayHeaderFooter } from '../store/actions/status';
import styles from './FoodExportView.styles';
import BrowserFoodExportInstructions from './BrowserFoodExportInstructions';
import MobileFoodExportInstructions from './MobileFoodExportInstructions';
import { registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels } from '../utils/googleanalytics';

const withSuperscriptOn = (value, match, superscript, score) => {
  return value.split(match).reduce((nodes, token, index, tokens) => {
    const isMatched = tokens.length > 1 && index < tokens.length - 1;
    // Skip adding a span if the token is empty and it's the last element
    if (token.trim() === "" && index === tokens.length - 1) return nodes;

    return nodes.concat(
      isMatched
        ? [
            <span key={`${token}-${index}`}>{`${score} - ${token}`}</span>,
            <sup key={`${superscript}-${index}`}>{superscript}</sup>,
          ]
        : [<span key={`${token}-${index}`}>{`${score} - ${token}`}</span>]
    );
  }, []);
};

const getContentLabelNode = (foodGroupId, name, score) => {
  switch (foodGroupId) {
    // '*Contains Gluten'
    case 7:
    case 13:
      return withSuperscriptOn(name, '*', 1, score);
    // '*Low Lactose'
    case 9:
      return withSuperscriptOn(name, '*', 3, score);
    // '**Lactose-Free'
    case 12:
    case 14:
      // Lactose-free and low-lactose both exist in these food groups.  Need to handle them separately.
      // Always check the '**' before '*' - for obvious reasons.
      if (name.endsWith('**')) {
        return withSuperscriptOn(name, '**', 4, score);
      }
      if (name.endsWith('*')) {
        return withSuperscriptOn(name, '*', 3, score);
      }
      return [
        <span key={name}>{`${score} - ${name}`}</span>,
      ];
    // '*Gluten-Free'
    case 15:
      return withSuperscriptOn(name, '*', 2, score);
    default:
      return [
        <span key={name}>{`${score} - ${name}`}</span>,
      ];
  }
};

const FoodGroupList = ({ foodGroup: { foods, id: foodGroupId } }) => (
  <ul>
    {foods.map(({ id, name, normalizeFoodScore }) => {
      const score = Math.round(normalizeFoodScore);
      return (
      <li key={id}>{getContentLabelNode(foodGroupId, name, score)}</li>
    )
    })}
  </ul>
);

const FoodGroupCategory = withStyles(styles)(({ foodGroup, classes, containerClass }) => (
  <div className={containerClass}>
    <p className={classes.foodGroupTitle}>{foodGroup.name}</p>
    <FoodGroupList foodGroup={foodGroup} />
  </div>
));

const FoodExportView = ({ setFoodExportView, classes, foodGroups }) => {
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();

  const handleFoodExportPageClosed = useCallback(() => {
    setFoodExportView(false);
    dispatch(setDisplayHeaderFooter(true));
    registerGAClickEventEnhanced(EventCategories.OptimalFoods, EventActions.Close, EventLabels.PageClosed);
  }, [setFoodExportView, dispatch]);

  const handleCopyToClipboard = useCallback((e, id) => {
    const r = document.createRange();
    r.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  }, []);

  const handlePrintClick = window.print;
  const sideEffectFreeFoodGroups = cloneDeep(foodGroups);
  const filteredFoodGroups = sideEffectFreeFoodGroups.map(group => {
    // eslint-disable-next-line no-param-reassign
    group.foods = group.foods.filter(food => !food.individuallyHidden && food.missingDietaryPreferences.length === 0);
    return group;
  });
  const nonEmptyFoodGroups = filteredFoodGroups.filter(group => group.foods && group.foods.length);

  useEffect(() => {
    window.onpopstate = () => {
      // Browser back button pressed, which is equivalent to close Btn press
      handleFoodExportPageClosed();
    };
  });

  return (
    <React.Fragment>
      <CustomView condition={!isMobile && isDesktop}>
        <div className={classes.printLogo}>
          <img
            src={images.logoNoTagline}
            alt="GenoPalate Logo"
            itemProp="image"
            className={classes.logo}
          />
        </div>
        <div className={classes.noprint}>
          <Button className={clsx(sharedClasses.secondaryButton, classes.closeBtn)} onClick={handleFoodExportPageClosed}>X CLOSE</Button>
        </div>
        <div className={classes.container}>
          <BrowserFoodExportInstructions copyToClipboard={handleCopyToClipboard} id="foodsList" printClicked={handlePrintClick} />
          <div className={classes.foodsList} id="foodsList">
            <p className={classes.foodsListTitle}>
              Your Food Index
            </p>
            <p>We've analyzed hundreds of foods to create a personalized list just for you, with each food scored based on your unique genetic needs.</p>
            
            <FoodExportViewLegend />

            <div className={classes.foodsListKey}>
              <span>
                <sup>1</sup>
                Contains Gluten
              </span>
              <span>
                <sup>2</sup>
                Gluten free
              </span>
              <span>
                <sup>3</sup>
                Low Lactose
              </span>
              <span>
                <sup>4</sup>
                Lactose-Free
              </span>
            </div>
            <div className={classes.list}>
              {nonEmptyFoodGroups.map((foodGroup) => <FoodGroupCategory key={foodGroup.id} foodGroup={foodGroup} containerClass={classes.foodGroup} />)}
            </div>
          </div>
        </div>
      </CustomView>
      <CustomView condition={isMobile || isTablet}>
        <div className={classes.noprint}>
          <Button className={clsx(sharedClasses.secondaryButton, classes.closeBtn)} onClick={handleFoodExportPageClosed}>X</Button>
        </div>
        <MobileFoodExportInstructions copyToClipboard={handleCopyToClipboard} id="foodsListMobile" />
        <div className={classes.printLogo}>
          <img
            src={images.logoNoTagline}
            alt="GenoPalate Logo"
            itemProp="image"
            className={classes.logo}
          />
        </div>
        <div id="foodsListMobile" className={classes.top}>
          <h3>Your Foods Index</h3>
          <p>
            We've analyzed hundreds of foods to create a personalized list just for you, with each food scored based on your unique genetic needs.
          </p>
          <FoodExportViewLegend />

          <div className={classes.foodsListKey}>
            <span>
              <sup>1</sup>
              Contains Gluten
            </span>
            <span>
              <sup>2</sup>
              Gluten free
            </span>
            <span>
              <sup>3</sup>
              Low Lactose
            </span>
            <span>
              <sup>4</sup>
              Lactose-Free
            </span>
          </div>
          <div className={classes.container}>
            <div className={classes.list}>
              {nonEmptyFoodGroups.map((foodGroup) => <FoodGroupCategory key={foodGroup.id} foodGroup={foodGroup} containerClass={classes.mobileFoodGroup} />)}
            </div>
          </div>
        </div>
      </CustomView>
    </React.Fragment>
  );
};

FoodExportView.propTypes = {
  setFoodExportView: PropTypes.func.isRequired,
  foodGroups: PropTypes.array.isRequired,
  classes: PropTypes.shape({
    logo: PropTypes.string,
    mobilelogo: PropTypes.string,
    printLogo: PropTypes.string,
    container: PropTypes.string,
    closeBtn: PropTypes.string,
    foodsList: PropTypes.string,
    foodsListTitle: PropTypes.string,
    list: PropTypes.string,
    foodGroup: PropTypes.string,
    mobileFoodGroup: PropTypes.string,
    foodGroupTitle: PropTypes.string,
    foodsListKey: PropTypes.string,
    noprint: PropTypes.string,
    top: PropTypes.string,
  }).isRequired,
};

export default withRouter(withStyles(styles)(FoodExportView));
