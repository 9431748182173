import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Container, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getTraitRecommendations } from "store/actions/domain";
import { Recommendation, NutrientDetailTabs } from "components/componentsV2";
import { getDomainName } from "utils/dataTools";
import { DomainNames } from "resources/constants";
import useStyles from "./NutrientDetailView.styles";
import actionTypes from "../../../store/actionTypes";

const NutrientDetailView = ({
  selectedDomain,
  essentialNutritionDetails,
  geneDetails,
  id,
  genderId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    fetching: traitRecommendationFetching,
    data: traitRecommendation,
    error: traitRecommendationError,
  } = useSelector((state) => state.domain.traitRecommendation);

  const selectedTraitDetails = useMemo(
    () => selectedDomain.traits.find((t) => t.traitId === parseInt(id, 10)),
    [selectedDomain, id]
  );

  const traitENDetails = useMemo(
    () => essentialNutritionDetails.find((end) => end.id === parseInt(id, 10)),
    [essentialNutritionDetails, id]
  );

  const sensitivitiesOrSubstances = useMemo(
    () =>
      !![
        DomainNames.SENSITIVITIES.toLowerCase(),
        DomainNames.SUBSTANCES.toLowerCase(),
      ].includes(selectedDomain.name.toLowerCase()),
    [selectedDomain.name]
  );

  useEffect(() => {
    if (selectedTraitDetails.geneticInsightId && !sensitivitiesOrSubstances) {
      if (
        (!traitRecommendation ||
          traitRecommendation.id !==
            parseInt(selectedTraitDetails.geneticInsightId, 10)) &&
        !traitRecommendationFetching &&
        !traitRecommendationError
      ) {
        dispatch(
          getTraitRecommendations([selectedTraitDetails.geneticInsightId])
        );
      }
    } else {
      dispatch({
        type: actionTypes.GET_TRAIT_RECOMMENDATION_SUCCESS,
        data: null,
      });
    }
  }, [
    traitRecommendation,
    traitRecommendationFetching,
    selectedTraitDetails.geneticInsightId,
    dispatch,
    traitRecommendationError,
    sensitivitiesOrSubstances,
  ]);

  if (!traitENDetails) return null;

  const traitGenes = geneDetails.find((gd) => gd.traitId === parseInt(id, 10));
  const title = getDomainName(selectedTraitDetails.traitName);

  return (
    <React.Fragment>
      <Grid container>
        <Container style={{ flexGrow: 1 }} maxWidth="lg">
          <Grid item xs={12} className={classes.subContainer}>
            <div id="nutrientName">
              <Typography variant="h4" className={classes.descriptionTitle}>
                {title}
              </Typography>
            </div>
            <Recommendation
              traitRecommendation={traitRecommendation}
              geneticInsightDescription={
                selectedTraitDetails.geneticInsightDescription
              }
            />
          </Grid>
        </Container>

        {!!traitGenes && (
          <NutrientDetailTabs
            geneDetails={traitGenes}
            selectedDomain={selectedDomain}
            essentialNutritionDetails={essentialNutritionDetails}
            id={id}
            genderId={genderId}
          ></NutrientDetailTabs>
        )}
      </Grid>
    </React.Fragment>
  );
};

NutrientDetailView.propTypes = {
  selectedDomain: PropTypes.shape({
    name: PropTypes.string,
    traits: PropTypes.arrayOf(
      PropTypes.shape({
        traitId: PropTypes.number,
        geneticInsightId: PropTypes.number,
        traitName: PropTypes.string,
        formattedAverage: PropTypes.string,
        formattedRecommendation: PropTypes.string,
        geneticInsightName: PropTypes.string,
        geneticInsightDescription: PropTypes.string,
        totalVariants: PropTypes.number,
        traitDescription: PropTypes.string,
        traitAdditionalDescription: PropTypes.string,
      })
    ),
  }).isRequired,
  classes: PropTypes.shape({
    mainContainer: PropTypes.string.isRequired,
    descriptionTitle: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  essentialNutritionDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  geneDetails: PropTypes.arrayOf(
    PropTypes.shape({
      traitId: PropTypes.number,
      genes: PropTypes.arrayOf(
        PropTypes.shape({
          markers: PropTypes.arrayOf(
            PropTypes.shape({
              healthOutcome: PropTypes.shape({
                name: PropTypes.string,
                description: PropTypes.string,
                icon: PropTypes.string,
              }),
              variant: PropTypes.bool,
            })
          ),
        })
      ),
    })
  ).isRequired,
  id: PropTypes.number.isRequired,
};

export default NutrientDetailView;
