import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    subContainer: {
      marginTop: 60,
      marginBottom: 60,
    },
    icon: {
      width: 44,
      height: 50,
      marginRight: "26px",
    },
    descriptionTitle: {
      marginBottom: 20,
    },
  })
);

export default useStyles;
