import { colors } from 'common/assets/sharedUI';

const styles = theme => ({
  pageDescription: {
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
  },
  foodContainer: {
    // background: colors.gray_01,
    minHeight: 812,
    width: '100%',
    padding: 20,
    overflowX: 'scroll',
  },
  foodCategoriesWrapper: {
    borderTop: `solid 1px ${colors.gray_02}`,
  },
  foodContentLegend: {
    textAlign: 'right',
    marginRight: '2%'
  },
  foodAppBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      padding: 20,
    },
  },
  welcomeContainer: {
  },
  hiddenFoodsText: {
    fontWeight: 600,
  },
  outlineCog: {
    fontSize: 16,
  },
  foodDetails: {
    paddingTop: 10,
    [theme.breakpoints.up('md')]: {
      paddingTop: 20,
    },
  },
});

export default styles;
