
import React from 'react'
import {  Typography, Card, CardContent, CardActions, Button,  withStyles  } from '@material-ui/core';
import useStyles from '../../pages/NutrientPage.styles';
import { colors } from 'common/assets/sharedUI';
import {upperCase } from 'lodash';



const ColorButton = withStyles((theme) => ({
    root: {
      color: colors.white,
      backgroundColor: colors.green_07,
      boxShadow: 'none',
      textTransform: 'capitalize',
      '&:hover': {
        color: colors.white,
        backgroundColor: colors.green_05,
        boxShadow: 'none',
      },
    },
  }))(Button);

export const UpSellCard = ({ title, subtitle, image, buttonTitle, handleClick }) => {
    const classes = useStyles();
  
    return (
      <Card>
        <CardContent>
          <img
            src={image}
            alt={title}
            style={{ objectFit: 'cover', width:'100%' }}
          />
          <Typography
            className={classes.subtitle}
            style={{ color: colors.black }}
            color='textSecondary'
            gutterBottom
          >
            {upperCase(subtitle)}
          </Typography>
          <Typography variant='h5' component='h2' className={classes.title}>
            {title}
          </Typography>
        </CardContent>
  
        <CardActions style={{ padding: 16, paddingTop: 0 }}>
          <ColorButton size='small' variant="contained" onClick={() => handleClick(title)}>
            {buttonTitle} →
          </ColorButton>
        </CardActions>
      </Card>
    )
  }
  