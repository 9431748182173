import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import CustomTextField from 'components/CustomTextField'
import PropTypes from 'prop-types'
import useStyles from './HeightForm.styles'

export default function HeightForm(props) {
  const classes = useStyles()
  const {
    id,
    questionText,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = props

  const feetProps = {
    className: classes.feetInp,
    name: `feet_${id}`,
    value: values[`feet_${id}`],
    onChange: handleChange,
    onBlur: handleBlur,
    error: errors[`feet_${id}`] && touched[`feet_${id}`],
    helperText: (errors[`feet_${id}`] && touched[`feet_${id}`]) && errors[`feet_${id}`],
    margin: 'normal',
    variant: 'outlined',
    size: 'small',
    inputProps: { maxLength: 1 },
  }

  const inchesProps = {
    className: classes.inchesInp,
    name: `inches_${id}`,
    value: values[`inches_${id}`],
    onChange: handleChange,
    onBlur: handleBlur,
    error: errors[`inches_${id}`] && touched[`inches_${id}`],
    helperText: (errors[`inches_${id}`] && touched[`inches_${id}`]) && errors[`inches_${id}`],
    margin: 'normal',
    variant: 'outlined',
    size: 'small',
    inputProps: { maxLength: 2 },
  }

  return (
    <Grid container>
      <Typography variant="body1" className={classes.questionLabel}>{questionText}</Typography>
      <Grid item xs={12} className={classes.questionContainer}>
        <CustomTextField {...feetProps} />
        <div className={classes.labelContainer}>
          <Typography variant="body1" className={classes.centerSpan}>ft</Typography>
        </div>
        <CustomTextField {...inchesProps} />
        <div className={classes.labelContainer}>
          <Typography variant="body1" className={classes.centerSpan}>in</Typography>
        </div>
      </Grid>
    </Grid>
  )
}

HeightForm.propTypes = {
  id: PropTypes.number.isRequired,
  questionText: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.any),
  touched: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}

HeightForm.defaultProps = {
  questionText: '',
  values: {},
  touched: {},
  errors: {},
}
