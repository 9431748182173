export const appConstants = {
  genoVitamins: {
    header: 'Personalized Vitamin Subscription',
    description:
      'Get your custom daily multivitamin formulated based on your DNA, age, gender, and diet conveniently delivered straight to your door.',
  },
  genoBlends: {
    header: `GenoBlend \n Personalized Protein & Fiber Blend`,
    description:
      'Unlock personalized nutrition with your custom protein and fiber blend, tailored specifically to your DNA. Delivered straight to your door, each pouch contains 14 servings of plant-based ingredients—perfect for fueling your wellness journey. Subscribe to receive a free welcome kit.',
  },
  learnMore: 'Learn More',
  genoVit: 'genoVit',
  genoBlend: 'genoBlend',

  genoBlendSubscriptionDays: [
    {
      name: 'Delivered every 14 days',
      value: 14,
    },
    {
      name: 'Delivered every 30 days',
      value: 30,
    },
    {
      name: 'Delivered every 45 days',
      value: 45,
    },
    {
      name: 'Delivered every 60 days',
      value: 60,
    },
  ],
  genoBlendFlavors: [
    {
      name: 'No flavor',
      value: 'no-flavor',
    },
  ],
  pickYourPlan: 'Pick Your Plan',
  selectAFlavor: {
    header: 'Select A Flavor',
    description: 'Boost the taste of your blend with our single serve flavor packets (14 single packets included)'
  },
  addToCart: 'Add To Cart',
  addedToCart: 'Added To Cart',
};
