import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase, Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { images } from 'resources';
import { colors } from 'common/assets/sharedUI';
import { registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels } from 'utils/googleanalytics';
import StatusTracker from './StatusTrackerV2';
import PaperContainer from './PaperContainer';
import ContactSupport from './ContactSupport';
import CloseableDialog from './CloseableDialog';
import * as flows from '../flows';

const styles = {
  bold: {
    fontWeight: 'bold',
  },
  subtext: {
    fontWeight: 'bold',
    marginBottom: 30,
  },
  description: {
    color: colors.darkGrey,
    marginBottom: 20,
  },
  titleText: {
    fontSize: 22,
    color: colors.primaryBlue,
  },
  button: {
    width: '100%',
    backgroundColor: colors.primaryBlue,
    color: 'white',
    fontSize: 22,
    borderRadius: 5,
    padding: '10px 0',
    marginTop: 30,
  },
  snackbar: {
    backgroundColor: colors.primaryGreen,
    color: colors.bodyText,
    // Changes color of the check icon in the alert
    '& .MuiSvgIcon-root': {
      color: colors.bodyText,
    },
  },
  contactSupport: {
    marginBottom: -20,
    marginTop: 30,
  },
  laterText: {
    fontWeight: 'bold',
    color: colors.primaryBlue,
    marginTop: 10,
    fontSize: 16,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  dialog: {
    maxWidth: 450,
    width: '100vw',
    padding: '0 20 20 20',
  },
  image: {
    maxWidth: 150,
    margin: '0 auto 20px auto',
    display: 'block',
  },
};

const AreYouSureModal = props => {
  const {
    classes,
    open,
    onClose,
    productType,
    onClick,
  } = props;

  return (
    <CloseableDialog open={open} onClose={onClose}>
      <div className={classes.dialog} id="areYouSureModal">
        <img src={images.whatToExpect} alt="" className={classes.image} />
        <Typography className={classes.titleText}>Are you sure?</Typography>
        <Typography style={{ marginBottom: 10 }}>
          {productType === 'edna'
            ? "We can't complete the GenoPalate Analysis until you've answered the questions. Once you finish the questions, your results will be ready in 24 hours."
            : "We can't complete the GenoPalate Analysis until you've answered the questions."}
        </Typography>
        <Typography>
          You can come back any time to complete the questionnaire. Just sign in to your account at genopalate.com or log
          in to the app and you&apos;ll be taken to the questions.
        </Typography>
        <ButtonBase className={classes.button} onClick={onClick}>
          ANSWER QUESTIONNAIRE&nbsp;
          <FontAwesomeIcon icon={faChevronRight} />
        </ButtonBase>
        <Typography className={classes.laterText} onClick={() => flows.logout()} style={{ textAlign: 'center' }}>Log me out</Typography>
      </div>
    </CloseableDialog>
  );
};

const WelcomeQuestionnaire = props => {
  const {
    onClick,
    classes,
    productType,
    activeStep,
    isSequencingUser
  } = props;

  const [displayModal, setDisplayModal] = useState(false);

  const handleContinueClick = () => {
    registerGAClickEventEnhanced(EventCategories.PreQuestionnaire, EventActions.Click, EventLabels.Continue);
    onClick();
  };

  const handleComeBackLaterClick = () => {
    registerGAClickEventEnhanced(EventCategories.PreQuestionnaire, EventActions.Click, EventLabels.ComeBackLater);
    setDisplayModal(true);
  };

  return (
    <Fragment>
      <PaperContainer style={{ marginTop: '-65px' }} id="personalizeYourResultsContainer">
        <StatusTracker productType={productType} activeStep={activeStep} isSequencingUser={isSequencingUser}  />
        <img src={images.whatToExpect} alt="" className={classes.image} />
        <Typography className={classes.titleText}>Personalize your results</Typography>
        <Typography style={{ marginBottom: 20 }}>
          It&apos;s time to learn more about you! We will ask you a series of questions and use your responses to personalize your
          experience now and in the future. This step takes 8-10 minutes to complete.
        </Typography>
        <ButtonBase className={classes.button} onClick={handleContinueClick}>CONTINUE</ButtonBase>
        <Typography className={classes.laterText} onClick={handleComeBackLaterClick}>I&apos;ll come back later</Typography>
        <ContactSupport className={classes.contactSupport} gaEvent={() => registerGAClickEventEnhanced(EventCategories.PreQuestionnaire, EventActions.Click, EventLabels.Questionnaire)} />
      </PaperContainer>
      <AreYouSureModal open={displayModal} onClose={() => setDisplayModal(false)} classes={classes} onClick={onClick} productType={productType} />
    </Fragment>
  );
};

WelcomeQuestionnaire.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    dnaUploadedHeader: PropTypes.string,
    titleText: PropTypes.string,
    button: PropTypes.string,
    subtext: PropTypes.string,
    description: PropTypes.string,
    snackbar: PropTypes.string,
    contactSupport: PropTypes.string,
    laterText: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  productType: PropTypes.oneOf(['edna', 'kit']).isRequired,
  activeStep: PropTypes.number.isRequired,
};

AreYouSureModal.propTypes = {
  classes: WelcomeQuestionnaire.propTypes.classes,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  productType: PropTypes.string.isRequired,
};

export default withStyles(styles)(WelcomeQuestionnaire);
