import { makeStyles } from "@material-ui/core/styles";
import { colors, fonts } from "common/assets/sharedUI";

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    color: colors.gray_09,
    border: "none",
    fontSize: "22px",
    "&.Mui-selected": {
      color: colors.lightBlue,
      fontWeight: "bold",
    },
  },
  indicator: {
    border: `3px solid ${colors.lightBlue}`,
  },
  tabBorder: {
    borderBottom: `1px solid ${colors.gray_02}`,
  },
  geneticSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  buttonGroup: {
    display: "flex",
    backgroundColor: colors.gray_02,
    padding: "2px",
    borderRadius: "5px",
    width: "400px",
    margin: "0 auto"
  },
  geneticButton: {
    width: "50%",
    padding: 0,
    margin: 0,
    color: colors.gray_08,
    backgroundColor: colors.gray_02,
    textTransform: "none",
    fontSize: "22px",
    border: "none",
    opacity: "unset",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    "&:hover": {
      backgroundColor: colors.white,
      color: colors.gray_08,
      marginRight: "3px"
    },
  },
  selectedButton: {
    color: colors.gray_08,
    backgroundColor: colors.white,
    borderRadius: "5px",
  },
  geneMessage: {
    fontFamily: fonts.Swiss721LT,
    fontSize: '20px',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  }
}));

export default useStyles;
