import { makeStyles } from "@material-ui/core/styles";
import { colors, fonts } from "common/assets/sharedUI";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: fonts.Swiss721LT,
  },
  tableRowBase: {
    fontSize: "16px",
    verticalAlign: "top",
    padding: "20px",
  },
  pt_0: {
    paddingTop: 0,
  },
  rowBorder: {
    borderBottom: `1px solid ${colors.gray_02}`,
  },
  tableCellBase: {
    borderBottom: "none",
  },
  tableHeader: {
    fontWeight: "bold",
    padding: "20px",
    backgroundColor: colors.gray_01,
    fontFamily: fonts.Swiss721MD,
    fontWeight: 600,
    color: colors.darkestGrey
  },
  bold: {
    fontFamily: fonts.Swiss721MD,
    fontWeight: 600
  },
  genotypeText: {
    fontSize: "35px",
    fontFamily: fonts.Swiss721MD,
    fontWeight: 600
  },
  blue_06: {
    color: colors.blue_06,
  },
  lightBlue: {
    color: colors.lightBlue,
  },
  gray_07: {
    color: colors.gray_07,
  },
  infoText: {
    display: "flex",
    alignItems: "start",
    color: colors.gray_07,
  },
  referencesText: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    cursor: "pointer",
    fontFamily: fonts.Swiss721MD,
    fontWeight: 600
  },
  wideColumn: {
    minWidth: "250px",
  },
  infoIcon: {
    color: colors.gray_07,
    marginRight: "4px",
    marginTop: "2px",
    fontSize: "21px",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
  closeButton: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  genotypeDescription: {
    marginBottom: "32px",
    fontFamily: fonts.Swiss721MD,
    fontWeight: 600
  },
  geneDescription: {
    minWidth: "350px",
  },
  mt_4: {
    marginTop: "32px",
  },
  listItems: {
    display: "list-item",
  },
  listItemText: {
    fontSize: "18px",
    color: colors.lightBlue,
    fontFamily: fonts.Swiss721MD,
    fontWeight: 600
  },
  tableBody: {
    fontFamily: fonts.Swiss721LT,
    color: colors.darkestGrey
  },
  darkestGrey: {
    color: colors.darkestGrey
  },
  geneName: {
    color: colors.darkestGrey,
    fontFamily: fonts.Swiss721MD,
    fontWeight: 600,
    fontSize: "22px"
  },
  tooltip: {
    fontSize: "14px",
  }
}));

export default useStyles;
