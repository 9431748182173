import get from 'lodash/get';
import { parseCartResponse, throwIfErrorResponse } from './utils';

export default (shopifyQuery) => async (cartId, lineItemId) => {
  const resp = await shopifyQuery(
    `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
       cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
         cart {
          id
          totalQuantity
          checkoutUrl
          cost {
            totalAmount {
              amount
            }
            subtotalAmount {
              amount
            }
          }
          lines(first: 50) {
            nodes {
              id
              merchandise {
                __typename
                ... on ProductVariant {
                  id
                  sku
                  title
                  price {
                    amount
                  }
                  product {
                    title
                    featuredImage {
                      url
                    }
                  }
                }
              }
              quantity
              cost {
                totalAmount {
                  amount
                }
              }
              attributes {
                key
                value
              }
            }
          }
        }
        userErrors {
          field
          message
        }
      }
    }`,
    {
      cartId,
      lineIds: [lineItemId],
    },
  );

  throwIfErrorResponse(resp);

  return parseCartResponse(get(resp, 'data.data.cartLinesRemove.cart', {}));
};
