import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "common/assets/sharedUI";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: 60,
      marginTop: 60,
    },
    title: {
      fontWeight: "bold",
    },
    carouselContainer: {
      marginTop: 30,
      justifyContent: "space-around",
      height: 200,
    },
    bestSourcesContainer: {
      borderRadius: 10,
      padding: 15,
      backgroundColor: colors.white,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: 160,
      margin: "0 auto",
    },
    bestSourcesImage: {
      height: 130,
    },
    btnContainer: {
      display: "flex",
      flexFlow: "row",
      justifyContent: "center",
      marginTop: 10,
    },
    button: {
      marginTop: 20,
      backgroundColor: colors.white,
      color: colors.green_07,
      padding: "2px 8px",
      height: "unset",
      textTransform: "none",
      border: "2px solid #385C0D",
      borderRadius: "3px",
      opacity: 1,
      letterSpacing: "0px",
      color: colors.green_07
    },
    icon: {
      width: 40,
      height: 40,
      marginRight: "26px",
    },
    flex: {
      display: "flex",
      alignItems: "center"
    }
  })
);

export default useStyles;
