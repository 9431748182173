import { combineReducers } from 'redux';
import basicReducer from './basicReducer';
import actionTypes from '../actionTypes';

const shippingAddressInitialState = {
  data: {
    shippingAddressId: null,
    shippingAddress: {
      shipAddr1: '',
      shipAddr2: '',
      shipCity: '',
      shipState: '',
      shipZip: '',
    },
  },
  fetching: false,
  error: null,
  updateFetching: false,
  updateError: null,
  updateSuccess: null,
};

const shippingAddressReducer = (state = shippingAddressInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SHIPPING_ADDRESS_STARTED:
      return {
        ...state,
        fetching: true,
        data: shippingAddressInitialState.data,
        error: null,
      };
    case actionTypes.GET_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.data,
        error: null,
      };
    case actionTypes.GET_SHIPPING_ADDRESS_ERROR:
      return {
        ...state,
        fetching: false,
        data: shippingAddressInitialState.data,
        error: action.error,
      };
    case actionTypes.UPDATE_SHIPPING_ADDRESS_STARTED:
      return {
        ...state,
        updateFetching: true,
        updateError: null,
        updateSuccess: null,
      };
    case actionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        updateFetching: false,
        data: action.data,
        updateError: null,
        updateSuccess: true,
      };
    case actionTypes.UPDATE_SHIPPING_ADDRESS_ERROR:
      return {
        ...state,
        updateFetching: false,
        updateError: action.error,
        updateSuccess: null,
      };
    default:
      return state;
  }
};

export default combineReducers({
  shippingAddress: shippingAddressReducer,
  formulation: basicReducer(actionTypes.GET_FORMULATION_STARTED, actionTypes.GET_FORMULATION_SUCCESS, actionTypes.GET_FORMULATION_ERROR),
  formulationV2: basicReducer(actionTypes.GET_FORMULATION_STARTED_V2, actionTypes.GET_FORMULATION_SUCCESS_V2, actionTypes.GET_FORMULATION_ERROR_V2),
  paymentMethod: basicReducer(actionTypes.GET_PAYMENT_METHOD_STARTED, actionTypes.GET_PAYMENT_METHOD_SUCCESS, actionTypes.GET_PAYMENT_METHOD_ERROR),
  subscriptions: basicReducer(actionTypes.GET_SUBSCRIPTIONS_STARTED, actionTypes.GET_SUBSCRIPTIONS_SUCCESS, actionTypes.GET_SUBSCRIPTIONS_ERROR),
  subscriptionOrders: basicReducer(actionTypes.GET_SUBSCRIPTION_ORDERS_STARTED, actionTypes.GET_SUBSCRIPTION_ORDERS_SUCCESS, actionTypes.GET_SUBSCRIPTION_ORDERS_ERROR),
  billing: basicReducer(actionTypes.UPDATE_SUBSCRIPTION_BILLING_STARTED, actionTypes.UPDATE_SUBSCRIPTION_BILLING_SUCCESS, actionTypes.UPDATE_SUBSCRIPTION_BILLING_ERROR),
  cancelSubscription: basicReducer(actionTypes.CANCEL_SUBSCRIPTION_STARTED, actionTypes.CANCEL_SUBSCRIPTION_SUCCESS, actionTypes.CANCEL_SUBSCRIPTION_ERROR),
  questionnaire: basicReducer(actionTypes.GET_SUBSCRIPTION_QUESTIONNAIRE_STARTED, actionTypes.GET_SUBSCRIPTION_QUESTIONNAIRE_SUCCESS, actionTypes.GET_SUBSCRIPTION_QUESTIONNAIRE_ERROR),
  saveQuestionnaire: basicReducer(actionTypes.SAVE_SUBSCRIPTION_QUESTIONNAIRE_STARTED, actionTypes.SAVE_SUBSCRIPTION_QUESTIONNAIRE_SUCCESS, actionTypes.SAVE_SUBSCRIPTION_QUESTIONNAIRE_ERROR),
  pauseSubscription: basicReducer(actionTypes.PAUSE_SUBSCRIPTION_STARTED, actionTypes.PAUSE_SUBSCRIPTION_SUCCESS, actionTypes.PAUSE_SUBSCRIPTION_ERROR),
  updateSubscription: basicReducer(actionTypes.UPDATE_SUBSCRIPTION_STARTED, actionTypes.UPDATE_SUBSCRIPTION_SUCCESS, actionTypes.UPDATE_SUBSCRIPTION_ERROR),
});
