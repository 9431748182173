import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { food } from 'services';
import { selectTopFoods } from 'store/actions/topFoods';

/**
 * Custom React hook to update the food score.
 *
 * @param {boolean} isScoreUpdated - A flag indicating whether the score has been updated.
 *
 * @returns {Object} An object containing:
 * - `isUpdating`: A boolean indicating whether the update is in progress.
 * - `message`: A string containing a message about the update process.
 * - `error`: An error object if an error occurred during the update process.
 *
 * @example
 * const { isUpdating, message, error } = useUpdateFoodScore(isScoreUpdated);
 */
export const useUpdateFoodScore = (isScoreUpdated) => {
  const dispatch = useDispatch();

  const [isUpdating, setIsUpdating] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const updateFoodScore = useCallback(async () => {
    setIsUpdating(true);
    try {
      await food.updateUserFoodScore();
    } catch (error) {
      console.error('Error updating food score', error);
      setMessage('Error updating food score');
      setError(error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const getFoodScores = useCallback(async () => {
    dispatch(selectTopFoods());
  }, [dispatch]);

  const getAndUpdateFoodScores = useCallback(async () => {
    await updateFoodScore();
    await getFoodScores();
  }, [updateFoodScore, getFoodScores]);

  useEffect(() => {
    // we are explicitly checking the false value here, since initial value is null
    // we are not updating the food score until we get our top foods data
    if (isScoreUpdated === false) {
      getAndUpdateFoodScores();
    }

    return false;
  }, [isScoreUpdated]);

  return { isUpdating, message, error };
};
