/* eslint-disable react/forbid-prop-types */
// @flow
import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CssBaseline, Container, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';

import { colors } from 'common/assets/sharedUI';
import { selectStatus } from 'store/actions/status';
import { selectTopFoods } from 'store/actions/topFoods';
import { getProfile } from 'store/actions/profile';
import { getEER } from 'store/actions/nutrition';
import { user } from 'services';
import {
  WebAppToolbar, WebAppFooter, MobileBanner, WebBanner, SupplementBanner,
} from 'components/componentsV2';
import setBannerEvent from 'services/user/setBannerEvent';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';

import OnboardingToolbar from './OnboardingToolbar';
import Cart from './Cart';
import constants, { apiStatuses, bannerTypes } from '../resources/constants';
import { DietitianConsultBanner } from './Banners';

const { userStatuses } = constants;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
  },
};

const WebAppContainer = (props) => {
  const { children, navProps, classes } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  // global state
  const statusProgress = useSelector(state => state.status.status);
  const status = useSelector(state => state.status.userStatus);
  const displayHeaderFooter = useSelector(state => state.status.headerFooter);
  const userTopFoods = useSelector(state => state.topFoods.userTopFoods);
  const userTopFoodsError = useSelector(state => state.topFoods.error);
  const eer = useSelector(state => state.nutrition.eer);

  const isReportDelivered = !!status && !!status.webDisplay && status.webDisplay === userStatuses.RENDER_REPORT;
  const supplementBannerUrl = '/account/products/supplement-subscription';

  // local state
  const [openSupplementBanner, setOpenSupplementBanner] = useState(false);
  const [openMobileAppBanner, setOpenMobileAppBanner] = useState(false);

  const closeSupplementBanner = useCallback(() => {
    setBannerEvent(bannerTypes.SUPPLEMENT);
    registerGAClickEventEnhanced(EventCategories.SupplementBanner, EventActions.Close, EventLabels.SupplementBanner);
    setOpenSupplementBanner(false);
  }, [setOpenSupplementBanner]);

  const closeMobileAppBanner = useCallback(() => {
    setBannerEvent(bannerTypes.MOBILE_APP);
    registerGAClickEventEnhanced(EventCategories.MobileAppBanner, EventActions.Close, EventLabels.MobileAppBanner);
    setOpenMobileAppBanner(false);
  }, [setOpenMobileAppBanner]);

  const getStatus = useCallback(() => {
    dispatch(selectStatus());
  }, [dispatch]);

  useEffect(() => {
    getStatus();
    // Load user data
    dispatch(getProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // want this to fire only when component mounts

  const bannerPermitted = useMemo(() => isReportDelivered && navProps.tab === 'home' && statusProgress === apiStatuses.RESOLVED, [isReportDelivered, navProps.tab, statusProgress]);

  useEffect(() => {
    const getBannerStatus = async () => {
      if (bannerPermitted) {
        const showSupplementBanner = await user.getShowSupplementBanner();
        if (!showSupplementBanner) { // only show mobile app banner if supplement banner is hidden
          const showMobileDownloadBanner = await user.getShowMobileDownloadBanner();
          setOpenMobileAppBanner(showMobileDownloadBanner);
        } else {
          setOpenMobileAppBanner(false);
        }
        setOpenSupplementBanner(showSupplementBanner);
      } else { // if banners are not permitted, close both banners
        setOpenMobileAppBanner(false);
        setOpenSupplementBanner(false);
      }
    };
    getBannerStatus();
  }, [openSupplementBanner, bannerPermitted]);

  useEffect(() => {
    // if the report is delivered but we don't have foods and there's no error, let's fetch them
    // this check is needed specifically for users who are online at the time their report becomes deliverable
    // see more here: https://genopalate.fibery.io/Software_Development/WIP---Assigned-to-Me-34#bug/Web-Optimal-Foods-are-not-displayed-if-user-'Refresh'-the-page-to-receive-results-1298
    if (
      isReportDelivered
      && (
        !userTopFoods
        || (
          userTopFoods.length === 0 && !userTopFoodsError
        )
      )
    ) {
      dispatch(selectTopFoods());
    }
  }, [isReportDelivered, userTopFoods, dispatch, userTopFoodsError]);

  useEffect(() => {
    if (!eer && isReportDelivered) dispatch(getEER());
  }, [eer, dispatch, isReportDelivered]);

  const toolbarPadding = useMemo(() => {
    if (smDown) {
      if (openMobileAppBanner) {
        return 110;
      } if (openSupplementBanner) {
        return 50;
      }
    }
    return 0; // if neither banner is open or view is not mobile
  }, [openSupplementBanner, openMobileAppBanner, smDown]);

  const contentPadding = useMemo(() => {
    if (!isReportDelivered) {
      return 75;
    } if (openMobileAppBanner || openSupplementBanner) {
      return smDown ? 230 : 190;
    }
    return 120; // if neither banner is open
  }, [openSupplementBanner, openMobileAppBanner, smDown, isReportDelivered]);

  const webBannerPadding = useMemo(() => (smDown ? 0 : 140), // if only the web banner is open
    [smDown]);

  const onboardingToolbarPadding = useMemo(
    () => ((
      smDown && (openMobileAppBanner || openSupplementBanner)) ? 110 : 0
    ), [smDown, openMobileAppBanner, openSupplementBanner],
  );

  const rdConsultBanner = useMemo(
    () => ((
      smDown && (openMobileAppBanner || openSupplementBanner)) ? 200 : 90
    ), [smDown, openMobileAppBanner, openSupplementBanner],
  );

  return (
    <div className={classes.container} style={{backgroundColor: (navProps.tab === 'home' || navProps.tab === 'food') ? colors.gray_01: colors.white}}>
      <CssBaseline />
      {openMobileAppBanner ? (
        <Hidden mdUp>
          <MobileBanner
            openBanner={openMobileAppBanner}
            onClose={closeMobileAppBanner}
          />
        </Hidden>
      ) : null}
      {openSupplementBanner ? (
        <Hidden mdUp>
          <SupplementBanner
            openBanner={openSupplementBanner}
            url={supplementBannerUrl}
            onClose={() => closeSupplementBanner()} // should hide banner
          />
        </Hidden>
      ) : null}
      {isReportDelivered && displayHeaderFooter
        && (
          <WebAppToolbar
            navProps={navProps}
            topMargin={toolbarPadding}
          />
        )}
      {openMobileAppBanner ? (
        <Hidden smDown>
          <WebBanner
            openBanner={openMobileAppBanner}
            onClose={closeMobileAppBanner}
            topPosition={webBannerPadding}
            bannerPosition="fixed"
          />
        </Hidden>
      ) : null}
      {openSupplementBanner ? (
        <Hidden smDown>
          <SupplementBanner
            openBanner={openSupplementBanner}
            url={supplementBannerUrl}
            onClose={() => closeSupplementBanner()} // should hide banner
          />
        </Hidden>
      ) : null}
      {!isReportDelivered && (
        <OnboardingToolbar
          smallLogo={smDown}
          status={status}
          topPosition={onboardingToolbarPadding}
        />
      )}


      {isReportDelivered && displayHeaderFooter &&  (
        <DietitianConsultBanner topPosition={rdConsultBanner} />
      )}


      {/* 
        Render header of any page if any passes through navProps 
        we are using this because we have controlled header of each page
        and it doesn't lie under main page container
       */}
      {navProps?.header && isReportDelivered && displayHeaderFooter && <div style={{ paddingTop: contentPadding }}>{navProps.header}</div>}

      <Container style={{ flexGrow: 1, paddingTop: navProps.header && displayHeaderFooter ? 20 : contentPadding + 25 }} maxWidth="lg">{children}</Container>
      {isReportDelivered && displayHeaderFooter && <WebAppFooter />}
      <Cart />
    </div>
  );
};

WebAppContainer.propTypes = {
  navProps: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(WebAppContainer);
