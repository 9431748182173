import get from 'lodash/get';
import { throwIfErrorResponse, parseCartResponse } from './utils';

/**
 * A function that when called will add a product line to an existing cart. The cartId, productId, sellingPlanId, and quantity
 * are graphQL id forms, such as 'gid://shopify/Cart/3089dc430f794e053155ee73cb1dcd19', 'gid://shopify/ProductVariant/42523837202601',
 * and 'gid://shopify/SellingPlan/1048740009'
 *
 * @param shopifyQuery
 *
 * @return {function(*, *, *): Promise<{lineItems: *, total: *, subtotal: *, checkoutUrl: *, tax: *, id: *}>}
 */
const addToCart = (shopifyQuery) => async (cartId, productId, sellingPlanId, additionalAttributes = {}, quantity = 1) => {
  // TODO: fix this: additionalAttributes should be in form of key value like {key: oneTime, value: true }
  const attributes = [{ key:'_oneTime', value: sellingPlanId ? 'No': 'Yes' }];
  Object.keys(additionalAttributes).length > 0 ? attributes.push(additionalAttributes) : null; //if we have any attributes push into the array otherwise do nothing 

  const lines = [{ merchandiseId: productId, quantity, sellingPlanId, attributes }];

  // https://shopify.dev/api/storefront/2022-10/mutations/cartLinesAdd
  const resp = await shopifyQuery(
    `mutation ($cartId: ID!, $lines: [CartLineInput!]!) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {
          id
          totalQuantity
          checkoutUrl
          cost {
            totalAmount {
              amount
            }
            subtotalAmount {
              amount
            }
          }
          lines(first: 50) {
            nodes {
              id
              merchandise {
                __typename
                ... on ProductVariant {
                  id
                  sku
                  title
                  price {
                    amount
                  }
                  product {
                    title
                    featuredImage {
                      url
                    }
                  }
                }
              }
              quantity
              cost {
                totalAmount {
                  amount
                }
              }
              attributes {
                key
                value
              }
            }
          }
        }
        userErrors {
          message
          field
        }
      }
    }`,
    {
      cartId,
      lines,
    },
  );

  throwIfErrorResponse(resp);

  return parseCartResponse(get(resp, 'data.data.cartLinesAdd.cart', {}));
};

export default addToCart;
