import { useMemo, useEffect, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  getShippingAddress,
  getPaymentMethod,
  getSubscriptions,
} from 'store/actions/subscription'
import { getCurrentSubscriptionV1 } from 'services/shopify/utils'
import { useSubscriptions } from './index'
import { supplementVariants, supplementProductSkus } from 'resources/constants'

/**
 * Custom hook for fetching shipping addresses from the API.
 * @function useFetchShippingAddress
 */
const useFetchShippingAddress = (dispatch) => {
  useEffect(() => {
    dispatch(getShippingAddress())
  }, [dispatch])
}

/**
 * Custom hook for fetching payment methods from the API.
 * @function useFetchPaymentMethod
 */
const useFetchPaymentMethod = (dispatch) => {
  useEffect(() => {
    dispatch(getPaymentMethod())
  }, [dispatch])
}

/**
 * Custom hook for filtering subscriptions based on a product ID.
 * @function useFilterSubscriptionsByProductId
 * @param {Array<Object>} subscriptions - The array of subscription objects.
 * @param {string} productId - The product ID to filter subscriptions.
 * @returns {Array<Object>} - The filtered subscriptions.
 */
const useFilterSubscriptionsByProductId = (subscriptions = [], productId) => {
  const activeSubscription = subscriptions
    ? getCurrentSubscriptionV1(subscriptions)
    : null

  return useMemo(() => {
    return (
      activeSubscription?.find((subscription) =>
        subscription.subscriptionName.includes(productId)
      ) || null
    )
  }, [subscriptions, productId])
}

const useFilterSubscriptionsByProductIdV2 = (subscriptions = [], productId) => {
  const activeSubscription = subscriptions
    ? getCurrentSubscriptionV1(subscriptions)
    : null

  return useMemo(() => {
    return (
      activeSubscription?.find((subscription) =>
        supplementProductSkus[subscription.productSku]
      ) || null
    )
  }, [subscriptions, productId])
}

/**
 * Main custom hook for managing user subscriptions, shipping data, and payment methods.
 * @function useUserCurrentSubscription
 * @param {"GenoVit" | "GenoBlend"} productId - The product ID for filtering subscriptions.
 * @returns {UseUserCurrentSubscriptionHook} - An object containing user subscription information.
 */
const useUserCurrentSubscription = (productId) => {
  const dispatch = useDispatch()

  const { data: subscriptions, fetching } = useSelector(
    (state) => state.subscription.subscriptions
  )
  const { data: shippingData } = useSelector(
    (state) => state.subscription.shippingAddress
  )
  const { data: userPaymentMethod } = useSelector(
    (state) => state.subscription.paymentMethod
  )

  // Fetch data using custom hooks
  useSubscriptions()
  useFetchShippingAddress(dispatch)
  useFetchPaymentMethod(dispatch)

  const refetch = useCallback(() => {
    dispatch(getSubscriptions());
  }, [dispatch])

  // Filter subscriptions based on the product ID
  const currentSubscription = useFilterSubscriptionsByProductId(
    subscriptions,
    productId
  )

  const currentSubscriptionV2 = useFilterSubscriptionsByProductIdV2(
    subscriptions,
    productId
  )

  /**
   * @type {UseUserCurrentSubscriptionHook}
   */
  return {
    currentSubscriptionFetching: fetching,
    //TODO: hotfix for blend subscription
    currentBlendSubscription: currentSubscription,
    currentSubscription: currentSubscriptionV2,
    currentSubscriptionV2,
    shippingData: shippingData || {}, // Default to an empty object if null or undefined
    paymentMethod: userPaymentMethod || {}, // Default to an empty object if null or undefined
    refetch
  }
}

export default useUserCurrentSubscription
