import React, { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Typography, Grid, Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { colors } from 'common/assets/sharedUI';
import { registerGAClickEventEnhanced, EventCategories } from '../../utils/googleanalytics';
import { sharedStyles } from '../../resources';

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    height: 150,
    width: 150,
    objectFit: 'cover',
    borderRadius: 8,
    marginBottom: 24,
  },
  button: {
    textTransform: 'none',
    color: colors.green_05,
    width: 'fit-content',
    marginLeft: '-6px',
  },
}));

const AdditionalProduct = ({
  name, image, price, description, id,
}) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const history = useHistory();

  const onViewProductClicked = useCallback(() => {
    registerGAClickEventEnhanced(EventCategories.AddOns, 'Learn More', name);
    history.push(`/account/products/product-details/${id}`);
  }, [history, id, name]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.wrapper}>
          <img src={image} alt={name} className={classes.image} />
          <Typography className={sharedClasses.h6} style={{ color: colors.gray_04, marginBottom: 8 }}>
            {`$${price}`}
          </Typography>
          <Typography className={sharedClasses.h6} style={{ marginBottom: 8 }}>
            {name}
          </Typography>
          <Typography className={sharedClasses.body} style={{ marginBottom: 8 }}>
            {description}
          </Typography>
          <Button size="small" className={clsx(sharedClasses.link, classes.button)} onClick={onViewProductClicked}>
            View Product
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

AdditionalProduct.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default AdditionalProduct;
