import React, {
  useEffect, useCallback, useMemo, useState, useLayoutEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  isEqual, snakeCase,
} from 'lodash';
import {
  useHistory,
  useRouteMatch,
  Link,
} from 'react-router-dom';
import {
  Grid, Typography, Button, makeStyles, createStyles,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  getFormulationV2,
  getSubscriptions,
  getShippingAddress,
  getPaymentMethod,
  cancelSubscription,
  saveSubscriptionQuestionnaire,
  getSubscriptionQuestionnaire,
  pauseSubscription,
  updateSubscriptionV2
} from 'store/actions/subscription';
import {
  fetchAdditionalProducts, fetchPurchasedProducts, fetchSubscriptionProducts, fetchSubscriptionProductsV2, addItemToCart, updateBoostersInCart, replaceItemInCart, showCart, removeItemFromCart, fetchBoosterFormulations, updateBoostersInCartV1,
} from 'store/actions/additionalProducts';
import {
  Loading,
} from 'components/componentsV2';
import { AccountAlert } from 'components/AccountForm';
import {
  CustomFormula,
  Booster,
  CancelSubscriptionDialog,
  QuestionnaireDialog,
  PauseSubscriptionDialog,
  ProductPrice
} from 'components/SupplementSubscription';
import InstallmentButtons from 'components/SupplementSubscription/InstallmentButtons';
import { CustomDialog, CustomLoader, ProductPlans } from 'components/shared'
import {
  AlertTypes, supplementProductSkus, defaultErrorMessage, genericErrorMessage, installmentSupplementProductSKUs,
} from 'resources/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft, faTruck, faLocationDot, faCalendarClock, faCreditCard, faCheck,
} from '@fortawesome/pro-solid-svg-icons';
import ReactGA from 'react-ga4';
import { colors, fonts } from 'common/assets/sharedUI';
import { getSubscriptionFromBoosters } from 'services/shopify/utils';
import clsx from 'clsx';
import { userStatuses } from 'common/resources/constants';
import { useGetSupplementBaseProduct, useGetSupplementBoosters, useQueryParams, useSubscriptionProducts, useUserCurrentSubscription } from 'hooks';
import { images, sharedStyles } from 'resources';

import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from 'utils/googleanalytics';
import AdditionalProducts from 'components/AdditionalProducts';
import { helpers, subscriptionHelpers } from 'utils';
import { SUBSCRIPTION_DURATIONS, SUBSCRIPTION_TYPE } from 'utils/subscriptionHelpers';
import { genopalateClickIdKey } from 'services/shopify/utils'
import { ArrowForward } from '@material-ui/icons';
import ManageSubscription from './ManageSubscription';

const { IS_PROD, IS_STAGING } = window.env;

// eslint-disable-next-line no-shadow
const useStyles = makeStyles((theme) => createStyles({
  backLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10 0 28 0px',
  },
  icon: {
    color: colors.gray_05,
    width: 16,
  },
  backLinkText: {
    fontFamily: fonts.paragraph2,
    color: colors.gray_05,
    width: 'fit-content',
    margin: '0 0 0 8px',
    fontSize: 16,
    fontWeight: 400,
  },
  productImage: {
    backgroundColor: colors.green_01,
    width: '100%',
    height: '100%',
    borderRadius: 8,
  },
  subscriptionImage: {
    backgroundColor: colors.green_01,
    width: '100%',
    height: '100%',
    borderRadius: 8,
  },
  productOuterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  responsiveWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  topSpace: {
    marginTop: 10,
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  iconsWrapper: {
    marginBottom: 10
  },
  productDetailsWrapper: {
    maxWidth: 480,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  subscriptionTitle: {
    fontFamily: fonts.heading3,
    color: colors.gray_06,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
    marginBottom: 10,
  },
  subscriptionDescription: {
    fontFamily: fonts.paragraph1,
    color: colors.gray_06,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    marginBottom: 15,
  },
  linkText: {
    fontFamily: fonts.paragraph2,
    color: colors.gray_05,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    marginBottom: 8,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  subscriptionPrice: {
    fontFamily: fonts.heading3,
    color: colors.gray_06,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  nonSubscriptionPrice: {
    fontFamily: fonts.heading3,
    color: colors.blue_03,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  iconTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  iconText: {
    fontFamily: fonts.paragraph1,
    color: colors.gray_06,
    fontWeight: 400,
    fontSize: 16,
    margin: '0 0 0 8px',
  },
  formulaHeader: {
    fontFamily: fonts.heading3,
    color: colors.blue_05,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
  },
  formulaWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 32,
    gap: 64,
    width: '100%',
    height: 416,
  },
  horizontalRule: {
    marginTop: 40,
    marginBottom: 40,
    borderTop: `1px solid ${colors.gray_03}`,
  },
  subscriptionCol: {
    width: 260,
  },
  subscriptionDivider: {
    borderLeft: `1px solid ${colors.gray_03}`,
    paddingLeft: 32,
  },
  checkoutWrapper: {
    marginTop: 80,
    marginBottom: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  additionalProductWrapper: {
    marginBottom: 25,
    paddingRight: 25,
  },
  priceCaption: {
    marginTop: 5,
    marginBottom: 15,
    fontWeight: 600,
  },
  addToCartDisabledText: {
    fontFamily: fonts.paragraph2,
    color: colors.red,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
  primaryButton: {
    color: colors.green_07,
    fontSize: '16px',
    textTransform: 'none',
    padding: 0,
    borderRadius: 0,
    display: 'inline-flex',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.green_07}`,
    cursor: 'pointer',
    transition: 'color 0.2s ease, border-color 0.2s ease',
    lineHeight: 0,
    '&:hover': {
      color: colors.green_07,
      borderBottomColor: colors.green_07,
    },
  },
  arrowIcon: {
    fontSize: '16px',
    marginLeft: 4,
    color: 'inherit',
    display: 'inline-block', 
  },
  truemedLogo: {
    height: '14px',
  },
  truemedMargin: {
    marginTop: "10px"
  }
}));



const SupplementSubscriptionPage = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const sharedClasses = sharedStyles();
  const history = useHistory();

  const [supplyPlanID, setSupplyPlanID] = useState(undefined); // either 3 or 6 months or 1 month (for installment)
  const { getQueryParam } = useQueryParams();

  const { data: allSubscriptionProducts } = useSelector((state) => state.additionalProducts.subscriptionProductsV2);
  const { data: existingAllSubscriptionProducts } = useSelector((state) => state.additionalProducts.subscriptionProducts);
  const { data: formulations } = useSelector((state) => state.subscription.formulationV2);

  // Filtering out one time, six month and 3 month subscription based on user selection
  const subscriptionProducts = useMemo(() => {
    return allSubscriptionProducts?.filter(subscriptionProduct => subscriptionProduct?.duration === supplyPlanID) ?? null
  }, [allSubscriptionProducts?.length, supplyPlanID])

  const { cart, spinnerOpen } = useSelector((state) => state.additionalProducts.cart);
  const { data: products } = useSelector((state) => state.additionalProducts.products);
  // boostersInCart represents the boosters currently in the Shopify cart. It includes
  // the probiotic booster as a representation of the base subscription.
  // It's either an array of booster name strings or null.
  const { boostersInCart, boostersInCartFetching } = useSelector(state => ({
    boostersInCart: state.additionalProducts.boostersInCart.data,
    boostersInCartFetching: state.additionalProducts.boostersInCart.fetching,
  }));
  const { data: boosterFormulations } = useSelector((state) => state.additionalProducts.boosterFormulations);
  const { cancelSubscriptionFetching, cancelSubscriptionError, cancelSubscriptionSuccess } = useSelector(state => ({
    cancelSubscriptionFetching: state.subscription.cancelSubscription.fetching,
    cancelSubscriptionError: state.subscription.cancelSubscription.error,
    cancelSubscriptionSuccess: state.subscription.cancelSubscription.data,
  }));
  const { pauseSubscriptionFetching, pauseSubscriptionError, pauseSubscriptionSuccess } = useSelector(state => ({
    pauseSubscriptionFetching: state.subscription.pauseSubscription.fetching,
    pauseSubscriptionError: state.subscription.pauseSubscription.error,
    pauseSubscriptionSuccess: state.subscription.pauseSubscription.data,
  }));
  const { updateSubscriptionFetching, updateSubscriptionError, updateSubscriptionSuccess } = useSelector((state) => ({
    updateSubscriptionFetching: state.subscription.updateSubscription.fetching,
    updateSubscriptionError: state.subscription.updateSubscription.error,
    updateSubscriptionSuccess: state.subscription.updateSubscription.data,
  }));
  const { questionnaire } = useSelector(state => ({
    questionnaire: state.subscription.questionnaire.data,
  }));
  const saveSubscriptionQuestionnaireSuccess = useSelector(state => state.subscription.saveQuestionnaire.data);
  const status = useSelector(state => state.status.userStatus);

  const isReportDelivered = useMemo(() => !!status && !!status.webDisplay && status.webDisplay === userStatuses.RENDER_REPORT, [status]);

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showPauseDialog, setShowPauseDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false);
  const [showQuestionnaireDialog, setShowQuestionnaireDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertType, setAlertType] = useState(AlertTypes.ERROR);
  const [isUpdating, setIsUpdating] = useState(false)

  // const currentSubscription = useMemo(() => (subscriptions ? getCurrentSubscription(subscriptions) : null), [subscriptions]);
  const { currentSubscriptionV2: currentSubscription, refetch } = useUserCurrentSubscription('GenoVit');

  const currentSubscriptionProduct = useMemo(() => {
    if (currentSubscription && subscriptionProducts) {
      // NOTE: this function gives you the mapping product of already subscribed genovit product
      const { id, planId } = getSubscriptionFromBoosters(currentSubscription.subscriptionItems, subscriptionProducts);
      if(id === null && planId === null) {
        // for checking the baseProduct
        return subscriptionProducts.find(product => product.sku === currentSubscription.productSku) || {};
      }
      return subscriptionProducts.find(product => product.id === id && product.planId === planId) || {};
    }
    return {};
  },
    [currentSubscription, subscriptionProducts]);

  const existingSubscriptionProduct = useMemo(() => {
    if (currentSubscription && existingAllSubscriptionProducts) {
      return existingAllSubscriptionProducts.find(product => product.sku === currentSubscription.productSku);
    }
    return null;
  }, [currentSubscription, existingAllSubscriptionProducts]);

  const nextOrderDate = useMemo(() => (currentSubscription ? new Date(currentSubscription.nextOrderDate) : null), [currentSubscription]);
  const { baseProduct } = useGetSupplementBaseProduct(subscriptionProducts, supplyPlanID);
  const { boostersV1: boosters } = useGetSupplementBoosters(subscriptionProducts, supplyPlanID, supplyPlanID)

  //TODO:
  const [boostersToUpdate, setBoostersToUpdate] = useState([]);

  const onClickPlan = (planId) => {
    setSupplyPlanID(planId);
    // setQueryParam('duration', planId);
    //TODO: for now will set to default when change the frequency
    if (currentSubscription) {
      setBoostersToUpdate(currentSubscription.subscriptionItems);
    } else {
      setBoostersToUpdate([]);
    }
  };

  useLayoutEffect(() => {
    if (currentSubscription?.subscriptionItems) {
      setBoostersToUpdate(currentSubscription?.subscriptionItems || [])
    }
  }, [currentSubscription?.subscriptionItems])

  const availableProducts = useMemo(() => {
    if (Array.isArray(products) && products.length > 0) {
      return products.sort((a, b) => a.sortOrder - b.sortOrder);
    }
    return [];
  }, [products]);

  const productInCart = () => {
    if (cart && cart.lineItems) {
      return cart.lineItems.find(item => supplementProductSkus[item.sku]);
    }
    return null;
  };

  const boosterIsSelected = useCallback((name) => {
    //TODO: for update we are handling local state for now
    if (
      (boostersInCart)
    ) {
      const boosters = boostersInCart || []
      return [...boosters].includes(name);
    }
    if (currentSubscription) {
      return [...boostersToUpdate].includes(name);
    }
    return false;
  }, [boostersInCart, currentSubscription, boostersToUpdate]);

  const addToCartHandled = useCallback((product) => {
    let additionalAttributes = {};
    if (getQueryParam(genopalateClickIdKey)){
      additionalAttributes = {
        key: genopalateClickIdKey,
        value: getQueryParam(genopalateClickIdKey)
      };
    }

    registerGAClickEventEnhanced(EventCategories.AddOns, EventActions.AddToCart, product.name);
    dispatch(addItemToCart(product.id, product.planId ? product.planId : undefined, undefined, additionalAttributes));
    setShowQuestionnaireDialog(false);
  }, [dispatch]);

  const removeFromCartClicked = useCallback((lineItem) => {
    console.log('removeFromCartClicked', lineItem)
    registerGAClickEventEnhanced(EventCategories.AddOns, EventActions.RemoveFromCart, lineItem.title);
    dispatch(removeItemFromCart(lineItem.cartLineId));
  }, [dispatch]);

  const handleCancelSubscription = useCallback((reason, comments) => {
    registerGAClickEventEnhanced(EventCategories.Subscription, EventActions.Click, EventLabels.CancelSubscription);
    dispatch(cancelSubscription(currentSubscription.subscriptionId, reason, comments));
  }, [currentSubscription, dispatch]);

  const handlePauseSubscription = useCallback((pauseDate) => {
    const additionalProperties = [{
      name: 'SUBSCRIPTION_TYPE',
      value: SUBSCRIPTION_TYPE.PAUSE,
    },
    {
      name: '_oneTime',
      value: 'No',
    }
  ];
    registerGAClickEventEnhanced(EventCategories.Subscription, EventActions.Click, EventLabels.PauseSubscription);
    dispatch(pauseSubscription(currentSubscription.subscriptionId, pauseDate, additionalProperties));
  }, [currentSubscription, dispatch]);

  const selectedSubscriptionLineItem = useMemo(() => {
    if (boostersToUpdate.length > 0 || productInCart() && subscriptionProducts !== null) {
      //TODO:
      const subscription = getSubscriptionFromBoosters(boostersInCart || [...boostersToUpdate], subscriptionProducts);
      if (subscription.id) {
        // TODO: For local manage boosters
        if (boostersToUpdate.length) {
          return subscriptionProducts.find(subscriptionProduct => subscriptionProduct.id === subscription.id)
        }
        return cart.lineItems.find(item => item.id === subscription.id);
      }
    } else if (currentSubscription && subscriptionProducts !== null && boostersToUpdate.length == 0) {
      // if there are no booster to update that means there is base product, we need to filter out the base with the active subscription duration
      return subscriptionProducts.find(subscriptionProduct => 
        subscriptionProduct.duration === currentSubscription.duration && subscriptionProduct.sku === `VS-BASE-${currentSubscription.duration}MO`// since we have the convention of skus for subscription products
      ) || {}; // if not found return empty object
    }
    return {};
  }, [boostersInCart, subscriptionProducts, cart, boostersToUpdate.length]);

  const selectedSubscriptionProduct = useMemo(() => {
    if (selectedSubscriptionLineItem && subscriptionProducts) {
      const product = subscriptionProducts.find(subscriptionProduct => subscriptionProduct.id === selectedSubscriptionLineItem.id);
      return product ? product : {};
    }
    return {};
  }, [selectedSubscriptionLineItem, subscriptionProducts, boostersToUpdate.length]);

  const selectedSubscriptionPricePerMonth = selectedSubscriptionProduct?.pricePerMonth || null;

  const openQuestionnaireDialog = useCallback(() => {
    setShowQuestionnaireDialog(true);
  }, []);

  const handleSaveQuestionnaire = useCallback((answers) => {
    if (questionnaire) {
      dispatch(saveSubscriptionQuestionnaire(questionnaire.questionnaireId, answers));
    }
    addToCartHandled(baseProduct);
  }, [addToCartHandled, baseProduct, questionnaire, dispatch]);

  // refresh subscription questionnaire if subscription questionnaire is updated
  useEffect(() => {
    dispatch(getSubscriptionQuestionnaire());
  }, [dispatch, saveSubscriptionQuestionnaireSuccess]);

  const handleNewAlert = useCallback(({ message, title, type }) => {
    setAlertMessage(message);
    setAlertTitle(title);
    setAlertType(type);
    setShowAlert(true);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setAlertMessage('');
    setAlertTitle('');
    setAlertType(AlertTypes.DEFAULT);
    setShowAlert(false);
  }, []);

  const handleCloseCancelDialog = useCallback(() => {
    setShowCancelDialog(false);
    setIsCanceling(false);
  }, []);

  const handleCloseDialogAfterCancellation = useCallback(() => {
    setShowCancelDialog(false);
    setIsCanceling(false);
    history.push('/shop');
    window.location.reload();
  }, [history]);

  const handleOpenCancelDialog = useCallback(() => {
    setShowCancelDialog(true);
  }, []);

  const handleOpenPauseDialog = useCallback(() => {
    setIsCanceling(false);
    setShowPauseDialog(true);
  }, []);

  const handleClosePauseDialog = useCallback(() => {
    // refetch the data for showing updated info
    refetch();
    setShowPauseDialog(false);
  }, []);

  const handleOpenCancelSubscriptionFromPause = useCallback(() => {
    handleClosePauseDialog();
    handleOpenCancelDialog();
  }, [])

  const handleCloseQuestionnaireDialog = useCallback(() => {
    setShowQuestionnaireDialog(false);
  }, []);

  const subscriptionLineItemId = useMemo(() => {
    if (productInCart() && selectedSubscriptionLineItem && selectedSubscriptionLineItem.id) {
      const { id: subscriptionId } = selectedSubscriptionLineItem;
      const { cartLineId } = cart.lineItems.find(item => item.id === subscriptionId);
      return cartLineId;
    }
    return null;
  }, [cart, selectedSubscriptionLineItem]);

  // Removes the old subscription item in the cart and replaces it with the new subscription.
  // Input is an array of booster names.
  const updateBoosters = useCallback((boostersForUpdate) => {
    console.log('updateBooster', boostersForUpdate)
    // if new subscription is the same as current subscription, remove subscription from cart
    if (currentSubscription && isEqual(currentSubscription.subscriptionItems.sort(), boostersForUpdate.sort())) {
      if (subscriptionLineItemId) {
        dispatch(removeItemFromCart(subscriptionLineItemId));
      }
    } else {
      const { id, planId } = getSubscriptionFromBoosters(boostersForUpdate, subscriptionProducts); // get new subscription
      console.log({id, planId})
      let additionalAttributes = {};
      if (getQueryParam(genopalateClickIdKey)){
        additionalAttributes = {
          key: genopalateClickIdKey,
          value: getQueryParam(genopalateClickIdKey)
        };
      }
      if (subscriptionLineItemId) {
        dispatch(replaceItemInCart(subscriptionLineItemId, id, planId, additionalAttributes));
      } else {
        dispatch(addItemToCart(id, planId, undefined, additionalAttributes));
      }
    }
  }, [dispatch, subscriptionProducts, currentSubscription, subscriptionLineItemId]);

  const onAddBoosterToCartClicked = useCallback((booster) => {
    console.log('onAddBoosterToCartClicked', booster)
    registerGAClickEventEnhanced(EventCategories.AddOns, EventActions.AddToCart, EventLabels.Booster(booster));
    if (currentSubscription && !boostersInCart) {
      console.log('if')
      updateBoosters([...currentSubscription.subscriptionItems, booster]);
    } else {
      console.log('else', boostersInCart, booster)
      const addedBoosters = boostersInCart || []
      updateBoosters([...addedBoosters, booster]);
    }
  }, [boostersInCart, updateBoosters, currentSubscription]);

  const onRemoveBoosterFromCartClicked = useCallback((booster) => {
    registerGAClickEventEnhanced(EventCategories.AddOns, EventActions.RemoveFromCart, EventLabels.Booster(booster));
    if (currentSubscription && !boostersInCart) {
      const boostersForUpdate = currentSubscription.subscriptionItems.filter(boosterInSubscription => boosterInSubscription !== booster);
      updateBoosters(boostersForUpdate);
    } else {
      const boostersForUpdate = boostersInCart.filter(boosterInCart => boosterInCart !== booster);
      updateBoosters(boostersForUpdate);
    }
  }, [boostersInCart, updateBoosters, currentSubscription]);

  const onCancelSubscriptionClicked = useCallback(() => {
    setShowPauseDialog(true);
    setIsCanceling(true);
  }, []);

  const onSubscriptionUpdated = () => {
    setIsUpdating(false)
    handleOpenUpdateDialog()
  }

  const handleSaveChanges = () => {
    setIsUpdating(true)

    // TODO: should have single source of truth for selected subscription either booster or without booster
    const productToUpdate = !isSubscriptionUpdated ? selectedSubscriptionProduct : currentSubscriptionProduct;
    const variantId = productToUpdate.id.split('/').pop() // Get the variant id only

    const payload = {
      quantity: 1,
      sku: productToUpdate.sku,
      duration: productToUpdate.duration,
      durationUnit: snakeCase(productToUpdate.durationUnit),
      variant: variantId,
      properties: [
        {
          name: 'SUBSCRIPTION_TYPE',
          value: SUBSCRIPTION_TYPE.UPDATED,
        },
        {
          name: '_oneTime',
          value: 'No',
        }
      ],
    }

    registerGAClickEventEnhanced(EventCategories.Subscription, EventActions.Click, EventLabels.UpdateSubscription(`${productToUpdate.duration}_${productToUpdate.durationUnit}`));

    dispatch(
      updateSubscriptionV2(
        currentSubscription?.subscriptionId,
        payload,
        onSubscriptionUpdated
      )
    )
  }

  const handleAddBoosterForUpdate = (booster) => {
    setBoostersToUpdate((pevBoosters) => ([...pevBoosters, booster.name]));
  }

  const handleRemoveBoosterForUpdate = (booster) => {
    setBoostersToUpdate((pevBoosters) => pevBoosters.filter(pevBooster => pevBooster !== booster.name));
  }

  const handleOpenUpdateDialog = useCallback(() => {
    setShowUpdateDialog(true)
  }, [])

  const handleCloseUpdateDialog = useCallback(() => {
    refetch()
    setShowUpdateDialog(false)
  }, [])

  // clean up any old alerts when page initially loads
  useEffect(() => {
    handleCloseAlert();
  }, [handleCloseAlert]);

  const openCart = useCallback(() => {
    dispatch(showCart());
  }, [dispatch]);

  useEffect(() => {
    if (IS_PROD || IS_STAGING) {
      ReactGA.send({ hitType: "pageview", page: match.url });
    }
  }, [match]);

  useEffect(() => {
    if (currentSubscription) {
      currentSubscription.isInstallmentProduct
        ? setSupplyPlanID(currentSubscription?.billingDuration)
        : setSupplyPlanID(currentSubscription.duration);
    } else {

      if (cart?.lineItems) {
        const cartItem = cart?.lineItems?.find(item => item?.sku === supplementProductSkus[item.sku]);
        const duration = subscriptionHelpers.getTheSupplyPlanBasedOnSupplementProductSku(cartItem?.sku)
        setSupplyPlanID(duration)
      }
    }

  }, [currentSubscription?.duration, cart]);

  useEffect(() => {
    dispatch(getSubscriptions());
    dispatch(fetchSubscriptionProducts());
    dispatch(fetchSubscriptionProductsV2());
    dispatch(fetchPurchasedProducts());
    dispatch(fetchAdditionalProducts());
    dispatch(getShippingAddress());
    dispatch(getPaymentMethod());
    dispatch(getFormulationV2());
    dispatch(getSubscriptionQuestionnaire());
    dispatch(fetchBoosterFormulations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateBoostersInCart(allSubscriptionProducts)); //TODO: verify for all the products
  }, [dispatch, cart, allSubscriptionProducts]);

  useEffect(() => {
    if (updateSubscriptionFetching === false && updateSubscriptionError) {
      handleNewAlert({
        message:
          updateSubscriptionError === 'error' ||
            updateSubscriptionError.includes(genericErrorMessage)
            ? defaultErrorMessage
            : updateSubscriptionError,
        title: 'Error',
        type: AlertTypes.ERROR,
      })
      //TODO: refactor
      setIsUpdating(false)
    }
  }, [updateSubscriptionFetching, updateSubscriptionError, handleNewAlert])

  useEffect(() => {
    if (cancelSubscriptionFetching === false && cancelSubscriptionError) {
      handleNewAlert({
        message: cancelSubscriptionError === 'error' || cancelSubscriptionError.includes(genericErrorMessage)
          ? defaultErrorMessage : cancelSubscriptionError,
        title: 'Error',
        type: AlertTypes.ERROR,
      });
    }
  }, [cancelSubscriptionFetching, cancelSubscriptionError, handleNewAlert]);

  useEffect(() => {
    if (pauseSubscriptionFetching === false && pauseSubscriptionError) {
      handleNewAlert({
        message: pauseSubscriptionError === 'error' || pauseSubscriptionError.includes(genericErrorMessage)
          ? defaultErrorMessage : pauseSubscriptionError,
        title: 'Error',
        type: AlertTypes.ERROR,
      });
    }
  }, [pauseSubscriptionFetching, pauseSubscriptionError, handleNewAlert]);

  //TODO: compare boosters with current subscriptions, and boosters to update to see if there is any changes to update
  const isSubscriptionUpdated = useMemo(() => helpers.areArraysEqual(currentSubscription?.subscriptionItems, boostersToUpdate), [currentSubscription, boostersToUpdate]);

  return (
    <div className={classes.pageWrapper}>
      <Grid container>
        <Link to={currentSubscription ? '/account' : '/shop'}>
          <div className={classes.backLink}>
            <FontAwesomeIcon icon={faArrowLeft} className={classes.icon} />
            <p className={classes.backLinkText}>{currentSubscription ? 'My Products' : 'Shop'}</p>
          </div>
        </Link>
        {
          baseProduct ? (
            <React.Fragment>
              <Grid item xs={12} className={classes.productOuterWrapper} style={{ alignItems: 'center' }}>
                <Grid container spacing={10}>
                  <Grid item xs={12} md={6}>
                    <div className={classes.responsiveWrapper} style={{ alignItems: 'center' }}>
                      <img
                        alt="Product"
                        className={classes.subscriptionImage}
                        src="https://cdn.shopify.com/s/files/1/0272/9689/0928/files/genovit-vl-supplement.jpg?v=1729753021"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={5} className={classes.topSpace}>

                    {currentSubscription ? (
                      <ManageSubscription 
                        classes={classes}
                        currentSubscriptionProduct={currentSubscriptionProduct}
                        supplyPlanID={supplyPlanID}
                        onClickPlan={onClickPlan}
                        disabled={!isReportDelivered || productInCart() || (currentSubscription && currentSubscription.isInstallmentProduct)}
                        />
                    ) : (
                      <div></div>
                    )}

                    <div className={classes.productDetailsWrapper}>
                      {!currentSubscription ? 
                        <Typography className={classes.subscriptionTitle}>
                          {baseProduct.name}
                        </Typography>
                      : null }

                      {!currentSubscription ?
                        <Typography className={classes.subscriptionDescription}>
                          {baseProduct.descriptionHtml}
                        </Typography>
                      : ''}

                      {/* OPTIONS */}
                      {!currentSubscription ? (
                      <ProductPlans
                        plans={subscriptionHelpers.getSubscriptionPlans(
                          allSubscriptionProducts
                        )}
                        activeSupplyPlan={
                          supplyPlanID === SUBSCRIPTION_DURATIONS.MONTHLY
                            ? SUBSCRIPTION_DURATIONS.THREE
                            : supplyPlanID
                        }
                        activeSubscription={Boolean(currentSubscription)}
                        onClickPlan={(plan) =>
                          onClickPlan(plan.subscriptionDuration)
                        }
                        disabled={!isReportDelivered || productInCart() || (currentSubscription && currentSubscription.isInstallmentProduct)}
                        isMonthly={supplyPlanID === SUBSCRIPTION_DURATIONS.MONTHLY}
                      />
                      ) : null}
                      {/* OPTIONS */}

                      {(+supplyPlanID === SUBSCRIPTION_DURATIONS.MONTHLY ||
                        +supplyPlanID === SUBSCRIPTION_DURATIONS.THREE) && !currentSubscription && (
                          <InstallmentButtons
                            duration={+supplyPlanID}
                            subscriptionPlan={
                              subscriptionHelpers.getSpecificSubscriptionPlans(
                                allSubscriptionProducts,
                                ['VS-BASE-3MO']
                              )[0]
                            }
                            installmentPlan={
                              subscriptionHelpers.getSpecificSubscriptionPlans(
                                allSubscriptionProducts,
                                ['VS-BASE-1MO']
                              )[0]
                            }
                            setSupplyPlanID={setSupplyPlanID}
                            supplyPlanID={supplyPlanID}
                            disabled={!isReportDelivered || productInCart() || currentSubscription}
                          />
                        )}

                      {currentSubscription ? (
                        <ProductPrice 
                          actualPrice={`${helpers.formatNumber(currentSubscriptionProduct.pricePerMonth)}/mo`} 
                          savingPrice={existingSubscriptionProduct && supplyPlanID === currentSubscription?.billingDuration ? `${helpers.formatNumber(existingSubscriptionProduct.pricePerMonth)}/mo`: null}                          
                          actualPriceClasses={classes.subscriptionPrice}
                          savingPriceClasses={classes.subscriptionPrice}
                          />
                      ) : (
                        <Typography
                          className={classes.nonSubscriptionPrice}
                          style={{ textAlign: 'left' }}
                        >
                          {supplyPlanID === undefined
                            ? helpers.formatNumber(baseProduct.price)
                            : `${helpers.formatNumber(
                              baseProduct.pricePerMonth
                            )}/mo`}
                        </Typography>
                      )}

                      <Typography className={clsx(sharedClasses.caption, classes.priceCaption)}>
                        {subscriptionHelpers.subscriptionInfo(supplyPlanID).message}
                      </Typography>

                      {currentSubscription ? (
                        <>
                          {supplyPlanID !== SUBSCRIPTION_DURATIONS.MONTHLY && <Button
                            className={sharedClasses.rebrandedPrimaryButton}
                            onClick={handleSaveChanges}
                            disabled={!isReportDelivered || supplyPlanID === currentSubscription?.duration || boostersInCart || isUpdating || (currentSubscription && currentSubscription.isInstallmentProduct)}
                            endIcon={isUpdating && <CircularProgress size={18} color='inherit' />}
                          >
                            Save Changes
                          </Button>}
                          <div className={classes.responsiveWrapper}>
                            {!currentSubscription.isInstallmentProduct &&
                              <Typography className={classes.linkText} style={{ marginRight: 10 }} onClick={handleOpenPauseDialog}>
                                Pause Subscription
                              </Typography>
                            }
                            <Typography className={classes.linkText} onClick={() => { currentSubscription && currentSubscription.isInstallmentProduct ? handleOpenCancelDialog() : onCancelSubscriptionClicked() }}>
                              Cancel Subscription
                            </Typography>
                          </div>
                        </>
                      ) : (
                        productInCart()
                          ? (
                            <Button
                              className={sharedClasses.rebrandedSuccessButton}
                              onClick={() => removeFromCartClicked(selectedSubscriptionLineItem)}
                            >
                              ADDED TO CART
                              <FontAwesomeIcon
                                icon={faCheck}
                                className={classes.icon}
                                style={{ marginLeft: 8, color: colors.green_05 }}
                              />
                            </Button>
                          )
                          : (
                            <>
                              <Button
                                className={sharedClasses.rebrandedPrimaryButton}
                                onClick={
                                  questionnaire && questionnaire.questionnaireComplete ? () => addToCartHandled(baseProduct) : openQuestionnaireDialog
                                }
                                disabled={!isReportDelivered}
                              >
                                ADD TO CART
                              </Button>
                              <Grid container alignItems="center" className={classes.truemedMargin} spacing={1}>
                                <Grid item>
                                  <Typography>
                                    HSA/FSA eligible with
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <img
                                    className={classes.truemedLogo}
                                    src={images.truemedLogo}
                                    alt="trueMed"
                                  />
                                </Grid>
                                <Grid item>
                                <Button
                                  className={classes.primaryButton}
                                  component="a"
                                  href="https://www.genopalate.com/pages/supplements-fsa-hsa-eligible?coback=1"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Learn more
                                  <ArrowForward fontSize="small" className={classes.arrowIcon} />
                                </Button>
                                </Grid>
                              </Grid>
                            </>
                          )
                      )}
                      {!isReportDelivered && (
                        <Typography className={classes.addToCartDisabledText}>
                          Your DNA must be processed before purchasing a supplement subscription.
                        </Typography>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <hr className={classes.horizontalRule} />
              </Grid>

              <Grid item xs={12}>
                <hr className={classes.horizontalRule} />
              </Grid>
              <Grid item xs={12}>
                <CustomFormula formulation={formulations} boosterFormulas={[]} isExampleFormula={!isReportDelivered} />
              </Grid>
              <Grid item xs={12}>
                <Typography className={sharedClasses.h5} style={{ color: colors.blue_05, marginBottom: 8 }}>
                  {currentSubscription ? 'Edit Your Boosters' : 'Select Boosters'}
                </Typography>
                <Typography className={sharedClasses.h7}>
                  Each of these boosters will add ingredients to your custom formula, effective on your next order. Limit {boosters.length}
                </Typography>
                <hr className={classes.horizontalRule} />
                <Grid container spacing={2}>
                  {
                    boosters.map(booster => (
                      <Grid item xs={12} md={6} lg={4} key={booster.name}>
                        <Booster
                          name={booster.name}
                          price={booster.formulation[0].boosterProductPrice}
                          formulation={booster.formulation}
                          selected={boosterIsSelected(booster.name)}
                          disabled={!productInCart() && !currentSubscription}
                          awaitingResults={!isReportDelivered}
                          addToCartClicked={currentSubscription ? () => handleAddBoosterForUpdate(booster) : () => onAddBoosterToCartClicked(booster.name)}
                          removeFromCartClicked={currentSubscription ? () => handleRemoveBoosterForUpdate(booster) : () => onRemoveBoosterFromCartClicked(booster.name)}
                          addBaseSubscriptionClicked={questionnaire && questionnaire.questionnaireComplete ? () => addToCartHandled(baseProduct) : openQuestionnaireDialog}
                          loading={boostersInCartFetching || spinnerOpen} // if booster UI or cart is loading
                          currentSubscription={currentSubscription}
                        />
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className={sharedClasses.caption} style={{ marginTop: 15 }}>*These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.</Typography>
              </Grid>
              {
                (!isSubscriptionUpdated || !!productInCart()) && (
                  <Grid item xs={12}>
                    <div className={classes.checkoutWrapper}>
                      {productInCart() ?
                        <Typography className={sharedClasses.h5} style={{ marginBottom: 40 }}>
                          Continue to Checkout
                        </Typography>
                        : null}
                      {currentSubscription && (
                        <Typography className={sharedClasses.h6} style={{ color: colors.gray_03, marginBottom: 8, textDecorationLine: 'line-through' }}>
                          {`was ${helpers.formatNumber(currentSubscriptionProduct.pricePerMonth)}/mo`}
                        </Typography>
                      )}
                      {selectedSubscriptionLineItem && (
                        <Typography className={sharedClasses.h6} style={{ color: colors.green_05, marginBottom: 24 }}>
                          {/* supplyPlanID - will be undefined for onetime products */}
                          {
                            supplyPlanID === undefined
                              ? `will be ${helpers.formatNumber(selectedSubscriptionProduct.price)}`
                              : `will be ${helpers.formatNumber(selectedSubscriptionPricePerMonth)}/mo`
                          }
                        </Typography>
                      )}
                      <Button
                        className={sharedClasses.rebrandedPrimaryButton}
                        style={{ alignSelf: 'inherit' }}
                        onClick={!isSubscriptionUpdated ? () => handleSaveChanges() : () => openCart()}
                        disabled={isUpdating}
                        endIcon={isUpdating && <CircularProgress size={18} color='inherit' />}
                      >
                        {!isSubscriptionUpdated ? 'Save Changes' : 'VIEW CART'}
                      </Button>
                    </div>
                  </Grid>
                )
              }
              <AdditionalProducts />
              {showAlert && (
                <AccountAlert
                  title={alertTitle}
                  message={alertMessage}
                  type={alertType}
                  onClose={() => handleCloseAlert()}
                />
              )}
              <CancelSubscriptionDialog
                open={showCancelDialog}
                handleClose={handleCloseCancelDialog}
                handleCancel={handleCancelSubscription}
                success={cancelSubscriptionFetching === false && !!cancelSubscriptionSuccess}
                handleCloseSuccess={handleCloseDialogAfterCancellation}
              />
              <PauseSubscriptionDialog
                open={showPauseDialog}
                handleClose={handleClosePauseDialog}
                handleCancel={handleOpenCancelSubscriptionFromPause}
                handlePause={handlePauseSubscription}
                success={pauseSubscriptionFetching === false && !!pauseSubscriptionSuccess}
                renewalDate={nextOrderDate}
                isCanceling={isCanceling}
              />
              <QuestionnaireDialog
                open={showQuestionnaireDialog}
                handleClose={handleCloseQuestionnaireDialog}
                handleSave={handleSaveQuestionnaire}
              />
              <CustomDialog
                open={showUpdateDialog}
                title='Changes Saved!'
                content='The updates to your subscription have been saved and will take effect on your next renewal date.'
                handleClose={handleCloseUpdateDialog}
                handleContinue={handleCloseUpdateDialog}
              />
            </React.Fragment>
          ) : <Loading />
        }
      </Grid>
    </div>
  );
};

export default SupplementSubscriptionPage;

SupplementSubscriptionPage.propTypes = {
  classes: PropTypes.shape({
    pageWrapper: PropTypes.string.isRequired,
    backLink: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    backLinkText: PropTypes.string.isRequired,
    productImage: PropTypes.string.isRequired,
    subscriptionImage: PropTypes.string.isRequired,
    productOuterWrapper: PropTypes.string.isRequired,
    responsiveWrapper: PropTypes.string.isRequired,
    rowWrapper: PropTypes.string.isRequired,
    productDetailsWrapper: PropTypes.string.isRequired,
    subscriptionTitle: PropTypes.string.isRequired,
    subscriptionDescription: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    subscriptionPrice: PropTypes.string.isRequired,
    iconTextWrapper: PropTypes.string.isRequired,
    iconText: PropTypes.string.isRequired,
    formulaHeader: PropTypes.string.isRequired,
    formulaWrapper: PropTypes.string.isRequired,
    horizontalRule: PropTypes.string.isRequired,
    subscriptionCol: PropTypes.string.isRequired,
    subscriptionDivider: PropTypes.string.isRequired,
    checkoutWrapper: PropTypes.string.isRequired,
    additionalProductWrapper: PropTypes.string.isRequired,
    priceCaption: PropTypes.string.isRequired,
  }).isRequired,
};
