import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "common/assets/sharedUI";

const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      marginTop: "70px",
    },
    subtitle: {
      fontWeight: 800,
    },
    line: {
      backgroundColor: colors.gray_07,
      height: "3px"
    },
    recommendationSubtitle: {
      fontWeight: 800,
      marginTop: "76px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
      },
    },
    icon: {
      width: 44,
      height: 50,
      marginRight: "26px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
      },
    },
    recommendationTitle: {
      fontWeight: 800,
      fontSize: "28px",
      color: colors.darkestGrey,
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    recommendationTitleContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    subtitleDescription: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    button: {
      marginTop: 20,
      backgroundColor: colors.white,
      color: colors.green_07,
      border: "2px solid #385C0D",
      borderRadius: "3px",
      opacity: 1,
      padding: "2px 8px",
      height: "unset",
      textTransform: "none",
      letterSpacing: "0px",
      color: colors.green_07
    },
    mb_9: {
      marginBottom: "72px",
    },
    benefitSection: {
      marginTop: "24px",
    },
    nonImpactMessage: {
      marginTop: "10px"
    }
  })
);

export default useStyles;
