import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import {
  faCalendarClock,
  faCreditCard,
  faLocationDot,
  faTruck,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { subscriptionHelpers } from 'utils';
import { SUBSCRIPTION_DURATIONS } from 'utils/subscriptionHelpers';
import { ProductPlans } from 'components/shared';
import { useUserCurrentSubscription } from 'hooks';

const ManageSubscription = ({
  classes,
  supplyPlanID,
  onClickPlan,
  disabled,
  currentSubscriptionProduct
}) => {
  const { currentSubscriptionV2: currentSubscription, shippingData, paymentMethod } = useUserCurrentSubscription('GenoVit');

  const { data: allSubscriptionProducts } = useSelector((state) => state.additionalProducts.subscriptionProductsV2);

  const nextDeliveryDate = useMemo(() => (currentSubscription ? new Date(currentSubscription.nextDeliveryDate) : null), [currentSubscription]);
  const nextOrderDate = useMemo(() => (currentSubscription ? new Date(currentSubscription.nextOrderDate) : null), [currentSubscription]);
  const shipCity = useMemo(() => (get(shippingData, 'address.shipCity', '')), [shippingData]);
  const shipState = useMemo(() => (get(shippingData, 'address.shipState', '')), [shippingData]);
  const last4 = useMemo(() => (get(paymentMethod, 'paymentDetails.last4', '')), [paymentMethod]);

  return (
    <>
      <div className={clsx(classes.rowWrapper, classes.iconsWrapper)}>
        <div className={classes.subscriptionCol}>
          <div className={classes.iconTextWrapper}>
            <FontAwesomeIcon icon={faTruck} className={classes.icon} />
            <p className={classes.iconText}>
              Next Delivery by{' '}
              {nextDeliveryDate.toLocaleDateString('en-US', {
                day: '2-digit',
                month: 'numeric',
              })}
            </p>
          </div>
          <div className={classes.iconTextWrapper}>
            <FontAwesomeIcon icon={faLocationDot} className={classes.icon} />
            <p className={classes.iconText}>{`To ${shipCity}, ${shipState}`}</p>
          </div>
          <Link to='/account'>
            <Typography className={classes.linkText}>
              Update Your Shipping Address
            </Typography>
          </Link>
        </div>
        <div
          className={clsx(classes.subscriptionCol, classes.subscriptionDivider)}
        >
          <div className={classes.iconTextWrapper}>
            <FontAwesomeIcon icon={faCalendarClock} className={classes.icon} />
            <p className={classes.iconText}>
              Renews{' '}
              {nextOrderDate.toLocaleDateString('en-US', {
                day: '2-digit',
                month: 'numeric',
              })}
            </p>
          </div>
          <div className={classes.iconTextWrapper}>
            <FontAwesomeIcon icon={faCreditCard} className={classes.icon} />
            <p className={classes.iconText}>{`Card ending in ${last4}`}</p>
          </div>
          <Link to='/account'>
            <Typography className={classes.linkText}>
              Update Your Payment Method
            </Typography>
          </Link>
        </div>
      </div>

      {/* Product details */}
      <div className={classes.productDetailsWrapper}>
        <Typography className={classes.subscriptionTitle}>
          {currentSubscription.subscriptionName}
        </Typography>

        <Typography className={classes.subscriptionDescription}>{currentSubscriptionProduct.descriptionHtml}</Typography>

        {/* OPTIONS */}
        <ProductPlans
          plans={subscriptionHelpers.getSubscriptionPlans(
            allSubscriptionProducts,
          )}
          activeSupplyPlan={
            supplyPlanID === SUBSCRIPTION_DURATIONS.MONTHLY
              ? SUBSCRIPTION_DURATIONS.THREE
              : supplyPlanID
          }
          activeSubscription={Boolean(currentSubscription)}
          onClickPlan={(plan) => onClickPlan(plan.subscriptionDuration)}
          disabled={disabled}
          isMonthly={supplyPlanID === SUBSCRIPTION_DURATIONS.MONTHLY}
        />
        {/* OPTIONS */}
      </div>
    </>
  );
};
ManageSubscription.propTypes = {
  classes: PropTypes.shape({
    rowWrapper: PropTypes.string,
    iconsWrapper: PropTypes.string,
    subscriptionCol: PropTypes.string,
    iconTextWrapper: PropTypes.string,
    icon: PropTypes.string,
    iconText: PropTypes.string,
    linkText: PropTypes.string,
    subscriptionDivider: PropTypes.string,
  }),
  supplyPlanID: PropTypes.number,
  onClickPlan: PropTypes.func,
  disabled: PropTypes.bool,
  currentSubscriptionProduct: PropTypes.object
};

export default ManageSubscription;
