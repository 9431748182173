import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const maxWidth = 120;

const useStyles = makeStyles({
  container: {
    width: '100%',
    alignItems: 'center',
    display: "flex",
    marginTop: '16px',
  },
  icon: {
    width: '71px',
    height: "66px",
    // backgroundColor: colors.green_05,
    maxWidth,
    marginRight: '26px',
    padding: "4px"
  },
  label: {
    fontSize: "18px"
  }
});

export default useStyles;
