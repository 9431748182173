import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { images } from 'resources';
import { connect } from 'react-redux';
import {
  createAccount, getRegistrationDetails,
} from 'store/actions/registration';
import * as QueryString from 'query-string';
import { colors } from 'common/assets/sharedUI';
import StatusTrackerV2 from '../../components/StatusTrackerV2';
import { Link } from 'react-router-dom';
import  RegisterationForm  from  '../../components/RegisterFormForSequencingUser'


const RegisterPageForSequencingUser = ({
  classes,
  fetchingOrderDetailsError,
  isFetchingOrderDetails,
  orderDetails,
  location, 
  getRegistrationDetails: dispatchGetRegistrationDetails,
  createAccount: dispatchCreateAccount, 
  history ,
  accountCreationError
}) => {

  const [productCode, setProductCode] = useState('');
const [orderNo, setOrderNo] = useState('');
const [jobId, setJobId] = useState('');
const [isNewDnaKitFlow, setIsNewDnaKitFlow ] = useState(true);

useEffect(() => {
    if (location && location.search) {
      const { token, userProductCode, orderNo, jobId } = QueryString.parse(location.search);
      if (token) {
        dispatchGetRegistrationDetails(token);
      }

      if (userProductCode) {
       setProductCode(userProductCode);
      }
      if (orderNo) {
        setOrderNo(orderNo);
      }
      if( orderDetails && orderDetails.orderNumber  && orderDetails.productCode){ 
        setIsNewDnaKitFlow(false);
      }
      if (jobId) {
        setJobId(jobId);
    }
    }
  }, [location]);
 

 const onSubmit = (values)  => {
    const body = {
      email: values.email.trim(),
      password: values.password.trim(),
      name: values.firstName.trim(),
      surname: values.lastName.trim(),
      userAgreement: values.tos,
      jobId: values.jobId.trim(),
      userProductCode: values.productCode.trim(),
      orderNumber: values.orderNumber.trim(),
    };
    dispatchCreateAccount(body, history);
  }

    return (
      <React.Fragment>
        <img src={images.logoNoTagline} alt="GenoPalate logo" className={classes.logo} />
        {/* {orderDetails && <StatusTrackerV2 productType={orderDetails.isKit ? 'kit' : 'edna'} activeStep={1} isSequencingUser />} */}
        <Grid container className={classes.registerPage}>

          <Grid item xs={12} className={classes.signInText}>
            <h2 className={classes.headerText}>Register Your Account</h2>
            New to GenoPalate?&nbsp;
            <a href="https://genopalate.com"><b>Buy now</b></a>
            
          </Grid>
          {fetchingOrderDetailsError && (
            <Grid item xs={12} className={classes.errorText}>
              We’re sorry, your authentication token has expired. Please check your email for a link to create your account. Thank you!
            </Grid>
          )}
          {!isFetchingOrderDetails && 
          <RegisterationForm
          onSubmit={onSubmit}
          classes={classes}
          fetchingOrderDetailsError={fetchingOrderDetailsError}
          isFetchingOrderDetails={isFetchingOrderDetails}
          orderDetails={orderDetails}
          productCode={productCode}
          orderNo={orderNo}
          jobId={jobId}
          isNewDnaKitFlow={isNewDnaKitFlow}
          accountCreationError={accountCreationError}
          /> }
        </Grid>
      </React.Fragment>
    );
  
}

RegisterPageForSequencingUser.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({
    passwordReq: PropTypes.string,
    registerPage: PropTypes.string,
    tosAndPPWrapper: PropTypes.string,
    createAccountButton: PropTypes.string,
    centerGrid: PropTypes.string,
    logo: PropTypes.string,
    signInText: PropTypes.string,
    headerText: PropTypes.string,
    alreadyHaveAccountText: PropTypes.string,
    errorText: PropTypes.string,
  }).isRequired,
  createAccount: PropTypes.func.isRequired,
  getRegistrationDetails: PropTypes.func.isRequired,
  isCreatingAccount: PropTypes.bool.isRequired,
  accountCreationError: PropTypes.string.isRequired,
  isFetchingOrderDetails: PropTypes.bool.isRequired,
  fetchingOrderDetailsError: PropTypes.string.isRequired,
  orderDetails: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    productCode: PropTypes.string.isRequired,
    orderNumber: PropTypes.string.isRequired,
    isKit: PropTypes.bool.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.any.isRequired,
};

RegisterPageForSequencingUser.defaultProps = {
  orderDetails: null,
};

const styles = theme => ({
  registerPage: {
    color: '#282c36',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    maxWidth: 400,
    margin: 'auto',
  },
  passwordReq: {
    margin: '0 20px',
  },
  tosAndPPWrapper: {
    display: 'inline-block',
    alignItems: 'center',
    '& a': {
      fontWeight: 'bold',
      color: '#095d7c',
    },
  },
  createAccountButton: {
    width: '100%',
    alignSelf: 'center',
    backgroundColor: colors.blue_04,
    color: 'white',
    fontSize: '16px',
    boxShadow: 'none',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: colors.blue_05,
      boxShadow: 'none',
    },
  },
  centerGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  logo: {
    width: 120,
    margin: 'auto',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  signInText: {
    fontSize: 14,
    textAlign: 'center',
    color: colors.gray_05,
  },
  alreadyHaveAccountText: {
    fontSize: 14,
    textAlign: 'center',
    color: colors.gray_05,
    marginTop: 15,
  },
  errorText: {
    padding: 4,
    marginTop: 10,
    borderStyle: 'solid',
    borderRadius: 4,
    backgroundColor: 'rgba(180,0,0,.15)',
    color: colors.red,
    textAlign: 'center',
  },
  headerText: {
    fontSize: 24,
    color: colors.blue_03,
    fontWeight: 300,
    textAlign: 'center',
    margin: 0,
    paddingTop: 10,
  },

  registerDNAKitText: {
    fontSize: 24,
    color: colors.blue_03,
    fontWeight: 300,
    textAlign: 'center',
    margin: 0,
    paddingTop: 10,
  }
});

export default connect(
  (state) => state.registration,
  {
    createAccount,
    getRegistrationDetails,
  },
)(withStyles(styles)(RegisterPageForSequencingUser));

