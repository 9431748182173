import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@material-ui/core';

const ProductPrice = ({
  actualPrice,
  savingPrice = null,
  actualPriceClasses,
  savingPriceClasses,
}) => {
  return (
    <Box display='flex' alignItems='center'>
      <Box mr={1}>
        {savingPrice ? (
          <Typography
            variant='h6'
            color='textSecondary'
            style={{ textDecoration: 'line-through' }}
          >
            {actualPrice}
          </Typography>
        ) : (
          <Typography className={actualPriceClasses}>{actualPrice}</Typography>
        )}
      </Box>
      {savingPrice && (
        <Box>
          <Typography variant='h5' className={savingPriceClasses}>
            {savingPrice}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default ProductPrice;

ProductPrice.propTypes = {
  actualPrice: PropTypes.number.isRequired,
  savingPrice: PropTypes.number,
  actualPriceClasses: PropTypes.string,
  savingPriceClasses: PropTypes.string,
};
