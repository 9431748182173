import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Typography,
  Container,
  Divider,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
  Hidden,
} from '@material-ui/core';
import useStyles from './CustomHeader.styles';
import { apiStatuses, nutrientsSubheading } from '../../../resources/constants';
import { useSelector, shallowEqual } from 'react-redux';

function CustomHeader({
  header,
  subHeading,
  link,
  icon,
  linkText,
  iconStyles,
  bannerStyles,
  headingStyles,
  subheadingStyles,
  linkStyles,
  hasImage,
  isLink = true,
  extraStyles = {},
  onClickLink = () => {},
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const statusLoading = useSelector(
    (state) => state.status.status,
    shallowEqual
  );

  const bannerClass = useMemo(() => {
    return `${classes.banner} ${
      !isSmallScreen && hasImage ? classes.bannerWithBackground : ''
    } ${bannerStyles}`;
  }, [classes.banner, isSmallScreen, hasImage, bannerStyles]);

  const headerHeadingClass = useMemo(
    () => `${classes.headerHeading} ${headingStyles}`,
    [classes.headerHeading, headingStyles]
  );
  const headerSubheadingClass = useMemo(
    () => `${classes.headerSubheading} ${subheadingStyles}`,
    [classes.headerSubheading, subheadingStyles]
  );
  const headerLinkClass = useMemo(
    () => `${classes.headerLink} ${linkStyles}`,
    [classes.headerLink, linkStyles]
  );
  const iconClass = useMemo(
    () => `${classes.icon} ${iconStyles}`,
    [classes.icon, iconStyles]
  );

  if (
    statusLoading === apiStatuses.PENDING &&
    subHeading !== nutrientsSubheading
  )
    return null;

  return (
    <>
      <div className={bannerClass}>
        <Container className={extraStyles}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
              <Typography variant='h1' className={headerHeadingClass}>{header}</Typography>
              <Typography className={headerSubheadingClass}>
                {subHeading}
              </Typography>
              {isLink ? (
                <Link className={headerLinkClass} href={link} onClick={onClickLink} target='_blank'>
                  {linkText}
                </Link>
              ) : (
                <Typography className={headerLinkClass}>{linkText}</Typography>
              )}
            </Grid>
            <Hidden xsDown>
              <Grid item sm={2}>
                <FontAwesomeIcon icon={icon} className={iconClass} />
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </div>
      <Divider variant='fullWidth' className={classes.divider} />
    </>
  );
}

CustomHeader.propTypes = {
  classes: PropTypes.shape({
    header: PropTypes.string,
    headerSubheading: PropTypes.string,
    headerLink: PropTypes.string,
    icon: PropTypes.string,
    divider: PropTypes.string,
  }),
  header: PropTypes.string,
  subHeading: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  statusLoading: PropTypes.string,
  bannerStyles: PropTypes.string,
  headingStyles: PropTypes.string,
  subheadingStyles: PropTypes.string,
  linkStyles: PropTypes.string,
  iconStyles: PropTypes.string,
  hasImage: PropTypes.bool,
  isLink: PropTypes.bool,
  onClickLink: PropTypes.func,
};

export default CustomHeader;
