import { combineReducers } from 'redux';
import basicReducer from './basicReducer';
import types from '../actionTypes';

const cartInitialState = {
  open: false,
  spinnerOpen: false,
  errorOpen: false,
  errorMessage: '',
  cart: null,
};

const cartReducer = (state = cartInitialState, action) => {
  switch (action.type) {
    case types.UPDATE_CART:
      return {
        ...state,
        cart: action.cart,
      };
    case types.OPEN_CART:
      return {
        ...state,
        open: true,
      };
    case types.CLOSE_CART:
      return {
        ...state,
        open: false,
      };
    case types.OPEN_CART_SPINNER:
      return {
        ...state,
        spinnerOpen: true,
        errorOpen: false,
      };
    case types.CLOSE_CART_SPINNER:
      return {
        ...state,
        spinnerOpen: false,
      };
    case types.OPEN_CART_ERROR:
      return {
        ...state,
        spinnerOpen: false,
        errorOpen: true,
        errorMessage: action.message,
      };
    case types.CLOSE_CART_ERROR:
      return {
        ...state,
        errorOpen: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  products: basicReducer(types.GET_ADDITIONAL_PRODUCTS_STARTED, types.GET_ADDITIONAL_PRODUCTS_SUCCESS, types.GET_ADDITIONAL_PRODUCTS_ERROR),
  purchasedProducts: basicReducer(types.GET_PURCHASED_PRODUCTS_STARTED, types.GET_PURCHASED_PRODUCTS_SUCCESS, types.GET_PURCHASED_PRODUCTS_ERROR),
  subscriptionProducts: basicReducer(types.GET_SUBSCRIPTION_PRODUCTS_STARTED, types.GET_SUBSCRIPTION_PRODUCTS_SUCCESS, types.GET_SUBSCRIPTION_PRODUCTS_ERROR),
  subscriptionProductsV2: basicReducer(types.GET_SUBSCRIPTION_PRODUCTS_STARTED_V2, types.GET_SUBSCRIPTION_PRODUCTS_SUCCESS_V2, types.GET_SUBSCRIPTION_PRODUCTS_ERROR_V2),
  subscriptionGenoBlendProducts: basicReducer(types.GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_STARTED, types.GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_SUCCESS, types.GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_ERROR),
  userGenoBlendType: basicReducer(types.GET_USER_BLEND_STARTED, types.GET_USER_BLEND_SUCCESS, types.GET_USER_BLEND_ERROR),
  initCart: basicReducer(types.INIT_CART_STARTED, types.INIT_CART_SUCCESS, types.INIT_CART_ERROR),
  cart: cartReducer,
  boostersInCart: basicReducer(types.SET_BOOSTERS_IN_CART_STARTED, types.SET_BOOSTERS_IN_CART_SUCCESS, types.SET_BOOSTERS_IN_CART_ERROR),
  boosterFormulations: basicReducer(types.GET_BOOSTER_FORMULATIONS_STARTED, types.GET_BOOSTER_FORMULATIONS_SUCCESS, types.GET_BOOSTER_FORMULATIONS_ERROR),
});
