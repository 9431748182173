import { makeStyles } from "@material-ui/core/styles";
import { colors } from "common/assets/sharedUI";
import { fonts } from "../../../common/assets/sharedUI";

const useStyles = makeStyles((theme) => ({
  referenceSection: {
    marginTop: "70px",
    paddingTop: "60px",
    paddingBottom: "60px",
    width: "100%",
    backgroundColor: colors.gray_01,
  },
  listItems: {
    display: "flex",
    paddingBottom: '10px',
    flexWrap: "wrap", // Allow wrapping on small screens
  },

  bulletWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap", // Keep numbering and text on the same line but allow wrapping
    width: "100%",
  },

  listItemText: {
    fontSize: "22px",
    color: colors.lightBlue,
    paddingLeft: '5px',
    textAlign: "justify",
    fontFamily: fonts.Swiss721MD,
    lineHeight: 1.25,
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    flex: 1, // Allow the link text to take up remaining space
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
    },
  },

  bullets: {
    fontSize: "22px",
    fontFamily: fonts.Swiss721MD,
    marginRight: "4px",
    whiteSpace: "nowrap", // Prevent the numbering from breaking to a new line
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
    },
  },

  lightBlue: {
    color: colors.lightBlue,
  },
  title: {
    fontFamily: fonts.BaskervilleBold
  }
}));

export default useStyles;
