import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, Typography, Button, Dialog, DialogContent, DialogTitle, CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrophy,
  faEye,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCog,
} from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'common/assets/sharedUI';
import IndividualNutrients from 'components/IndividualNutrients';
import { setFoodVisibility } from 'store/actions/topFoods';
import helpers from 'utils/helpers';
import styles from './FoodDetailView.styles';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import FoodScore from './FoodScore';

const FoodDetailView = (props) => {
  const {
    id, classes, foodScore, prevUrl, nextUrl, prevId, nextId, setFoodId, foodGroup, trophy,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const foodDetails = useSelector(state => state.food.details);

  useEffect(() => {
    if (!foodGroup || !foodGroup.foods) history.push('/food');
    else {
      const currentFood = foodGroup.foods.find(element => element.id == id);
      if (currentFood.individuallyHidden || currentFood.missingDietaryPreferences.length > 0) history.push('/food');
    }
  }, [foodGroup, id, history]);

  const onArrowClick = (e, route, targetId, foodName, arrowDirection) => {
    e.preventDefault();
    registerGAClickEventEnhanced(
      EventCategories.IndividualFoodPage,
      arrowDirection === 'Left' ? EventActions.LeftArrow : EventActions.RightArrow,
      EventLabels.Food(foodName),
    );
    setFoodId(targetId);
    history.push(route);
  };

  if (foodScore === undefined || foodScore === null || !foodGroup) return null;

  const foodName = (foodScore[0].food.name !== undefined ? foodScore[0].food.name : '');

  const onToggle = useCallback(
    async () => {
      registerGAClickEventEnhanced(EventCategories.FoodTools, EventActions.Confirm, EventLabels.Remove);
      handleClose();
      setLoading(true);
      dispatch(setFoodVisibility(id, true)); // the button only hides food, never makes it visible
      history.push('/food');
    },
    [handleClose, setLoading, id, history, dispatch, foodName],
  );

  const handleOpen = useCallback(
    () => {
      registerGAClickEventEnhanced(EventCategories.FoodTools, EventActions.Hide, EventLabels.Food(foodName));
      setOpen(true);
    },
    [setOpen, foodName],
  );

  const handleClose = useCallback(
    (type) => {
      registerGAClickEventEnhanced(EventCategories.FoodTools, EventActions[type], EventLabels[type]);
      setOpen(false);
    },
    [setOpen, foodName],
  );

  const shownFoods = foodGroup.foods.filter(food => !food.individuallyHidden && food.missingDietaryPreferences.length === 0);
  const shownFoodsCount = shownFoods.length;
  const currentFoodIndex = parseInt(shownFoods.findIndex(food => food.id == id), 10) + 1;
  const foodDetailsData = foodDetails.data;

  const onNutrientClick = useCallback((nutrientName, type, id) => {
    registerGAClickEventEnhanced(
      EventCategories.FoodSentence,
      EventActions.Open,
      EventLabels.Nutrient(nutrientName),
    );
    history.push({
      pathname: `/nutrients/${ type.toLowerCase() }/${ id}`,
      state: { selected: type.toLowerCase() },
    });
  }, [history]);

  const getSentence = useCallback((sentence, nutrients) => {
    let updatedSentence = sentence;
    nutrients.forEach(nutrient => {
      updatedSentence = helpers.reactStringReplace(updatedSentence, nutrient.tag, () => (
        <span className={classes.link} onClick={() => onNutrientClick(nutrient.name, nutrient.type, nutrient.traitId)}>{nutrient.name}</span>
      ));
    });
    return updatedSentence;
  }, [classes.link, onNutrientClick]);

  const getFoodDescription = useCallback(() => {
    const { sourceDescriptions } = foodDetailsData;
    const excellent = getSentence(sourceDescriptions.excellent, sourceDescriptions.excellentNutrients);
    const good = getSentence(sourceDescriptions.good, sourceDescriptions.goodNutrients);
    const low = getSentence(sourceDescriptions.low, sourceDescriptions.lowNutrients);
    return (
      <Typography>
        {excellent}
        {' '}
        {good}
        {' '}
        {low}
      </Typography>
    );
  }, [foodDetailsData, getSentence]);

  const portionLabel = `Per ${foodDetailsData.portionAmount} ${foodDetailsData.portionUnit}`
  const showBeneficialNutrients = foodDetailsData.beneficialNutrients && foodDetailsData.beneficialNutrients.length > 0

  return (
    <Grid container>

      <Grid item xs={4} md={1} className={classes.foodImageContainer}>
        <img alt={foodName} src={`https://geno-icons.s3.amazonaws.com/${foodScore[0].food.fileName}`} className={classes.foodImage} />
      </Grid>

      <Grid item container xs={8} md={6}>
        <div className={classes.foodTitleContainer}>
            <div name="trophyContainer" className={classes.foodTrophyContainer}>
              <Typography variant="subtitle1" style={{ fontSize: '1rem', marginRight: 5 }}>Score:</Typography>
              <FoodScore score={`${Math.round(foodDetailsData.foodScore)}`} scoreStyle={{ fontSize: '1rem' }} />
            </div>
          <Typography variant="h4" className={classes.foodName}>{foodName}</Typography>
        </div>
      </Grid>
      <Grid item container xs={12} md={5}>
        <div className={classes.categoryContainer}>
          <Button className={classes.arrowButton} onClick={e => onArrowClick(e, prevUrl, prevId, foodName, 'Left')} disabled={currentFoodIndex === 1}>
            <ArrowBackIcon className={classes.arrowIcon} />
          </Button>
          <Typography className={classes.foodCategory}>
            <span>
              (
              {currentFoodIndex}
              {' '}
              of
              {' '}
              {shownFoodsCount}
              )
            </span>
            {' '}
            {foodGroup.name}
          </Typography>
          <Button className={classes.arrowButton} onClick={e => onArrowClick(e, nextUrl, nextId, foodName, 'Right')} disabled={currentFoodIndex === shownFoodsCount}>
            <ArrowForwardIcon className={classes.arrowIcon} />
          </Button>

          <Button className={classes.hideButton} onClick={handleOpen}>
            <div>
              <FontAwesomeIcon icon={faEye} className={classes.eyeIcon} />
              Hide
            </div>
          </Button>
        </div>
      </Grid>
      <Grid item container xs={12} className={classes.individualNutrientDetailsWrapper}>
        <Grid xs={12} md={7} lg={8} item className={classes.individualNutrientDetails}>
          {getFoodDescription()}
          <Typography name="descriptionText">Explore the nutrients above to understand how your recommendations are influenced by your genes.</Typography>
        </Grid>
        <Grid xs={12} md={5} lg={4} item>
          <div className={classes.nutrientValuesTables}>
            <Typography variant="h6" className={classes.beneficialNutrients}>Your Beneficial Nutrients</Typography>
            { showBeneficialNutrients
              ? (
                <IndividualNutrients
                  portionLabel={portionLabel}
                  data={foodDetailsData.beneficialNutrients}
                />
              )
              : (
                <Typography name="descriptionText">You have no beneficial nutrients for this food.</Typography>
              )}
            <Typography 
              variant="h6" 
              className={classes.energyNeeds}
            >Energy</Typography>
            <IndividualNutrients
              portionLabel={ !showBeneficialNutrients && portionLabel } 
              data={foodDetailsData.energyNutrients} 
            />
            <Typography variant="h6" className={classes.otherVitaminsMinerals}>Other Vitamins &amp; Minerals</Typography>
            <IndividualNutrients data={foodDetailsData.otherNutrients} />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => handleClose('X')}
        style={{ zIndex: 1302 }}
        aria-labelledby="are-you-sure-modal"
        aria-describedby="are-you-sure-you-want-to-hide-this-food"
        maxWidth="xs"
      >
        <DialogContent>
          <FontAwesomeIcon icon={faTimes} className={classes.exitIcon} onClick={() => handleClose('X')} />
        </DialogContent>
        <DialogTitle>
          Are You Sure?
        </DialogTitle>
        <DialogContent>
          <Typography>
            By hiding
            {' '}
            {foodName}
            {' '}
            you will have one less food in your optimal foods list.
          </Typography>
          <br />
          <Typography>
            <FontAwesomeIcon icon={faCog} className={classes.eyeIcon} />
            You can modify this by clicking the settings icon on the optimal foods page.
          </Typography>
          <br />
          <div className={classes.modalButtonContainer}>
            <Button className={classes.cancelButton} onClick={() => handleClose('Cancel')}>Cancel</Button>
            { loading
              ? <CircularProgress size={24} color={colors.blue_03} />
              : (
                <Button className={classes.removeButton} onClick={onToggle}>
                  Remove
                </Button>
              )}
          </div>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

FoodDetailView.propTypes = {
  id: PropTypes.number.isRequired,
  prevUrl: PropTypes.string.isRequired,
  foodScore: PropTypes.any.isRequired,
  nextUrl: PropTypes.string.isRequired,
  prevId: PropTypes.number.isRequired,
  nextId: PropTypes.number.isRequired,
  setFoodId: PropTypes.func.isRequired,
  foodGroup: PropTypes.object.isRequired,
  trophy: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    checkIcon: PropTypes.string,
    foodCategory: PropTypes.string,
    foodTitleContainer: PropTypes.string,
    otherVitaminsMinerals: PropTypes.string,
    nutrientValuesTables: PropTypes.string,
    individualNutrientDetails: PropTypes.string,
    energyNeeds: PropTypes.string,
    individualNutrientValues: PropTypes.string,
    beneficialNutrients: PropTypes.string,
    cardContainer: PropTypes.string,
    foodContainer: PropTypes.string,
    relativePositioningPlaceholder: PropTypes.string,
    foodTrophyTriangle: PropTypes.string,
    foodTrophyContainer: PropTypes.string,
    foodTrophy: PropTypes.string,
    arrowButton: PropTypes.string,
    arrowIcon: PropTypes.string,
    foodImage: PropTypes.string,
    foodImageContainer: PropTypes.string,
    foodName: PropTypes.string,
    categoryContainer: PropTypes.string,
    category: PropTypes.string,
    hideButton: PropTypes.string,
    detailContainer: PropTypes.string,
    detailTitle: PropTypes.string,
    detailTitle2: PropTypes.string,
    detailNote: PropTypes.string,
    alignCenter: PropTypes.string,
    exitIcon: PropTypes.string,
    eyeIcon: PropTypes.string,
    modalButtonContainer: PropTypes.string,
    cancelButton: PropTypes.string,
    removeButton: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
};

export default withRouter(withStyles(styles)(FoodDetailView));
