import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  Tooltip,
} from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import useStyles from "./VariantDetected.styles";
import PropTypes from "prop-types";

export const ReferenceCell = ({ markers, scrollToReferences, references, isTestedMarker }) => {
  const classes = useStyles();

  const markerreference = Array.from(
    new Set(
      markers?.map((marker) => {
        if (
          marker.reference === null ||
          marker.genotype === "--" ||
          (!isTestedMarker && !marker.variant)
        ) {
          return null;
        }
        const index = references.findIndex((ref) => ref.reference === marker.reference);
        return index !== -1 ? index + 1 : null;
      })
        .filter((index) => index !== null)
    )
  ).sort((a, b) => a - b);

  const referenceCount = markerreference.length;

  return referenceCount > 0 && (
    <>
      <Typography
        className={`${classes.referencesText}`}
      >
        References[
        {markerreference.map((ref, index) => (
          <Typography key={index} className={`${classes.lightBlue}`} onClick={() => { scrollToReferences(ref) }}>
            {ref}{index < markerreference.length - 1 && ', '}
          </Typography>
        ))}
        ]
      </Typography>
    </>
  )
};

const GeneMarkerRow = ({ gene, marker, classes, markerIndex, textColor, scrollToReferences, references, isTestedMarker }) => {
  const generateGpNumber = (marker) => {
    const zeroPaddedId = String(marker.markerId).padStart(6, '0');
    return `GP${zeroPaddedId}`;
  }

  return (
    <>
      <TableCell className={classes.tableCellBase}>
        {markerIndex === 0 && (
          <Box mb={4}>
            <Typography className={classes.tableBody}>Gene</Typography>
            <Typography className={`${classes.geneName}`}>{gene.geneName}</Typography>
          </Box>
        )}

        <Box key={markerIndex}>
          <Typography className={classes.tableBody}>Marker</Typography>
          <Typography className={`${classes.bold} ${classes.darkestGrey}`}>{generateGpNumber(marker)}</Typography>
        </Box>
      </TableCell>

      <TableCell className={classes.tableCellBase}>
        <Typography
          key={markerIndex}
          className={`${textColor} ${classes.genotypeText}`}
        >
          {marker.genotype && marker.genotype !== "--" ? marker.genotype : ""}
        </Typography>
      </TableCell>

      <TableCell className={`${classes.wideColumn} ${classes.tableCellBase}`}>
        <Box key={markerIndex}>
          {marker.genotypeDescription && (
            <Typography className={`${classes.genotypeDescription} ${textColor}`}>
              {marker.genotypeDescription}
            </Typography>
          )}
          <Typography
            className={`${classes.infoText} ${marker.genotypeDescription ? classes.mt_4 : ""
              } ${classes.tableBody}`}
          >
            <Tooltip arrow classes={{ tooltip: classes.tooltip }} title="This percentage shows how many people in the general population have the same genotype as you. Please note that your results are based entirely on your own DNA and are not influenced by anyone else's results or information.">
              <Info className={classes.infoIcon} />
            </Tooltip>
            {Math.round(marker.prevalence)}% of the population shares your
            genotype
          </Typography>
        </Box>
      </TableCell>

      <TableCell className={`${classes.tableCellBase} ${classes.geneDescription}`}>
        {markerIndex === 0 && <Typography className={classes.tableBody}>{gene.geneDescription}</Typography>}
      </TableCell>

      {markerIndex === 0 && (
        <TableCell className={classes.tableCellBase}>
          <ReferenceCell markers={gene.markers}
            scrollToReferences={scrollToReferences}
            references={references}
            isTestedMarker={isTestedMarker}
          />
        </TableCell>
      )}
    </>
  );
};

const VariantDetected = ({ geneDetails, isTestedMarker, key, references }) => {
  const classes = useStyles();

  if (!geneDetails || !geneDetails.genes || geneDetails.genes.length === 0) {
    return <Typography className={classes.tableBody}>Loading...</Typography>;
  }

  const scrollToReferences = (targetElementId) => {
    const targetElement = document.getElementById(`Ref${targetElementId - 2}`);
    if (targetElement) {
      const offset = 0;

      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <TableContainer
      key={key}
      component={Paper}
      className={classes.tableContainer}
    >
      <Table aria-label="Genotype Table" className={classes.tableBody}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>MARKER</TableCell>
            <TableCell className={classes.tableHeader}>GENOTYPE</TableCell>
            <TableCell className={`${classes.tableHeader} ${classes.wideColumn}`}>
              WHAT YOUR GENOTYPE MEANS
            </TableCell>
            <TableCell className={classes.tableHeader}>GENE INFORMATION</TableCell>
            <TableCell className={classes.tableHeader}>RESEARCH</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {geneDetails.genes
            .sort((a, b) => {
              const aHasVariant = a.markers?.some(marker => marker.variant === true);
              const bHasVariant = b.markers?.some(marker => marker.variant === true);

              // Primary sort based on variant condition
              if (aHasVariant && !bHasVariant) return -1;
              if (!aHasVariant && bHasVariant) return 1;

              // Secondary sort by geneName
              if (a.geneName == null && b.geneName != null) return 1;
              if (b.geneName == null && a.geneName != null) return -1;
              if (a.geneName === '--' && b.geneName !== '--') return 1;
              if (b.geneName === '--' && a.geneName !== '--') return -1;
              return a.geneName?.localeCompare(b.geneName);
            }).map((gene, geneIndex) => {
              return gene.markers?.map((marker, markerIndex) => {
                const isVariant = marker.variant;
                if (((!isTestedMarker && !isVariant) || marker.genotype === "--")) {
                  return null;
                }
                const textColor = isVariant ? classes.blue_06 : classes.gray_07;
                const geneVariantLenght = gene.markers
                  ? gene.markers.filter((markerElement) =>
                    markerElement.genotype !== "--" && (isTestedMarker || markerElement.genotype)
                  ).length - 1
                  : 0;
                const rowStyling = markerIndex === geneVariantLenght ? classes.rowBorder : ""
                return (
                  <TableRow
                    key={geneIndex}
                    className={`${classes.tableRowBase} ${rowStyling} ${markerIndex !== 0 ? classes.pt_0 : ""}`}
                  >
                    <GeneMarkerRow
                      marker={marker}
                      gene={gene}
                      textColor={textColor}
                      classes={classes}
                      markerIndex={markerIndex}
                      scrollToReferences={scrollToReferences}
                      references={references}
                      isTestedMarker={isTestedMarker}
                    />
                  </TableRow>
                );
              })
            }
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

VariantDetected.propTypes = {
  classes: PropTypes.shape({
    tableContainer: PropTypes.string.isRequired,
    tableRowBase: PropTypes.string.isRequired,
    tableCellBase: PropTypes.string.isRequired,
    tableHeader: PropTypes.string.isRequired,
    bold: PropTypes.string.isRequired,
    genotypeText: PropTypes.string.isRequired,
    blue_06: PropTypes.string.isRequired,
    gray_07: PropTypes.string.isRequired,
    infoText: PropTypes.string.isRequired,
    referencesText: PropTypes.string.isRequired,
    wideColumn: PropTypes.string.isRequired,
    infoIcon: PropTypes.string.isRequired,
    modalBox: PropTypes.string.isRequired,
    closeButton: PropTypes.string.isRequired,
  }).isRequired,
  geneDetails: PropTypes.shape({
    traitId: PropTypes.number,
    genes: PropTypes.arrayOf(
      PropTypes.shape({
        markers: PropTypes.arrayOf(
          PropTypes.shape({
            healthOutcome: PropTypes.shape({
              name: PropTypes.string,
              description: PropTypes.string,
              icon: PropTypes.string,
            }),
            variant: PropTypes.bool,
          })
        ),
      })
    ),
  }).isRequired,
  isTestedMarker: PropTypes.bool,
};

export default VariantDetected;
