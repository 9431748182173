import React, {useMemo} from 'react'
import { registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels } from 'utils/googleanalytics';
import kebabCase from 'lodash/kebabCase';
import {  Typography, Box} from '@material-ui/core';
import { images } from 'resources';
import useStyles from '../../pages/NutrientPage.styles';
import { useHistory } from 'react-router-dom';
import {UpSellCard} from './UpsellCard'
import { genopalateClickIdKey } from 'services/shopify/utils';
import { internalURLs } from 'resources/constants';
import { useAdditionalProducts } from 'hooks';


export default function NutrientUpsellContainer({heading, type, description}) {
    const classes = useStyles();
    const history = useHistory();

    const { data: products } = useAdditionalProducts();
    const productName = '1-on-1 Consultation with a Registered Dietitian';
    const product = useMemo(() => products && products.find(({ name }) => name === productName), [products?.length]);


  const handleCustomBlendClick = (title) => {
    const params = new URLSearchParams({
      [genopalateClickIdKey]: type === 'food' ? kebabCase(EventLabels.FoodUpSell(title))  : kebabCase(EventLabels.NutrientUpSell(title)) 
    });

    registerGAClickEventEnhanced(
      type === 'food' ? EventCategories.FoodIndex  : EventCategories.Nutrients,
      type === 'food' ? EventActions.FoodIndexGenoBlendCallout  : EventActions.NutrientsGenoBlendCallout,
      type === 'food' ? EventLabels.FoodUpSell(title) : EventLabels.NutrientUpSell(title));
    history.push(`${internalURLs.BLEND_PRODUCT_DETAILS}?${params.toString()}`);
  }

  const handleGenoVitClick = (title) => {
    const params = new URLSearchParams({
      [genopalateClickIdKey]: type === 'food' ? kebabCase(EventLabels.FoodUpSell(title))  : kebabCase(EventLabels.NutrientUpSell(title)) 
    });

    registerGAClickEventEnhanced(
      type === 'food' ? EventCategories.FoodIndex : EventCategories.Nutrients,
      type === 'food' ? EventActions.FoodIndexGenoVitCallout : EventActions.NutrientsGenoVitCallout,
      type === 'food' ? EventLabels.FoodUpSell(title) : EventLabels.NutrientUpSell(title));

    history.push(`${internalURLs.SUPPLEMENT_PRODUCT_DETAILS}?${params.toString()}`);
  }

  const handleConsultClick = (title) => {
    const params = new URLSearchParams({
      [genopalateClickIdKey]: type === 'food' ? kebabCase(EventLabels.FoodUpSell(title))  : kebabCase(EventLabels.NutrientUpSell(title)) 
    });

    registerGAClickEventEnhanced(
      type === 'food' ? EventCategories.FoodIndex : EventCategories.Nutrients,
      type === 'food' ? EventActions.FoodIndexConsult : EventActions.NutrientsConsult,
      type === 'food' ? EventLabels.FoodUpSell(title) : EventLabels.NutrientUpSell(title));
      
    const newUrl = product?.id ? `/account/products/product-details/${product.id}?${params.toString()}` : '/account/products/product-details/not-found'
    history.push(newUrl);
  }

  return (
    <Box className={classes.upSellCard}>
    {/* Up sell section */}

      <Box>
        <Typography variant='h4' className={classes.title}>{heading}</Typography>
          <br /> <br />
        <Typography>{description}</Typography>
      </Box>

      <br /> <br />
      
      <UpSellCard 
        title='Personalized Fiber & Protein' 
        subtitle='Macro Support'
        image={images.genoblendUpsell}
        buttonTitle='See Custom Blend'
        handleClick={handleCustomBlendClick}
      />

      <br />

      
      <UpSellCard 
        title='Personalized Vitamins' 
        subtitle='Micro Support'
        image={images.genovitPouch}
        buttonTitle='See Custom Formula'
        handleClick={handleGenoVitClick}
      />
      
      <br />

      <UpSellCard 
        title='1-on-1 Consultation' 
        subtitle='Dietitian Support'
        image={images.consultations}
        buttonTitle='Sign Up Now'
        handleClick={handleConsultClick}
      />
    
    </Box>
  )
}


