import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import startCase from 'lodash/startCase';
import { Typography, Collapse, Button } from '@material-ui/core';
import { sharedStyles } from 'resources';
import useStyles from './NutrientDescription.styles';
import { registerGAClickEventEnhanced } from '../utils/googleanalytics';
import icons from "resources/icons"

const NutrientDescription = ({ name, description, additionalDescription }) => {
  const sharedClasses = sharedStyles();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (eventName) => {
    registerGAClickEventEnhanced(`Nutrient-${eventName}`, expanded ? 'Close' : 'Open', 'Read more');
    setExpanded(!expanded);
  };

  const paragraphs = additionalDescription.split('$break') || [];

  return (
    <div className={classes.flex}>
      <img
        src={icons.reader}
        className={classes.icon}
        name="aboutIcon"
        alt=""
        />
      <div>
        <Typography
          variant="h6"
          className={classes.title}
          name="about"
        >
          {`About ${name}`}
        </Typography>
        <Typography variant="body1" className={classes.description}>{description}</Typography>
        <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapse}>
          {paragraphs.map(paragraph => <Typography variant="body1" key={paragraph} paragraph>{paragraph}</Typography>)}
        </Collapse>
        <Button
          className={clsx(
            sharedClasses.rebrandedSecondaryButton,
            classes.button
          )}
          variant="outlined"
          onClick={() => handleExpandClick(`Read More clicked on ${name} details`)}
        >
          {expanded ? 'Read less' : 'Read more'}
        </Button>
      </div>
    </div>
  );
};

NutrientDescription.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  additionalDescription: PropTypes.string.isRequired,
};

export default NutrientDescription;
