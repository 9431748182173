import { useMemo } from 'react'

import { uniqBy } from 'lodash'
import { boosterNames } from 'resources/constants'

const DEFAULT_SUBSCRIPTION_UNIT = 'MONTH'

// TODO: supplyPlanID can be change in future that's why using duration parameter
const useGetSupplementBoosters = (
  subscriptionProducts = [],
  supplyPlanID,
  duration
) => {
  const boosters = useMemo(() => {
    // get the unique boosters from the list of subscription products, excluding Probiotic
    if (subscriptionProducts) {
      return uniqBy(
        subscriptionProducts
          .filter((subscription) => subscription.duration === duration)
          .map((subscription) => subscription.boosters)
          .flat(),
        'name'
      );
    }
    return null
  }, [subscriptionProducts, duration]);

  return {
    /** @deprecated */
    boosters: boosters?.filter((booster) => !booster.name.includes(boosterNames.Probiotic) && !booster.name.includes(boosterNames.Omega3)),
    boostersV1: boosters
  }
}

export default useGetSupplementBoosters
