import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux'
import useStyles from './Recommendation.styles';

export default function Recommendation(props) {
  const classes = useStyles()
  const {
    traitRecommendation, geneticInsightDescription,
  } = props;
  const eer = useSelector(state => state.nutrition.eer)
  const formattedEER = Math.round(parseFloat(eer))

  const recArray = [];
  if (traitRecommendation && traitRecommendation.rec1) {
    recArray.push(traitRecommendation.rec1);
    if (traitRecommendation.rec2) recArray.push(traitRecommendation.rec2);
    if (traitRecommendation.rec3) recArray.push(traitRecommendation.rec3);
    if (traitRecommendation.rec4) recArray.push(traitRecommendation.rec4);
    recArray.push(`Your recommended total daily caloric intake is ${formattedEER}. Total daily calories are determined using your age, gender, height, weight, activity level, and health goals.`)
  } else {
    recArray.push(geneticInsightDescription);
  }

  return recArray.map(recommendation => (
    <Typography variant="body1" paragraph className={classes.description}>
      {recommendation}
    </Typography>
  ));
}
