import React, { forwardRef } from "react";
import { Box, Container, Link, List, ListItem, Typography } from "@material-ui/core";
import useStyles from "./References.styles";

const References = forwardRef(({ references }) => {
  const classes = useStyles();

  const renderItem = references.map((ref, index) => (
    <ListItem key={index} className={classes.listItems}>
      <Box className={classes.bulletWrapper}>
        <Typography variant="body1" className={classes.bullets} id={`Ref${index}`}>
          {index + 1}.
        </Typography>
        <Link
          href={ref.studyLink}
          target="_blank"
          className={`${classes.listItemText} ${classes.lightBlue}`}
        >
          {ref.reference}
        </Link>
      </Box>
    </ListItem>

  ))

  return (
    <Box className={classes.referenceSection} id={`Ref-1`}>
      <Container style={{ flexGrow: 1 }} maxWidth="lg">
        <Typography variant="h4" gutterBottom className={classes.title}>
          References
        </Typography>
        <List>
          {renderItem}
        </List>
      </Container>
    </Box>
  );
});

export default References;
