/* eslint-disable class-methods-use-this */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDna,
  faUser,
  faQuestion,
  faCheck,
  faBarcode,
} from '@fortawesome/free-solid-svg-icons';
import icons from '../resources/icons';
import { colors } from 'common/assets/sharedUI';

const styles = theme => ({
  statusTracker: {
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyItems: 'center',
    fontSize: 16,
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    [theme.breakpoints.up('xs')]: {
      padding: '10px 0',
    },
  },
  stepIcon: {
    borderRadius: '50%',
    background: 'white',
    border: 'solid 2px white',
    display: 'flex',
    width: '2.5em',
    height: '2.5em',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '.4em',
    '& .circle': {
      background: colors.gray_03,
      fontSize: '1em',
      height: '2em',
      width: '2em',
      display: 'flex',
      borderRadius: '50%',
      textAlign: 'center',
      color: 'white',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  stepIconActive: {
    border: `solid 2px ${colors.gray_03}`,
    '& .circle': {
      background: colors.primaryBlue,
    },
  },
  stepIconCompleted: {
    '& .circle': {
      background: colors.green_04,
    },
  },
  stepLabel: {
    fontSize: '.813em',
    width: '5.125em',
    textAlign: 'center',
    lineHeight: '1em',
  },
  stepLabelActive: {
    fontWeight: 'bold',
  },
  separatorWrapper: {
    padding: 15,
  },
  separator: {
    position: 'relative',
    bottom: '1em',
    background: colors.gray_03,
    height: '2px',
    width: '100%',
    alignSelf: 'center',
  },
  stepWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  stepWrapperForSequencing: {
    display: 'flex',
    width: '60%',
    justifyContent: 'space-between',
    margin: '0px 20%',
  },
  step: {
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  consultIcon: {
    height: 33,
    width: 33,
  },
});

const StatusTrackerV2 = ({ productType, activeStep, classes, isSequencingUser }) => {
  const orderDetails = useSelector(state => state.registration.orderDetails);
  const userProfile = useSelector(state => state.profile.userProfile);

  const getConsult = () => {
    if (orderDetails) {
      return orderDetails.hasConsult
    }
    if (userProfile && userProfile.data) {
      return userProfile.data.hasConsult
    }
    return false
  }

  const Step = ({ isActive, isComplete, label, children }) => (
    <div className={classes.step}>
      <div className={clsx(classes.stepIcon, isActive && classes.stepIconActive, isComplete && classes.stepIconCompleted)}>
        <div className="circle">
          {children}
        </div>
      </div>
      <div className={clsx(classes.stepLabel, isActive && classes.stepLabelActive)}>{label}</div>
    </div>
  )

  const renderFirstStep = (isActive, isComplete) => (
    <Step isActive={isActive} isComplete={isComplete} label={'Create account'}>
      <FontAwesomeIcon icon={isComplete ? faCheck : faUser} />
    </Step>
  )

  const renderSecondStep = (productType, isActive, isComplete) => (
    productType === 'kit'
      ? <Step isActive={isActive} isComplete={isComplete} label={'Personalize results'}>
        <FontAwesomeIcon icon={isComplete ? faCheck : faQuestion} />
      </Step>
      : <Step isActive={isActive} isComplete={isComplete} label={'Provide DNA'}>
        <FontAwesomeIcon icon={isComplete ? faCheck : faDna} />
      </Step>
  )

  const renderThirdStep = (productType, isActive) => (
    productType === 'kit'
      ? <Step isActive={isActive} isComplete={false} label={'Enter code'}>
        <FontAwesomeIcon icon={faBarcode} />
      </Step>
      : <Step isActive={isActive} isComplete={false} label={'Personalize results'}>
        <FontAwesomeIcon icon={faQuestion} />
      </Step>
  )

  const consultIcon = <img src={icons['consultInvert']} alt="consult icon" className={classes.consultIcon} />

  const consult = getConsult()

  return (
    <div
      className={classes.statusTracker}
      id={`statusTrackerStep${activeStep}`}
      style={{ maxWidth: consult ? 350 : 300 }}
    >
      <div className={ isSequencingUser  ? classes.stepWrapperForSequencing : classes.stepWrapper}>
        {renderFirstStep(activeStep === 1, activeStep > 1)}
        <div className={classes.separator} />
        {  isSequencingUser ? '' :  renderSecondStep(productType, activeStep === 2, activeStep > 2)}
       { isSequencingUser ? '' :  <div className={classes.separator} />}
        {renderThirdStep(productType, activeStep === 3)}
        {consult &&
          <Step isActive={false} isComplete={false} label={'Sign up for consult'}>
            {consultIcon}
          </Step>
        }
      </div>
    </div>
  );
}

StatusTrackerV2.propTypes = {
  productType: PropTypes.oneOf(['edna', 'kit']).isRequired,
  activeStep: PropTypes.oneOf([1, 2, 3]).isRequired,
  classes: PropTypes.shape({
    statusTracker: PropTypes.string,
    stepIcon: PropTypes.string,
    stepIconActive: PropTypes.string,
    stepIconCompleted: PropTypes.string,
    stepLabel: PropTypes.string,
    stepLabelActive: PropTypes.string,
    separatorWrapper: PropTypes.string,
    separator: PropTypes.string,
    stepWrapper: PropTypes.string,
    stepWrapperForSequencing: PropTypes.string,
    step: PropTypes.string,
    consultIcon: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(StatusTrackerV2);
