import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "common/assets/sharedUI";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: 20,
      backgroundColor: colors.white,
      color: colors.green_07,
      padding: "2px 8px",
      height: "unset",
      textTransform: "none",
      border: "2px solid #385C0D",
      borderRadius: "3px",
      opacity: 1,
      letterSpacing: "0px",
      color: colors.green_07
    },
    title: {
      fontWeight: 800,
    },
    flex: {
      display: "flex",
      alignItems: "top"
    },
    icon: {
      width: 40,
      height: 40,
      marginRight: "26px",
    },
    description: {
      marginTop: "25px",
    },
    collapse: {
      marginTop: "16px"
    }
  })
);

export default useStyles;
