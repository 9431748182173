/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { get } from 'lodash';
import { getAccountContracts } from 'store/actions/consent';
import { selectStatus } from 'store/actions/status';
import { hideDnaUploadedBanner } from 'store/actions/dnaUpload';
import {
  begin, getProgress, back,
} from 'store/actions/questionnaire';
import { getName } from 'utils/dataTools';
import { userStatuses } from 'common/resources/constants';
import { apiStatuses } from 'resources/constants';
import {
  QuestionWrapper, Loading, WelcomeBackModal,
  WelcomeQuestionnaire, BioBankingResearchInfo, BioBankingResearchConsent,
  RecipeQuestionnaireIntro,
} from '../components/componentsV2';

const questionnaireStatuses = {
  BEGIN: 'beginQuestionnaire',
  CONTINUE: 'continueQuestionnaire',
  RECIPE: 'recipeQuestionnaire',
};

// TODO: move to Questionnaire model in common
const questionnaireNames = {
  ONBOARDING: 'onboarding',
  RECIPE: 'recipe',
};

// TODO: This page needs to be refactored. Most of the logic contained in the useEffects should be in actions and not in the component.
const QuestionnairePage = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  // questionnaire id
  const questionnaireId = useSelector(state => state.questionnaire.questionnaireId);
  // modal variables
  const [firstLoad, setFirstLoad] = useState(true);
  const [open, setOpen] = useState(false);
  // user data
  const profile = useSelector(state => state.profile.userProfile);
  // consent contract
  const contracts = useSelector(state => state.consent.accountContracts);

  const status = useSelector(state => state.status.userStatus);
  const statusLoadStatus = useSelector(state => state.status.status);
  const [isQuestionnaireFinished, setIsQuestionnaireFinished] = useState(false);

  const dnaUploadProgress = useSelector(state => state.dnaUpload.uploadProgress);

  // user status
  useEffect(() => {
    const webStatus = get(status, 'webDisplay', null);

    // If user's status is not a questionnaire or consent status, redirect to home
    if (
      webStatus
      && !webStatus.toLowerCase().includes('questionnaire')
      && !webStatus.toLowerCase().includes('consent')
      && !(webStatus === userStatuses.JUMP_GO_VIDEO_KIT)
    ) {
      history.push('/');
    }

    // Fetch begin API if the user should continue the questionnaire
    // Set isQuestionnaireFinished to true when the status is not begin or continue
    if (match.params.questionnaireName && match.params.questionnaireName !== 'undefined') {
      if (webStatus && (webStatus.includes('continueQuestionnaire') || webStatus === userStatuses.JUMP_GO_VIDEO_KIT)) {
        dispatch(begin(match.params.questionnaireName));
      } else if (webStatus === questionnaireStatuses.RECIPE) {
        dispatch(begin(match.params.questionnaireName));
      } else if (webStatus && !webStatus.includes('beginQuestionnaire')) {
        setIsQuestionnaireFinished(true);
      }
    }

    // check if the user status has changed to consentTo... and get contract
    if (webStatus && webStatus.includes('consentToResearch')) {
      dispatch(getAccountContracts());
    }

    // we just completed onboarding and need to be redirected to complete recipes next
    if (match.params.questionnaireName && match.params.questionnaireName === questionnaireNames.ONBOARDING && webStatus && webStatus === questionnaireStatuses.RECIPE) {
      history.push(`/questionnaire/${questionnaireNames.RECIPE}/`);
    }
  }, [status, dispatch, history, match.params.questionnaireName]);

  // question object - to determine which question type will be shown to the user
  const question = useSelector(state => state.questionnaire.question);
  const beginStatus = useSelector(state => state.questionnaire.beginStatus);
  // current question id
  const currentQuestionId = useSelector(state => state.questionnaire.currentQuestionId);
  // Update URL when a new question loads
  // TODO: Either fix routing with questions (which has never worked) or remove this to stop pushing question ID to the url
  useEffect(() => {
    // Wrapping with if prevents issue in #252.
    if (currentQuestionId) {
      history.push(`${currentQuestionId}`);
    }
  }, [currentQuestionId, history]);

  const trackProgress = useSelector(state => state.questionnaire.trackProgress);
  // Get user's progress when a new question loads
  useEffect(() => {
    if (questionnaireId !== -1 && trackProgress) {
      dispatch(getProgress(questionnaireId));
    }
  }, [question, dispatch, questionnaireId, trackProgress]);

  // progress object - for the progress bar and to determine which welcome screen will be shown to the user
  const progress = useSelector(state => state.questionnaire.progress);
  const [startQuestionnaire, setStartQuestionnaire] = useState(false);
  // Check user's progress
  useEffect(() => {
    // Welcome back modal will only load the first time and if the user has some progress
    if (progress && parseInt(get(progress, 'progress.answer_count', 0), 10) > 0 && firstLoad && !startQuestionnaire) {
      setOpen(true);
    }
  }, [progress, firstLoad, startQuestionnaire]);

  // questionnaireComplete - to determine if the user has ended answering the questionnaire
  const questionnaireComplete = useSelector(state => state.questionnaire.questionnaireComplete);
  // check save answer progress to display complete screens
  useEffect(() => {
    if (questionnaireComplete) {
      dispatch(selectStatus());
    }
  }, [dispatch, questionnaireComplete]);

  const handleContinueToInfo = () => {
    setOpen(false);
  };

  const [continueToConsent, setContinueToConsent] = useState(false);
  const handleContinueToConsent = () => {
    setContinueToConsent(true);
  };

  const finishConsent = useCallback(() => {
    dispatch(selectStatus());
    history.push('/home');
  }, [dispatch, history]);

  // check status of addConsent API call
  const addConsentStatus = useSelector(state => state.consent.addConsentStatus);
  // check add consent status to go to home page
  useEffect(() => {
    if (addConsentStatus !== apiStatuses.IDLE && addConsentStatus !== apiStatuses.PENDING) {
      if (get(status, 'webDisplay', '') === 'consentToResearchAndBioBankExisting' || get(status, 'webDisplay', '') === 'consentToResearchExisting') {
        finishConsent();
      }
      setContinueToConsent(false);
    }
  }, [addConsentStatus, finishConsent, status]);

  // handle welcome page functionality when user clicks back button
  // on first question, when the user starts the questionnaire or
  // when the page is reloaded
  // Handle back and next button functionality
  const handleBackBtn = () => {
    dispatch(back(currentQuestionId));
  };

  const handleStartQuestionnaire = useCallback(() => {
    dispatch(hideDnaUploadedBanner());
    if (question.length === 0) {
      dispatch(begin(match.params.questionnaireName));
    }
    setStartQuestionnaire(true);
  }, [dispatch, match.params.questionnaireName, question.length]);

  // render status using web status
  const renderScreens = useCallback((webStatus) => {
    const modalProps = {
      open,
      onClose: () => {
        setOpen(false);
      },
      name: profile ? getName(profile) : '',
      isBioBank: true,
    };

    if (webStatus.includes('consentTo')) {
      if (continueToConsent) {
        const bioBankingResearchConsentProps = {
          isBioBank: webStatus.includes('consentToResearchAndBioBank'),
          contracts,
        };
        return (
          <React.Fragment>
            <BioBankingResearchConsent {...bioBankingResearchConsentProps} />
            <WelcomeBackModal {...modalProps} />
          </React.Fragment>
        );
      }
      const bioBankingResearchInfoProps = {
        isBioBank: webStatus.includes('consentToResearchAndBioBank'),
        onClickContinue: handleContinueToConsent,
      };
      return (
        <React.Fragment>
          <BioBankingResearchInfo {...bioBankingResearchInfoProps} />
          <WelcomeBackModal {...modalProps} />
        </React.Fragment>
      );
    }
    switch (webStatus) {
      case userStatuses.BEGIN_QUESTIONNAIRE_EXISTING:
      case userStatuses.CONTINUE_QUESTIONNAIRE_EXISTING: {
        const welcomeProps = {
          name: profile ? getName(profile) : '',
          onClick: handleStartQuestionnaire,
          displayUploadSuccess: dnaUploadProgress === 100,
          productType: 'edna',
          activeStep: 3,
          isSequencingUser:  profile?.data?.isSequencingUser ?? false,
        };
        return <WelcomeQuestionnaire {...welcomeProps} />;
      }
      case userStatuses.BEGIN_QUESTIONNAIRE_KIT:
      case userStatuses.CONTINUE_QUESTIONNAIRE_KIT:
      case userStatuses.JUMP_GO_VIDEO_KIT: {
        // Leaving this separate from the cases above for now because I'm sure there's intended differences in these cases that weren't in the designs.
        const welcomeProps = {
          name: profile ? getName(profile) : '',
          onClick: handleStartQuestionnaire,
          displayUploadSuccess: dnaUploadProgress === 100,
          productType: 'kit',
          activeStep: 2,
        };

        return <WelcomeQuestionnaire {...welcomeProps} />;
      }
      case questionnaireStatuses.RECIPE: {
        const welcomeProps = {
          onClick: handleStartQuestionnaire,
          onClickGoToConsent: handleContinueToInfo,
        };
        return <RecipeQuestionnaireIntro {...welcomeProps} />;
      }
      default:
        return null;
    }
  }, [continueToConsent, contracts, dnaUploadProgress, handleStartQuestionnaire, open, profile]);

  const props = {
    question,
    questionnaireId,
    trackProgress,
    onClickBackBtn: handleBackBtn,
  };
  const modalProps = {
    open,
    onClose: () => {
      setOpen(false);
      setFirstLoad(false);
    },
    name: profile ? getName(profile) : '',
  };

  // if the begin service has been called, show the loading icon
  const webStatus = get(status, 'webDisplay', '');
  const shouldShowLoadingIcon = !isQuestionnaireFinished
    && (
      !webStatus.includes('beginQuestionnaire')
      || (
        webStatus.includes('beginQuestionnaire')
        && startQuestionnaire
      )
    )
    && (
      beginStatus === apiStatuses.IDLE
      || beginStatus === apiStatuses.PENDING
    );

  return (
    <div>
      {
        statusLoadStatus === apiStatuses.IDLE || statusLoadStatus === apiStatuses.PENDING || shouldShowLoadingIcon ? (<Loading />)
          : (startQuestionnaire || webStatus.includes('continueQuestionnaire')) && !isQuestionnaireFinished && currentQuestionId > 0 ? (
            <React.Fragment>
              <QuestionWrapper {...props} />
              <WelcomeBackModal {...modalProps} />
            </React.Fragment>
          ) : renderScreens(webStatus)
      }
    </div>
  );
};

export default QuestionnairePage;
