import React from 'react';
import {
  Switch, Redirect, Route,
} from 'react-router-dom';

import BaseRoute from './BaseRoute';
import WebAppRoute from './WebAppRoute';
import DeleteAccountRoute from './DeleteAccountRoute';

import {
  LoginPage,
  RegisterPage,
  RegisterTMKit,
  ForgotPasswordPage,
  ForgotPasswordChangePage,
  DeleteRequestConfirmationPage,
  RegisterPageForSequenceUser,
  SupplementSubscriptionPage as SupplementSubscriptionPageV1,
} from '../pages';

import {
  WebAppHomePage,
  NutrientPage,
  FoodPage,
  InsightsPage,
  MyAccountPage,
  NotFoundPage,
  QuestionnairePage,
  ConsentPage,
  AvailableProductsPage,
  ProductDetailsPage,
  PurchasedProductsPage,
  RecipesPage,
  FoodSettingsPage,
  IndividualFoodSettingsPage,
  PreferenceSettingsPage,
  SupplementSubscriptionPage,
  BlendSubscriptionPage,
  ConsultationLandingPage
} from '../pages/pagesV2';

import { nutrientCategories } from '../resources/hardcodedAssets';
import { BaseContainer } from '../components';
import ShopPage from '../pages/ShopPage';
import { internalURLs } from '../resources/constants';
import OptimalFood from 'components/Headers/OptimalFood';
import Recipe from 'components/Headers/Recipe';
import Nutrients from 'components/Headers/Nutrients';
import HomeHeader from 'components/Headers/Home';
import WebAppFullPageLayout from '../layouts/WebAppFullPageLayout';

const Routes = () => (
  <Switch>
    <BaseRoute exact path="/" component={LoginPage} />
    <Redirect exact path="/register/existing_dna" to="/register" />
    <Redirect exact path="/register/existing_data" to="/register" />
    <Redirect exact path="/register/dna_kit" to="/register" />
    <Route
      exact
      path="/register"
      render={(props) => (
        <BaseContainer>
          <RegisterPage {...props} />
        </BaseContainer>
      )}
    />
     <Route
      exact
      path="/register-sequencing-user"
      render={(props) => (
        <BaseContainer>
          <RegisterPageForSequenceUser {...props} />
        </BaseContainer>
      )}
    />
    <Route
      exact
      path="/register-kit"
      render={(props) => (
        <RegisterTMKit {...props} />
      )}
    />
    <BaseRoute exact path="/register/shopify" component={RegisterPage} />
    <BaseRoute exact path="/forgot_password" component={ForgotPasswordPage} />
    <BaseRoute exact path="/change_password/:key" component={ForgotPasswordChangePage} />
    <DeleteAccountRoute exact path="/delete/verify_token/:token" component={DeleteRequestConfirmationPage} />
    <WebAppRoute exact path="/home" navProps={{ tab: 'home', header: <HomeHeader /> }} component={WebAppHomePage} />
    <WebAppRoute
      exact
      path="/nutrients"
      navProps={{ tab: 'nutrients', header: <Nutrients /> }}
      component={NutrientPage}
      layout={WebAppFullPageLayout}
      additionalProps={{ selected: nutrientCategories[0], detailView: false }} // TODO: use traits fetched from endpoint instead of hardcoded assets
    />
    {nutrientCategories.map((category) => (
      <WebAppRoute
        key={category}
        exact
        path={`/nutrients/${category}`}
        navProps={{ tab: 'nutrients', header: <Nutrients /> }}
        layout={WebAppFullPageLayout}
        additionalProps={{ selected: category, detailView: false }}
        component={NutrientPage}
      />
    ))}
    {nutrientCategories.map((category) => (
      <WebAppRoute
        key={category}
        exact
        path={`/nutrients/${category}/:id`}
        navProps={{ tab: 'nutrients' }}
        additionalProps={{ selected: category, detailView: true }}
        layout={WebAppFullPageLayout}
        component={NutrientPage}
      />
    ))}
    <WebAppRoute exact path="/recipes" navProps={{ tab: 'recipes', header: <Recipe /> }} component={RecipesPage} />
    <WebAppRoute exact path="/food" navProps={{ tab: 'food', header: <OptimalFood /> }} additionalProps={{ detailView: false }} component={FoodPage} />
    <WebAppRoute exact path="/food/settings" navProps={{ tab: 'food' }} component={FoodSettingsPage} />
    <WebAppRoute exact path="/food/settings/individual-foods" navProps={{ tab: 'food' }} component={IndividualFoodSettingsPage} />
    <WebAppRoute exact path="/food/settings/allergies-and-preferences" navProps={{ tab: 'food' }} additionalProps={{ type: 'allergies' }} component={PreferenceSettingsPage} />
    <WebAppRoute exact path="/food/settings/diets" navProps={{ tab: 'food' }} additionalProps={{ type: 'diets' }} component={PreferenceSettingsPage} />
    <WebAppRoute exact path="/food/:id" navProps={{ tab: 'food' }} additionalProps={{ detailView: true }} component={FoodPage} />
    <WebAppRoute exact path="/shop" navProps={{ tab: 'shop' }} component={ShopPage} />
    <WebAppRoute exact path="/insights" navProps={{ tab: 'insights' }} component={InsightsPage} />
    <WebAppRoute exact path="/university" navProps={{ tab: 'university' }} component={NutrientPage} />
    <WebAppRoute exact path="/account/consent" navProps={{ tab: 'account' }} component={ConsentPage} />
    <WebAppRoute exact path="/account/products" navProps={{ tab: 'account' }} component={AvailableProductsPage} />
    <WebAppRoute exact path="/account/products/product-details/:productId" navProps={{ tab: 'account' }} component={ProductDetailsPage} />
    <WebAppRoute exact path="/account/products/purchased" navProps={{ tab: 'account' }} component={PurchasedProductsPage} />
    <WebAppRoute exact path={internalURLs.SUPPLEMENT_PRODUCT_DETAILS} navProps={{ tab: 'account' }} component={SupplementSubscriptionPageV1} />
    <WebAppRoute exact path={internalURLs.SUPPLEMENT_PRODUCT_DETAILS+'/_supplement_bk'} navProps={{ tab: 'account' }} component={SupplementSubscriptionPage} />
    <WebAppRoute exact path={internalURLs.BLEND_PRODUCT_DETAILS} navProps={{ tab: 'account' }} component={BlendSubscriptionPage} />
    <WebAppRoute exact path="/account" navProps={{ tab: 'account' }} component={MyAccountPage} />
    <WebAppRoute exact path="/questionnaire/:questionnaireName?/:questionId?" navProps={{ tab: '' }} component={QuestionnairePage} />
    <WebAppRoute
      exact path="/consultation"
      navProps={{ tab: 'consultation', detailView: true }}
      layout={WebAppFullPageLayout}
      component={ConsultationLandingPage} />
    <WebAppRoute navProps={{ tab: '' }} component={NotFoundPage} />
  </Switch>
);

export default Routes;
