import { colors, fonts } from 'common/assets/sharedUI';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    description: {
        color: colors.darkestGrey,
        fontFamily: fonts.Swiss721LT
    }
}))

export default useStyles;