import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors, fonts } from 'common/assets/sharedUI';

const useStyles = makeStyles((theme) => createStyles({
  firstParagraph: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  reportHighlightsContainer: {
    padding: 20,
    marginBottom: 40,
  },
  firstImgContainer: {
    marginTop: 0,
    display: 'flex',
    alignItems: 'flex-start',
  },
  firstImg: {
    width: '100%',
  },
  carouselGrid: {
    backgroundColor: colors.lightGrey,
    position: 'relative',
  },
  carouselContainer: {
    marginTop: 35,
    height: 250,
  },
  viewAllFoodsContainer: {
    width: '100%',
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  secondImgContainer: {
    position: 'absolute',
    width: 420,
    height: '24vw',
    right: 0,
    bottom: '-19vw',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: 380,
      bottom: '-16.5vw',
      right: -20,
    },
    [theme.breakpoints.down('sm')]: {
      width: 360,
      bottom: '-14vw',
      height: '28vw',
      right: -25,
    },
    [theme.breakpoints.down('xs')]: {
      bottom: '-21vw',
      width: 255,
      height: '35vw',
      right: -15,
    },
  },
  secondImg: {
    transform: 'rotate(12deg)',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 70,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 70,
    },
  },
  genoPalateUniversityTitle: {
    textAlign: 'center',
    paddingTop: 80,
    paddingBottom: 20,
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignContent: 'center',
  },
  additionalResourcesTitle: {
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5rem',
    },
  },
  genoPalateUniversityContainer: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 40,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 20,
    },
  },
  genoPalateUniversityDescription: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  genoPalateUnivesitySections: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 30,
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  sectionIcon: {
    color: colors.blue_03,
    fontSize: 30,
    marginBottom: 8,
  },
  buttonIcon: {
    color: colors.blue_03,
    fontSize: 25,
    marginRight: 5,
  },
  customIcon: {
    marginRight: 5,
  },
  customImg: {
    filter: 'invert(39%) sepia(89%) saturate(371%) hue-rotate(151deg) brightness(91%) contrast(92%)',
  },
  additionalResourcesContainer: {
    paddingTop: 30,
    paddingBottom: 30,
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  supplementImgContainer: {
    position: 'relative',
  },
  supplementImg: {
    borderRadius: '8px',
    width: '100%',
    maxHeight: '290px',
    objectFit: 'cover',
  },
  supplementImageText: {
    color: colors.white,
    position: 'absolute',
    bottom: '24px',
    left: '24px',
    fontFamily: fonts.heading3,
    paddingTop: '-24px',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
  },
  supplementButton: {
    position: 'absolute',
    width: '135px',
    color: colors.green_07,
    backgroundColor: colors.green_06,
    bottom: '24px',
    right: '24px',
    fontWeight: 700,
    fontSize: '12px',
    letterSpacing: '1.5px',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: colors.menuGreen,
    },
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  supplementSpotlightHeader: {
    color: colors.gray_06,
    fontFamily: fonts.heading3,
    paddingTop: '24px',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
  },
  supplementSpotlightPrice: {
    fontFamily: fonts.heading3,
    color: colors.gray_04,
    paddingTop: '8px',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '24px',
  },
  supplementSpotlightDescription: {
    fontFamily: fonts.paragraph2,
    color: colors.gray_04,
    paddingTop: '8px',
    paddingBottom: '45px',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  nutrientCardsContainer: {
    marginTop: '20px',
    marginBottom: '20px',

  },
  '@media (max-width: 600px)': {
    nutrientsCard: {
      order: 2, 
    },
    foodsCard: {
      order: 1, 
    }
  },
  title: {
    fontFamily: fonts.BaskervilleBold,
    fontWeight: 400,
    lineHeight: '30px',
    fontSize: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.6rem'
    },
    padding: 0,
  },
  subtitle: {
    paddingTop: '20px',
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  listItem: {
    padding: 0,
    cursor: 'pointer'
  },

  legendIcon: {
    minWidth: '23px',
  },
  shopAllLink: {
    color: colors.lightBlue,
    textTransform: 'capitalize',
    fontSize: '25px',
    lineHeight: '30px',
    font: fonts.Swiss721LT,
    fontWeight: 'normal',
    paddingBottom: '40px',
    marginLeft: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '22px',
    }
  },
  heading: {
    fontFamily: fonts.BaskervilleBold,
    fontWeight: 300,
    lineHeight: '49px',
    fontSize: '40px',
    marginLeft: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
      lineHeight: '34px',
    },
    marginTop: '20px',
  },
  card: {
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
    minHeight: '235px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '5px',
    paddingBottom: '10px'
  },
  detail: {
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    marginTop: '10px',
  },
  buttonText: {
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    marginLeft: '8px',
  },
  genoPalateUniversity: {
    backgroundColor: colors.white,
    marginBottom: '20px',
  },
  shopAllContainer: {
    marginBottom: '40px',
  },
  
}));

export default useStyles;