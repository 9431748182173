import React, { useMemo } from 'react';
import { faSalad } from '@fortawesome/pro-duotone-svg-icons';
import helpers from '../../../utils/helpers'

import {
  optimalFoodsHeader,
  optimalFoodsLink,
  optimalFoodsSubheading,
} from 'resources/constants';
import CustomHeader from '../CustomHeader';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { EventActions, EventCategories, registerGAClickEventEnhanced } from 'utils/googleanalytics';

function OptimalFood() {
  const { userProfile } = useSelector((state) => state.profile);
  const name = useMemo(() => (get(userProfile, 'data.name', '')), [userProfile]);

  const onClickLink = () => {
    registerGAClickEventEnhanced(EventCategories.FoodIndex, EventActions.FoodScoreCalculation, EventActions.FoodScoreCalculation);
  }

  return (
    <CustomHeader
      header={`${helpers.possessive(name)} ${optimalFoodsHeader}`}
      subHeading={optimalFoodsSubheading}
      link={'https://www.genopalate.com/pages/how-your-foods-were-chosen'}
      icon={faSalad}
      linkText={optimalFoodsLink}
      onClickLink={onClickLink}
    />
  );
}

export default OptimalFood;
