import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Popper, Typography, Grid, Button, Paper, CircularProgress,
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCartPlus } from '@fortawesome/pro-solid-svg-icons';
import { colors } from 'common/assets/sharedUI';
import { sharedStyles } from '../../resources';
import { helpers } from 'utils';

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 16,
    backgroundColor: colors.gray_01,
    borderRadius: 16,
    height: 380,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  selectedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 16,
    backgroundColor: `${colors.green_01}40`, // making hex color low opacity
    border: `1px solid ${colors.green_02}`,
    borderRadius: 16,
    height: 380,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  popper: {
    zIndex: theme.zIndex.modal,
    backgroundColor: colors.white,
    border: `1px solid ${colors.green_03}`,
    borderRadius: 8,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 277,
  },
  pseudoDisabledButton: { // the button must be clickable but must look disabled
    height: 48,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    fontWeight: 700,
    fontSize: '16px',
    letterSpacing: 1.25,
    textTransform: 'uppercase',
    alignSelf: 'center',
    backgroundColor: colors.green_00,
    color: colors.white,
    borderColor: colors.green_01,
  },
  popperButton: {
    padding: '16px 0px',
    width: '100%',
    color: colors.green_05,
    backgroundColor: colors.green_02,
    radius: 444,
  },
  overrideStyles: {
    padding: '16px 0px',
    width: '100%',
  },
  icon: {
    marginLeft: 8,
  },
}));

const Booster = ({
  name,
  price,
  formulation,
  selected,
  addToCartClicked,
  removeFromCartClicked,
  disabled,
  addBaseSubscriptionClicked,
  loading,
  awaitingResults,
  currentSubscription,
}) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event) => {
    if (event && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }, []);

  const boosterName = useMemo(() => (name.includes('Booster') ? name : `${name} Booster`), [name]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={selected ? classes.selectedWrapper : classes.wrapper}>
          <div>
            <Typography className={sharedClasses.h5} style={{ color: colors.gray_04, marginBottom: 8 }}>
              {`${helpers.formatNumber(price, { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })}/mo`}
            </Typography>
            <Typography className={sharedClasses.h6} style={{ marginBottom: 8 }}>
              {boosterName}
            </Typography>
            <ul>
              {formulation && formulation.length > 0 && formulation.map(ingredient => (
                <li key={ingredient.name}>
                  <Typography className={sharedClasses.body}>
                    {`${ingredient.displayName} - ${ingredient.amount} ${ingredient.uom}`}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
          {!selected ? (
            <div>
              <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
              >
                <Paper
                  className={classes.popper}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <div>
                      <Typography className={sharedClasses.caption} style={{ textAlign: 'center' }}>
                        You don’t have a subscription in your cart!
                        Add one to unlock boosters
                      </Typography>
                      <Button
                        onClick={() => {
                          addBaseSubscriptionClicked();
                          handleClose();
                        }}
                        className={clsx(sharedClasses.rebrandedSecondaryButton, classes.popperButton)}
                      >
                        Buy Subscription
                      </Button>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Popper>
              <Button
                ref={anchorRef}
                className={disabled || loading ? clsx(classes.pseudoDisabledButton, classes.overrideStyles) : clsx(sharedClasses.rebrandedSecondaryButton, classes.overrideStyles)} // when disabled, the button must look disabled but still be clickable
                onClick={disabled ? handleToggle : () => addToCartClicked(name)}
                disabled={loading || awaitingResults || (currentSubscription && currentSubscription.isInstallmentProduct)} // when boosters are loading, the button is actually disabled
              >
                SELECT BOOSTER
                {loading ? (
                  <CircularProgress
                    size={16}
                    color={colors.white}
                    className={classes.icon}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCartPlus}
                    color={colors.white}
                    className={classes.icon}
                  />
                )}
              </Button>
            </div>
          )
            : (
              <Button
                className={loading ? clsx(classes.pseudoDisabledButton, classes.overrideStyles) : clsx(sharedClasses.rebrandedSuccessButton, classes.overrideStyles)}
                onClick={() => removeFromCartClicked(name)}
                disabled={loading ||
                  (currentSubscription &&
                  currentSubscription.isInstallmentProduct
                  )} // when boosters are loading, the button is actually disabled 
                  // and when the subscription is an installment product, the button is disabled
              >
                SELECTED
                {loading ? (
                  <CircularProgress
                    size={16}
                    color={colors.white}
                    className={classes.icon}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={colors.green_05}
                    className={classes.icon}
                  />
                )}
              </Button>
            )}
        </div>
      </Grid>
    </Grid>
  );
};

Booster.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  formulation: PropTypes.arrayOf(PropTypes.shape({
    abbvName: PropTypes.string,
    amount: PropTypes.number,
    displayName: PropTypes.string,
    name: PropTypes.string,
    uom: PropTypes.string,
  })).isRequired,
  addToCartClicked: PropTypes.func.isRequired,
  removeFromCartClicked: PropTypes.func.isRequired,
  addBaseSubscriptionClicked: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  awaitingResults: PropTypes.bool.isRequired,
};

export default Booster;
