import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { images } from 'resources';
import { colors } from 'common/assets/sharedUI';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import packageJson from '../../package.json';
import constants from 'resources/constants';
import {
  registerGAClickEventEnhanced, mapPathToPageTitle, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';

global.appVersion = packageJson.version;

const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
    paddingTop: 60,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: colors.darkerGrey,
    color: colors.white,
  },
  space: {
    paddingTop: 40,
    paddingBottom: 20,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexFlow: 'column',
    },
  },
  socialMediaSpace: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
  },
  legendSpace: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  versionSpace: {
    paddingBottom: 41,
    display: 'flex',
    justifyContent: 'center',
    color: colors.darkerGrey,
  },
  button: {
    marginLeft: 30,
    color: colors.white,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'baseline',
    },
  },
  image: {
    width: '7.5em',
    height: '7.5em',
    padding: '1.5em',
    [theme.breakpoints.down('sm')]: {
      width: '6.5em !important',
      height: '6.5em !important',
      padding: '1.5em',
    },
    [theme.breakpoints.down('xs')]: {
      width: '4em !important',
      height: '4em !important',
      padding: '0.25em',
    },
  },
  berriesImage: {
    width: 420,
    top: -197,
    right: theme.spacing(6),
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      width: 370,
      top: -176,
      right: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      width: 320,
      top: -155,
      right: theme.spacing(2),
    },
  },
}));

const handleGAEvent = (eventLabel) => {
  registerGAClickEventEnhanced(EventCategories.Footer, EventActions.Click, eventLabel);
};

const Footer = () => {
  const location = useLocation();
  const { pathname, search } = location;
  // const userProductV2 = useSelector(state => state.productV2.userProductV2);
  const essentialNutritionGeneticInsights = useSelector(state => state.domain.essentialNutrition.data);
  const userTopFoods = useSelector(state => state.topFoods.userTopFoods);
  const pageTitle = mapPathToPageTitle(pathname + search, essentialNutritionGeneticInsights, userTopFoods);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar component='footer' position="static" className={classes.appBar}>
        <Container maxWidth="lg">
          <Toolbar className={classes.space}>
            <Button
              href={constants.CONTACT_SUPPORT_URL}
              onClick={() => handleGAEvent(EventLabels.ContactSupport)}
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              className={classes.button}
            >
              CONTACT SUPPORT
            </Button>
            <Button
              href={constants.PRIVACY}
              onClick={() => handleGAEvent(EventLabels.PrivacyPolicy)}
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              className={classes.button}
            >
              PRIVACY
            </Button>
            <Button
              href={constants.TERM_OF_SERVICES}
              onClick={() => handleGAEvent(EventLabels.TermsOfService)}
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              className={classes.button}
            >
              TERMS OF SERVICE
            </Button>
          </Toolbar>
          <div className={classes.socialMediaSpace}>
            <Link href="https://www.pinterest.com/genopalate/" onClick={() => handleGAEvent(EventLabels.Pinterest)} target="_blank" rel="noopener noreferrer"><img alt="pinterest" src={images.pinterest} className={classes.image} /></Link>
            <Link href="https://www.linkedin.com/company/genopalate/" onClick={() => handleGAEvent(EventLabels.LinkedIn)} target="_blank" rel="noopener noreferrer"><img alt="linkedin" src={images.linkedin} className={classes.image} /></Link>
            <Link href="https://twitter.com/GenoPalate" onClick={() => handleGAEvent(EventLabels.Twitter)} target="_blank" rel="noopener noreferrer"><img alt="twitter" src={images.twitter} className={classes.image} /></Link>
            <Link href="https://www.instagram.com/genopalate/" onClick={() => handleGAEvent(EventLabels.Instagram)} target="_blank" rel="noopener noreferrer"><img alt="instagram" src={images.insta} className={classes.image} /></Link>
            <Link href="https://www.facebook.com/genopalateinc/" onClick={() => handleGAEvent(EventLabels.facebook)} target="_blank" rel="noopener noreferrer"><img alt="facebook" src={images.facebook} className={classes.image} /></Link>
          </div>
          <span className={classes.legendSpace}>
            We do not offer our services outside of the United States at this time.
          </span>
          <span className={classes.versionSpace} name="version">{`v${global.appVersion}`}</span>
        </Container>
      </AppBar>
    </div>
  );
};

export default Footer;
