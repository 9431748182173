import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { uniqBy } from "lodash";
import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getTraitRecommendations } from "store/actions/domain";
import {
  KeyBenefit,
  NutrientDescription,
  RecommendationBlock,
} from "components/componentsV2";
import TipsPaper from "./TipsPaper";
import NutrientSources from "./NutrientSources";
import { cleanRecommendation, getIntake } from "utils/dataTools";
import { DomainNames, POTASSIUM, GENERAL_INTAKE_OF_POTASSIUM_FOR_FEMALE, GENERAL_INTAKE_OF_POTASSIUM_FOR_MALE, MALE_GENDER_ID } from 'resources/constants';
import actionTypes from "../../../store/actionTypes";
import icons from "resources/icons";
import { sharedStyles } from "resources";
import clsx from "clsx";
import useStyles from "./OverviewTab.styles";

/**
 * @param {{ name: string, icon: string, description: string }[]} benefits
 */
const NutrientBenefits = ({ benefits }) =>
  uniqBy(benefits, "description")
    .slice(0, 4)
    .map(({ icon, name, description }, _index, ary) => (
      <KeyBenefit
        key={`${icon}_${name}`}
        icon={icon}
        label={description}
        componentWidth={12}
      />
    ));

const Arrow = ({ onFabClick, arrowStyles, url, label, children }) => (
  <Fab className={arrowStyles} onClick={(e) => onFabClick(e, url, label)}>
    {children}
  </Fab>
);

Arrow.propTypes = {
  onFabClick: PropTypes.func.isRequired,
  arrowStyles: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const BenefitsWrapper = ({
  subtitleStyles,
  lineStyling,
  label,
  showTitle,
  children,
}) => (
  <div>
    {showTitle && (
      <>
        <Typography variant="h6" className={subtitleStyles}>
          {label || ""}
        </Typography>
        <hr className={lineStyling} />
      </>
    )}
    <Grid container>{children}</Grid>
  </div>
);

BenefitsWrapper.propTypes = {
  subtitleStyles: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const formatNutrientTitle = (inputString) => {
  return inputString
    .toLowerCase() 
    .split(' ')     
    .map(word => /^[a-zA-Z](?![a-zA-Z])/.test(word) ? word.toUpperCase() : word) 
    .join(' ');    
};


const OverviewTab = ({
  selectedDomain,
  essentialNutritionDetails,
  geneDetails,
  id,
  handleTabChange,
  genderId
}) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const {
    fetching: traitRecommendationFetching,
    data: traitRecommendation,
    error: traitRecommendationError,
  } = useSelector((state) => state.domain.traitRecommendation);

  const selectedTraitDetails = useMemo(
    () => selectedDomain.traits.find((t) => t.traitId === parseInt(id, 10)),
    [selectedDomain, id]
  );

  const traitENDetails = useMemo(
    () => essentialNutritionDetails.find((end) => end.id === parseInt(id, 10)),
    [essentialNutritionDetails, id]
  );

  const sensitivitiesOrSubstances = useMemo(
    () =>
      !![
        DomainNames.SENSITIVITIES.toLowerCase(),
        DomainNames.SUBSTANCES.toLowerCase(),
      ].includes(selectedDomain.name.toLowerCase()),
    [selectedDomain.name]
  );

  useEffect(() => {
    if (selectedTraitDetails.geneticInsightId && !sensitivitiesOrSubstances) {
      if (
        (!traitRecommendation ||
          traitRecommendation.id !==
            parseInt(selectedTraitDetails.geneticInsightId, 10)) &&
        !traitRecommendationFetching &&
        !traitRecommendationError
      ) {
        dispatch(
          getTraitRecommendations([selectedTraitDetails.geneticInsightId])
        );
      }
    } else {
      dispatch({
        type: actionTypes.GET_TRAIT_RECOMMENDATION_SUCCESS,
        data: null,
      });
    }
  }, [
    traitRecommendation,
    traitRecommendationFetching,
    selectedTraitDetails.geneticInsightId,
    dispatch,
    traitRecommendationError,
    sensitivitiesOrSubstances,
  ]);

  if (!traitENDetails) return null;

  const getUnitSubtitle = (formattedRecommendation) => {
    if (
      !formattedRecommendation ||
      formattedRecommendation === "No recommendation"
    ) {
      return "";
    }
    return formattedRecommendation.includes("%")
      ? "of your daily calories"
      : "per day";
  };

  // filter out healthBenefits to only use the ones specific to the trait we're using
  // then loop through the genes and populate
  const nutrientBenefits = traitENDetails.healthBenefits || [];
  const genes =
    !!geneDetails &&
    !!geneDetails.genes &&
    geneDetails.genes.reduce((acc, { markers }) => {
      markers.forEach((marker) => {
        if (
          marker.variant &&
          !!marker.healthOutcome &&
          !acc.find(({ name }) => name === marker.healthOutcome.name)
        ) {
          acc.push({
            name: marker.healthOutcome.name,
            description: marker.healthOutcome.description,
            icon: marker.healthOutcome.icon,
          });
        }
      });

      return acc;
    }, []);
  const subtitle =
    getUnitSubtitle(selectedTraitDetails.formattedRecommendation) || "";
   //for potassium general intake is hardcoded and it is changed for male and female both
   const generalIntake = selectedTraitDetails.traitName === POTASSIUM ? genderId === MALE_GENDER_ID ? GENERAL_INTAKE_OF_POTASSIUM_FOR_MALE : GENERAL_INTAKE_OF_POTASSIUM_FOR_FEMALE  : selectedTraitDetails.formattedAverage || '';
   const genticInsight = selectedTraitDetails.recommendationText?.toLowerCase();
   const title = selectedTraitDetails.traitName?.toLowerCase();

  return (
    <React.Fragment>
      <Grid container className={classes.mainContainer}>
        <Container style={{ flexGrow: 1 }} maxWidth="lg">
          <Grid
            container
            justify="space-between"
            className={classes.mb_9}
            spacing={smDown ? 5 : 0}
          >
            <Grid item xs={12} sm={4}>
              <div className={classes.recommendationTitleContainer}>
                <img
                  src={icons.recommendation}
                  className={classes.icon}
                  name="recomendationIcon"
                  alt=""
                />
                <Typography
                  variant="subtitle1"
                  name="title"
                  className={classes.recommendationTitle}
                >
                  {sensitivitiesOrSubstances ? "Your Result" : "Your Recommendation" }
                </Typography>
              </div>
              <RecommendationBlock
                intake={getIntake(
                  selectedTraitDetails.geneticInsightName ||
                    "Genetic Result Not Read",
                  cleanRecommendation(
                    selectedTraitDetails.formattedRecommendation
                  ),
                  sensitivitiesOrSubstances,
                  selectedTraitDetails.traitName
                )}
                subtitle={subtitle}
                generalIntake={generalIntake}
                sensitivitiesOrSubstances={sensitivitiesOrSubstances}
              />
              { !sensitivitiesOrSubstances && <>
                  <Typography className={classes.recommendationSubtitle}>
                    What We Took Into Account
                  </Typography>
                  <Typography className={classes.subtitleDescription}>
                    Your unique recommendation was calculated based on your genes,
                    age, and gender.
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }} component="span">
                    <a
                      href="https://www.genopalate.com/pages/creating-your-analysis"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      How we calculate our recommendations →
                    </a>
                  </Typography>
              </> }
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box className={classes.recommendationTitleContainer}>
                {!smDown && (
                  <img
                    src={icons.dna}
                    className={classes.icon}
                    name="dnaIcon"
                    alt=""
                  />
                )}
                <Box>
                  <Box className={classes.recommendationTitleContainer}>
                    {smDown && (
                      <img
                        src={icons.dna}
                        className={classes.icon}
                        name="dnaIcon"
                        alt=""
                      />
                    )}
                    <Typography
                      variant="subtitle1"
                      name="title"
                      className={classes.recommendationTitle}
                    >
                      Genetic Factors
                    </Typography>
                  </Box>

                  {!sensitivitiesOrSubstances && selectedTraitDetails.impactful && <Typography>
                      Evidence suggests that individuals with genetics like yours
                      may have the following health improvements when consuming a
                      diet that is{" "}
                      {genticInsight ? genticInsight : ''}{" "} in {formatNutrientTitle(title)}.
                    </Typography>
                  }
                  {sensitivitiesOrSubstances && selectedTraitDetails.traitGeneticFactor && 
                    <Typography>{
                      selectedTraitDetails.traitGeneticFactor}
                    </Typography>
                  }
                  {!sensitivitiesOrSubstances && genes.length > 0 && (
                    <Grid xs={12} item>
                      <BenefitsWrapper
                        label="Key Benefits"
                        lineStyling={classes.line}
                        subtitleStyles={classes.subtitle}
                        showTitle={false}
                      >
                        <NutrientBenefits benefits={genes} />
                      </BenefitsWrapper>
                    </Grid>
                  )}
                  {!sensitivitiesOrSubstances && !selectedTraitDetails.impactful  && <Typography className={classes.nonImpactMessage}>
                      Your genes align with typical {formatNutrientTitle(selectedTraitDetails.traitName)} guidelines.
                    </Typography>
                  }

                  <Typography className={classes.benefitSection}>
                    Learn more about which genes influenced your results for
                    this trait.
                  </Typography>
                  <Button
                    className={clsx(
                      sharedClasses.rebrandedSecondaryButton,
                      classes.button
                    )}
                    variant="outlined"
                    onClick={handleTabChange}
                  >
                    View Genetic Impact →
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <TipsPaper
          sensitivitiesOrSubstances={sensitivitiesOrSubstances}
          traitData={selectedTraitDetails}
          enDetails={traitENDetails}
        />

        <Container style={{ flexGrow: 1 }} maxWidth="lg">
          <NutrientSources
            sensitivitiesOrSubstances={sensitivitiesOrSubstances}
            traitData={selectedTraitDetails}
            enDetails={traitENDetails}
          />

          <Grid container spacing={smDown ? 4 : 10}>
            <Grid item xs={12} sm={6} id="nutrientDescription">
              <NutrientDescription
                name={selectedTraitDetails.traitName}
                description={selectedTraitDetails.traitDescription}
                additionalDescription={
                  selectedTraitDetails.traitAdditionalDescription
                }
              />
            </Grid>
            {!sensitivitiesOrSubstances && nutrientBenefits.length > 0 && (
              <Grid xs={12} item sm={6}>
                <BenefitsWrapper
                  label="Nutrient Benefits"
                  subtitleStyles={classes.subtitle}
                  lineStyling={classes.line}
                  showTitle={true}
                >
                  {!!traitENDetails && !!traitENDetails.healthBenefits && (
                    <NutrientBenefits
                      benefits={traitENDetails.healthBenefits}
                    />
                  )}
                </BenefitsWrapper>
              </Grid>
            )}
          </Grid>
        </Container>
      </Grid>
    </React.Fragment>
  );
};

OverviewTab.propTypes = {
  selectedDomain: PropTypes.shape({
    name: PropTypes.string,
    traits: PropTypes.arrayOf(
      PropTypes.shape({
        traitId: PropTypes.number,
        geneticInsightId: PropTypes.number,
        traitName: PropTypes.string,
        formattedAverage: PropTypes.string,
        formattedRecommendation: PropTypes.string,
        geneticInsightName: PropTypes.string,
        geneticInsightDescription: PropTypes.string,
        totalVariants: PropTypes.number,
        traitDescription: PropTypes.string,
        traitAdditionalDescription: PropTypes.string,
      })
    ),
  }).isRequired,
  classes: PropTypes.shape({
    mainContainer: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    line: PropTypes.string.isRequired,
    recommendationSubtitle: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    recommendationTitle: PropTypes.string.isRequired,
    recommendationTitleContainer: PropTypes.string.isRequired,
    subtitleDescription: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  essentialNutritionDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  geneDetails: PropTypes.shape({
    traitId: PropTypes.number,
    genes: PropTypes.arrayOf(
      PropTypes.shape({
        markers: PropTypes.arrayOf(
          PropTypes.shape({
            healthOutcome: PropTypes.shape({
              name: PropTypes.string,
              description: PropTypes.string,
              icon: PropTypes.string,
            }),
            variant: PropTypes.bool,
          })
        ),
      })
    ),
  }).isRequired,
  id: PropTypes.number.isRequired,
};

export default OverviewTab;
