import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { startCase } from 'lodash';
import useStyles from './KeyBenefit.styles';

const KeyBenefit = ({ icon, label, componentWidth }) => {
  const classes = useStyles();
const formattedLabel = label
  .split(',')
  .map((part) => startCase(part?.trim()))
  .join(', '); 
  return (
    <Grid item xs={componentWidth}>
      <div className={classes.container}>
        <img alt={icon} className={classes.icon} src={`https://geno-icons.s3.amazonaws.com/health-benefits/${icon}`} />
        <Typography variant="caption" align="center" className={classes.label}>{formattedLabel}</Typography>
      </div>
    </Grid>
  );
};

KeyBenefit.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  componentWidth: PropTypes.number.isRequired,
};

export default KeyBenefit;
