import { get, isEqual } from 'lodash';
import { oneTimeSupplementProductSkus, oneTimeSupplementProductSkusV2, subscriptionStatuses } from 'resources/constants';

export const localStorageCartKey = 'shopifyCartId';
export const genopalateClickIdKey = '_gpClickId'; // key that is uses for GA4 (google analytics)

export const parseCartResponse = (resp) => {
  if (resp && resp.cost && resp.lines && resp.lines.nodes) {
    const lineItems = resp.lines.nodes.map(line => ({
      id: line.merchandise.id,
      cartLineId: line.id,
      image: line.merchandise.product.featuredImage.url,
      title: line.merchandise.product.title,
      variantTitle: line?.merchandise?.title || '',
      price: line.merchandise.price.amount,
      quantity: line.quantity,
      sku: line.merchandise.sku,
      isOneTime: line.attributes.some(attr => attr.key === '_oneTime' && attr.value === 'Yes'),
    }));
    return {
      total: resp.cost.totalAmount.amount,
      subtotal: resp.cost.subtotalAmount.amount,
      checkoutUrl: resp.checkoutUrl,
      id: resp.id,
      lineItems,
    };
  }
  return {
    checkoutUrl: resp.checkoutUrl,
    id: resp.id,
  };
};



/**
 * Finds and returns the current subscription from a list of subscriptions.
 *
 * @function getCurrentSubscription
 * @param {Array<Object>} subscriptions - Array of subscription objects.
 * @returns {Object|null} Current subscription or null if not found.
 */
export const getCurrentSubscription = (subscriptions = []) => subscriptions
  .find(subscription => [
    subscriptionStatuses.Active, subscriptionStatuses.Paused, subscriptionStatuses.Pending,
  ].includes(subscription.status));

/**
 * Finds and returns the current subscription from a list of subscriptions.
 *
 * @function getCurrentSubscriptionV1
 * @param {Array<Object>} subscriptions - Array of subscription objects.
 * @returns {Object|null} Current subscription or null if not found.
 */
export const getCurrentSubscriptionV1 = (subscriptions = []) => subscriptions
  .filter(subscription => [
    subscriptionStatuses.Active, subscriptionStatuses.Paused, subscriptionStatuses.Pending,
  ].includes(subscription.status));

export const getSubscriptionNamesFromSubscriptionProducts = (subscriptionProducts) => subscriptionProducts.map(product => product.name);

// Given the subscription name, return an array of booster names included in that subscription
export const getBoostersFromSubscription = (subscriptionName, subscriptionProducts) => subscriptionProducts.find(product => product.name === subscriptionName).boosters.map(booster => booster.name);

export const getBoostersFromSubscriptionSKU = (sku, subscriptionProducts) => subscriptionProducts.find(product => product.sku === sku).boosters.map(booster => booster.name);
export const getSKUFromSubscriptionProducts = (subscriptionProducts) => subscriptionProducts.map(product => product.sku);

// Given an array of booster names, return the id and the plan id of the subscription that includes
// the identical set of boosters
export const getSubscriptionFromBoosters = (boosters = [], subscriptionProducts = []) => {
  const subscriptionBoosters = subscriptionProducts?.map(product => ({ id: product.id, planId: product.planId, isOneTimeProduct: (oneTimeSupplementProductSkus[product.sku] || oneTimeSupplementProductSkusV2[product.sku]),boosters: product.boosters.map(booster => booster.name) }));
  const subscription = subscriptionBoosters?.find(subscriptionBooster => isEqual([...subscriptionBooster.boosters].sort(), [...boosters].sort())); // sort booster lists for comparison without mutating
  // check for one time product since one time product doesn't have selling plan id
  if (subscription && subscription.id && subscription.planId === undefined && subscription.isOneTimeProduct) { //TODO: check for one time product
    return { id: subscription.id, planId: subscription.planId };
  } else if (subscription && subscription.id && subscription.planId) {
    return { id: subscription.id, planId: subscription.planId };
  }
  else {
    return { id: null, planId: null }
  }
};

export const throwIfErrorResponse = (resp) => {
  const errors = get(resp, 'data.errors');

  if (errors && errors.length > 0) {
    throw new Error(errors[0].message || 'An unknown error has occurred.');
  }
};

/**
 * Formats a date to a string with two-digit day and numeric month.
 * @function formatDateToTwoDigit
 * @param {Date} date - Date object to be formatted.
 * @returns {string} Formatted date with two-digit day and numeric month.
 * @example
 * const formattedDate = formatDateToTwoDigit(new Date());
 * console.log(formattedDate); // Example output: '01/23' (assuming January 23rd)
 */
export const formatDateToTwoDigit = (date) => {
  return date ? date.toLocaleDateString('en-US', { day: '2-digit', month: 'numeric' }): ''
}
