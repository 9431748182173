import { differenceInYears } from 'date-fns';
import { isEqual, sortBy } from 'lodash';
import { blendNames, blendSKUs, supplementProductNames, supplementProductSkus } from 'resources/constants';

/**
 * 
 * @param {string} productSku 
 * @returns {string}
 */
export const getProductName = (productSku) => {
  if(supplementProductSkus[productSku]) return supplementProductNames.VS0000;

  if(blendSKUs[productSku]) return blendNames['NS0001-LPLF'];

  return supplementProductNames.VS0000;
}

export const getDimensions = () => (window === undefined ? 500 : window.innerHeight);

const isRegExp = (re) => re instanceof RegExp;

const formatDateString = (dateString, monthLength) => new Date(dateString).toLocaleDateString('en-us', { month: monthLength, day: 'numeric', year: 'numeric' });

const escapeRegExp = (string) => {
  const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
  const reHasRegExpChar = RegExp(reRegExpChar.source);

  return (string && reHasRegExpChar.test(string))
    ? string.replace(reRegExpChar, '\\$&')
    : string;
};

const isString = (value) => typeof value === 'string';

// use for feature flags
const checkBetaPreviewStatus = (email) => email.toLowerCase().trim().split('@')[1] === 'genopalate.com';

const flatten = (array) => {
  let newArray = [];

  array.forEach((item) => {
    if (Array.isArray(item)) {
      newArray = newArray.concat(item);
    } else {
      newArray.push(item);
    }
  });

  return newArray;
};

/**
 * Given a string, replace every substring that is matched by the `match` regex
 * with the result of calling `fn` on matched substring. The result will be an
 * array with all odd indexed elements containing the replacements. The primary
 * use case is similar to using String.prototype.replace except for React.
 *
 * React will happily render an array as children of a react element, which
 * makes this approach very useful for tasks like surrounding certain text
 * within a string with react elements.
 *
 * Example:
 * matchReplace(
 *   'Emphasize all phone numbers like 884-555-4443.',
 *   /([\d|-]+)/g,
 *   (number, i) => <strong key={i}>{number}</strong>
 * );
 * // => ['Emphasize all phone numbers like ', <strong>884-555-4443</strong>, '.'
 *
 * @param {string} str
 * @param {regexp|str} match Must contain a matching group
 * @param {function} fn
 * @return {array}
 */
const replaceString = (str, match, fn) => {
  let curCharStart = 0;
  let curCharLen = 0;

  if (str === '') {
    return str;
  } if (!str || !isString(str)) {
    throw new TypeError('First argument to react-string-replace#replaceString must be a string');
  }

  let re = match;

  if (!isRegExp(re)) {
    re = new RegExp(`(${ escapeRegExp(re) })`, 'gi');
  }

  const result = str.split(re);

  // Apply fn to all odd elements
  for (let i = 1, { length } = result; i < length; i += 2) {
    curCharLen = result[i].length;
    curCharStart += result[i - 1].length;
    result[i] = fn(result[i], i, curCharStart);
    curCharStart += curCharLen;
  }

  return result;
};

const reactStringReplace = (source, match, fn) => {
  if (!Array.isArray(source)) source = [source];

  return flatten(source.map((x) => (isString(x) ? replaceString(x, match, fn) : x)));
};

const isEighteenOrOlder = (birthdate) => {
  const yearsOld = differenceInYears(new Date(), Date.parse(birthdate));

  return yearsOld >= 18;
};

const APOSTROPHE_CHAR = '’';
const SINGLE_QUOTE_CHAR = '\'';

/**
 * Make a string possessive, with either an apostrophe or an apostrophe and the letter 's'.
 *
 * @param value
 *
 * @return either <value>' or <value>'s; if value already ends in an apostrophe or a single quote nothing is appended
 */
const possessive = (value) => {
  if (!value || value === '') {
    return value;
  }
  const lastChar = value.slice(-1);
  if (lastChar === APOSTROPHE_CHAR || lastChar === SINGLE_QUOTE_CHAR) {
    return value;
  }
  const endOfWord = lastChar.toLowerCase() === 's' ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`;
  return `${value}${endOfWord}`;
};

/**
 * Make a string possessive, with either an apostrophe or an apostrophe and the letter 's'.
 *
 * @param value
 *
 * @return either <value>' or <value>'s; if value already ends in an apostrophe or a single quote nothing is appended
 */
const capitalizePossessive = (value) => {
  if (!value || value === '') {
    return value;
  }
  const lastChar = value.slice(-1);
  if (lastChar === APOSTROPHE_CHAR || lastChar === SINGLE_QUOTE_CHAR) {
    return value;
  }
  const endOfWord = lastChar.toLowerCase() === 'S' ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}S`;
  return `${value}${endOfWord}`;
};

/**
 * Formats a number using Intl.NumberFormat.
 *
 * @param {number} value - The number to be formatted.
 * @param {Intl.NumberFormatOptions} [options] - Optional formatting options.
 * @returns {string} The formatted number as a string.
 *
 * @example
 * const formattedNumber = formatNumber(1234567.89);
 * // Output: "1,234,567.89"
 *
 * @example
 * const formattedCurrency = formatNumber(1234567.89, { style: 'currency', currency: 'USD' });
 * // Output: "$1,234,567.89"
 */
function formatNumber(value, options = { style: 'currency', currency: 'USD' }) {
  const formatter = new Intl.NumberFormat('en-US', options)
  return formatter.format(value)
}


const areArraysEqual = (array1, array2) => {
  // Sort the arrays before comparing
  const sortedArray1 = sortBy(array1);
  const sortedArray2 = sortBy(array2);

  // Use isEqual to compare the sorted arrays
  return isEqual(sortedArray1, sortedArray2);
};

function numberToWords(num) {
  if (num === 0) return "zero";

  // Arrays for units, teens, tens, and thousands
  const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  const teens = ["ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
  const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
  const thousands = ["", "thousand", "million", "billion"];

  // Helper function to handle numbers less than 1000
  function helper(n) {
      let result = "";
      if (n >= 100) {
          result += units[Math.floor(n / 100)] + " hundred ";
          n %= 100;
      }
      if (n >= 10 && n < 20) {
          result += teens[n - 10] + " ";
      } else if (n >= 20) {
          result += tens[Math.floor(n / 10)] + " ";
          n %= 10;
      }
      if (n > 0 && n < 10) {
          result += units[n] + " ";
      }
      return result.trim();
  }

  let result = "";
  let thousandCounter = 0;

  while (num > 0) {
      let remainder = num % 1000;
      if (remainder !== 0) {
          result = helper(remainder) + (thousands[thousandCounter] ? " " + thousands[thousandCounter] : "") + " " + result;
      }
      num = Math.floor(num / 1000);
      thousandCounter++;
  }

  return result.trim();
}

export default {
  formatDateString,
  checkBetaPreviewStatus,
  getDimensions,
  reactStringReplace,
  isEighteenOrOlder,
  possessive,
  capitalizePossessive,
  formatNumber,
  areArraysEqual,
  numberToWords
};
