import React from 'react';
import { makeStyles } from '@material-ui/core';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles(() => ({
  score: (props) => ({
    fontSize: '1.5rem',
    marginBottom: 0,
    color: props.scoreColor,
  }),
}));

const FoodScore = ({ score, scoreStyle = {} }) => {
  // Determine the text color based on the score value
  const getScoreColor = (score) => {
    if (score >= 50) {
      return colors.darkestGreen;
    } else if (score >= 35) {
      return colors.orange_02;
    } else {
      return colors.chartOrange;
    }
  };

  const classes = useStyles({ scoreColor: getScoreColor(score) });

  return (
    <div>
      <p className={classes.score} style={{ ...scoreStyle }}>{score}</p>
    </div>
  );
};

export default FoodScore;
