import { makeStyles } from "@material-ui/core/styles";
import { colors } from "common/assets/sharedUI";

const useStyles = makeStyles({
  tipContainer: {
    width: "100%",
  },
  paper: {
    width: "100%",
    padding: "2rem",
    zIndex: 10,
    backgroundColor: colors.gray_01,
    boxShadow: 'none'
  },
  titleContainer: {
    display: "flex",
    alignItems: "start",
  },
  title: {
    fontWeight: "bold",
  },
  icon: {
    width: 55,
    height: 40,
    marginRight: "26px",
  },
  tipsDescription: {
    fontSize: "18px",
    marginBottom: "16px",
  },
  headerBar: {
    backgroundColor: "#277E9F",
    height: "3px",
    marginBottom: "25px"
  }
});

export default useStyles;
