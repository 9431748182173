import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import CustomTextField from 'components/CustomTextField'
import PropTypes from 'prop-types'
import useStyles from './WeightForm.styles'

export default function WeightForm(props) {
  const classes = useStyles()
  const {
    id,
    questionText,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = props

  const poundsProps = {
    name: `pounds_${id}`,
    value: values[`pounds_${id}`],
    onChange: handleChange,
    onBlur: handleBlur,
    error: errors[`pounds_${id}`] && touched[`pounds_${id}`],
    helperText: (errors[`pounds_${id}`] && touched[`pounds_${id}`]) && errors[`pounds_${id}`],
    margin: 'normal',
    variant: 'outlined',
    size: 'small',
    inputProps: { maxLength: 4 },
    className: classes.poundsInp,
  }

  return (
    <Grid container>
      <Typography variant="body1" className={classes.questionLabel}>{questionText}</Typography>
      <Grid item xs={12} className={classes.questionContainer}>
        <CustomTextField {...poundsProps} />
        <div className={classes.labelContainer}>
          <Typography variant="body1" className={classes.centerSpan}>lb</Typography>
        </div>
      </Grid>
    </Grid>
  )
}

WeightForm.propTypes = {
  id: PropTypes.number.isRequired,
  questionText: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.any),
  touched: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}

WeightForm.defaultProps = {
  questionText: '',
  values: {},
  touched: {},
  errors: {},
}
