import { makeStyles } from "@material-ui/core/styles";
import { colors } from "common/assets/sharedUI";
import { fonts } from "../../../common/assets/sharedUI";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: 0,
        color: colors.gray_06,
    },
    expansionPanel: {
        backgroundColor: colors.gray_02,
        color: colors.gray_06,
        borderRadius: 0,
        boxShadow: 'none',
        margin: 0,
        padding: 0,
        '&:before': {
            display: 'none',
        },
    },
    summary: {
        padding: 0,
        minHeight: 'auto',
        margin: 0,
        color: colors.gray_06,
        '&.Mui-expanded': {
            minHeight: 'auto',
            margin: '0 !important',
            backgroundColor: colors.bannerColor,
            color: colors.white,
            padding: 0,
        },
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
            padding: 0,
            '&.Mui-expanded': {
                margin: '0 !important',
                padding: 0,
            },
        },
    },
    heading: {
        fontFamily: fonts.Swiss721MD,
        fontSize: '19px',
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            paddingLeft: theme.spacing(2),
        },
    },
    details: {
        backgroundColor: colors.white,
        padding: 0,
        fontFamily: fonts.Swiss721MD,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    // Override specific IconButton class
    iconButtonEdgeEnd: {
        '&.MuiIconButton-edgeEnd': {
            marginRight: '2px', // Apply your custom margin here
        },
    },
    // Table styling
    tableContainer: {
        marginBottom: theme.spacing(2),
        fontFamily: fonts.Swiss721LT,
        boxShadow: 'none'
    },
    tableRowBase: {
        fontSize: "12px",
        verticalAlign: "top",
        padding: "10px",
    },
    pt_0: {
        paddingTop: 0,
    },
    removeBorder: {
        border: 'none',
    },
    rowBorder: {
        borderBottom: `1px solid ${colors.gray_02}`,
    },
    tableCellBase: {
        borderBottom: "none",
        padding: "10px 12px",
    },
    tableHeader: {
        fontWeight: "bold",
        padding: "5px 12px",
        backgroundColor: colors.gray_01,
        fontFamily: fonts.Swiss721MD,
        fontWeight: 600,
        color: colors.darkestGrey,
    },
    bold: {
        fontFamily: fonts.Swiss721MD,
        fontWeight: 600,
    },
    genotypeText: {
        fontSize: "20px",
        fontFamily: fonts.Swiss721MD,
        fontWeight: 600,
    },
    blue_06: {
        color: colors.blue_06,
    },
    gray_07: {
        color: colors.gray_07,
    },
    infoText: {
        display: "flex",
        alignItems: "start",
        color: colors.gray_07,
    },
    infoIcon: {
        color: colors.gray_07,
        marginRight: "4px",
        marginTop: "2px",
        fontSize: "21px",
    },
    genotypeDescription: {
        marginBottom: "14px",
        fontFamily: fonts.Swiss721MD,
        fontWeight: 600,
        paddingleft: "20px"
    },
    mt_4: {
        marginTop: "32px",
    },
    tableBody: {
        fontFamily: fonts.Swiss721LT,
        color: colors.darkestGrey,
        fontSize: "12px",
    },
    darkestGrey: {
        color: colors.darkestGrey,
    },
    marker: {
        color: colors.darkestGrey,
        fontSize: "14px",
        fontFamily: fonts.Swiss721MD,
        fontWeight: 600,
    },
    geneName: {
        color: colors.darkestGrey,
        fontFamily: fonts.Swiss721MD,
        fontWeight: 600,
        fontSize: "18px",
    },
    button: {
        marginTop: "10px",
        backgroundColor: colors.white,
        border: "none",
        opacity: 1,
        padding: "5px 8px",
        paddingLeft: 0,
        height: "unset",
        textTransform: "none",
        letterSpacing: "0px",
        color: colors.blue_06,
        '&:hover': {
            paddingLeft: '2px',
        },
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    infoRow: {
        padding: 0
    },
    pr_4: {
        paddingRight: theme.spacing(4)
    },
    pl_4: {
        paddingLeft: theme.spacing(4)
    },
    // Modal
    dialogRoot: {
        padding: 0,
        // Add padding for mobile to avoid header overlap
        paddingTop: theme.spacing(1),
        [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {
            paddingTop: theme.spacing(6), // Adjust this value as needed
        }
    },
    dialogContent: {
        padding: 0,
        maxHeight: "87%", // Adjust max height depending on your design
        overflowY: 'auto',  // Allow scrolling if content exceeds max height
        overflowX: 'hidden', // Prevent horizontal scrolling
    },
    dialogHeader: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: colors.bannerColor,
    },
    dialogTitle: {
        color: colors.white,
        fontSize: "16px",
        fontFamily: fonts.Swiss721MD,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(6.5),
        color: colors.white,
    },
    contentText: {
        padding: 0,
    },
    geneDescription: {
        fontSize: "14px",
        color: colors.darkestGrey,
        fontFamily: fonts.Swiss721LT,
    },
    geneCell: {
        borderTop: `1px solid ${colors.gray_02}`,
        padding: 0,
        paddingLeft: "16px",
        fontWeight: "bold",
        fontFamily: fonts.Swiss721MD,
        fontWeight: 600,
        color: colors.darkestGrey,
        paddingTop: "20px"
    },
    font: {
        fontSize: "14px"
    },
    wideColumn: {
        paddingLeft: "40px"
    }
}));

export default useStyles;
